import {
  Typography,
  withStyles,
  Grid,
  Select,
  MenuItem,
  Paper,
  FormControl,
  InputLabel,
  Button,
  Card,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SummaryViewTable from "../../components/SummaryViewTable";
import {
  getNNextQuarters,
  nearestCompletedFiscalQuarter,
} from "../../util/fiscalQuarterFunctions";
import {
  formatDataForMekkoChart,
  formatSummaryViewData,
  preFormatMekkoData,
} from "../../util/dataFormatter";
import D3MekkoChart from "../PBISummaryView/D3PBIMekkoChart";
import PBISummaryLegend from "../PBISummaryView/PBISummaryLegend";
import NoDataComponent from "../../components/NoDataComponent";
import RegionFilter from "../../Filters/Region_Filter";
import OpportunityType from "../../Filters/OpportunityType";
import Acct_SegmentFilter from "../../Filters/Acct_SegmentFilter";
import FilterContainer from "../../Filters/FilterContainer";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import WinAcvDrillDownDrilldownBody from "../WinACVAnalysis/WinAcvDrillDown";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
} from "../../util/customFunctions";
import addHeaderdescription from "../../components/HeaderDescription";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
});
class PBIPipelineView extends Component {
  filters = [
    "projected_close_fiscal_quarter",
    "Region_Rollup",
    "Booking_Type_NL_CS_US",
    "Acct_Segment",
  ];
  state = {
    projected_close_fiscal_quarter: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: 3,
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    filtersForDrilldown: [],
    quarter: ["All"],
    openStage: 3,
    filters: [],
  };
  componentDidMount() {
    this.props.getPBIPipelineFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentWillUnmount() {
    D3MekkoChart.destroy(this._rootNode1);
    window.removeEventListener("resize", this.onResize);
  }
  onResize = () => {
    const preFormattedMekkoData = preFormatMekkoData(
      this.props.PBIPipelineData
    );
    const { properties, formattedData, industries } = formatDataForMekkoChart(
      preFormattedMekkoData
    );

    D3MekkoChart.destroy(this._rootNode1);
    this._chart1 = D3MekkoChart.create(this._rootNode1, {
      data: formattedData,
      labels: { appCluster: properties, vertical: industries },
      handleChangeLocation: this.handleChangeLocation,
    });
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }
  quarterForDrilldown = () => {
    let quarter = [];
    quarter = this.state.projected_close_fiscal_quarter;
    console.log(quarter);
    return quarter;
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  passFiltersForDrilldown = () => {
    let filtersArray = [];
    filtersArray = [
      ...filtersArray,
      {
        title: "Projected Close Fiscal Qtr.",
        value: this.state.projected_close_fiscal_quarter,
      },
    ];
    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_names = [...stage_names, i.stage_name];
      }
    });
    filtersArray = [
      ...filtersArray,
      { title: "Earliest Open Stage", value: stage_names[0] },
    ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (!this.state.Region_Rollup.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Region", value: this.state.Region_Rollup },
      ];

    if (!this.state.Acct_Segment.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Segment", value: this.state.Acct_Segment },
      ];

    return filtersArray;
  };
  handleChangeLocation = (location, x, y) => {
    console.log(x, y);
    if (location !== "overview") {
      var filters = [];
      if (!this.state.quarter.includes("All")) {
        filters = [
          ...filters,
          {
            name: "projected_close_fiscal_quarter",
            value: this.state.quarter,
            string: true,
          },
        ];
      }
      let stage_names = [];
      this.props.funnelCompareFilters.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence >= this.state.openStage)
            stage_names = [...stage_names, i.stage_name];
        }
      });
      filters = [
        ...filters,
        {
          name: "Acct_Vertical",
          value: Array.isArray(y) ? y : [y],
          string: true,
        },
        { name: "stage", value: stage_names, string: true },
      ];
      let localFilters = this.props.currentFilters;
      var filters1 = [];
      this.state.filters.map((item) => {
        if (item.title === "Projected Close Fiscal Qtr. ") {
          item.value = this.state.quarter;
          filters1.push(item);
        } else {
          filters1.push(item);
        }
      });
      filters = [...filters, ...localFilters];
      console.log(filters);

      this.props.getPbiSummaryDrilldown(filters, {
        appsCluster: x,
        oppStatus: "open",
      });
      let filterDrilldown = [
        ...filters1,
        { title: "Vertical", value: Array.isArray(y) ? "All" : y },
        { title: "Cluster", value: x },
      ];
      this.setState({
        location: location,
        filtersForDrilldown: filterDrilldown,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log("vertical_App_oppID", oppId);
    this.setState({
      ...this.state,
      location: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
    });
    this.props.clearExpectedAcvData();
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
    });
    //this.props.setTable('')
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_names = [...stage_names, i.stage_name];
      }
    });
    filters = [
      ...filters,
      {
        name: "stage",
        value: stage_names,
        string: true,
      },
    ];
    this.setState({
      ...this.state,
      filters: this.passFiltersForDrilldown(),
      quarter: this.quarterForDrilldown(),
      openStage: this.state.earliestOpenStage,
    });

    this.props.getPBIPipelineData(this.props.company, filters);
  };
  componentDidUpdate(prevProps, prevState) {
    console.log(this.props.funnelCompareFilters);
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const stageSequence = 3;
      let stage_names = [];
      this.props.funnelCompareFilters.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence >= stageSequence)
            stage_names = [...stage_names, i.stage_name];
        }
      });

      this.setState({
        ...prevState,
        projected_close_fiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "projected_close_fiscal_quarter"
        ),
        quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "projected_close_fiscal_quarter"
        ),
        earliestOpenStage: stageSequence,
        openStage: stageSequence,
        filters: this.passFiltersForDrilldown(),
      });
    }
    if (
      this.props.PBIPipelineData !== "" &&
      prevProps.PBIPipelineData === "" &&
      this.props.PBIPipelineData.length !== 0
    ) {
      const preFormattedMekkoData = preFormatMekkoData(
        this.props.PBIPipelineData
      );
      const { properties, formattedData, industries } = formatDataForMekkoChart(
        preFormattedMekkoData
      );

      D3MekkoChart.destroy(this._rootNode1);
      this._chart1 = D3MekkoChart.create(this._rootNode1, {
        data: formattedData,
        labels: { appCluster: properties, vertical: industries },
        handleChangeLocation: this.handleChangeLocation,
      });
    }
  }
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          quarter: "validated_fiscal_quarter",
          year: "validated_year",
          month: "validated_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type_NetNew_Installed_Base",
          LeadSource: "Lead_Source",
          quarter: "open_fiscal_quarter",
          year: "open_calendar_year",
          month: "open_calendar_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
        };
  render() {
    const { classes } = this.props;
    const tableHeaders =
      this.props.PBIPipelineData !== "" &&
      this.props.PBIPipelineData !== undefined &&
      this.props.PBIPipelineData.length > 0
        ? Object.keys(this.props.PBIPipelineData[0]).filter(
            (item) => item !== "Acct_Vertical"
          )
        : null;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid
          className={classes.grid}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Pipeline by Vertical and Apps Cluster
          </Typography>
          <Typography variant="body1">
            Dissect the pipeline by Vertical segment and Apps Cluster to
            determine where to focus.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Pipeline by Vertical and Apps Cluster",
          "Dissect the pipeline by Vertical segment and Apps Cluster to determine where to focus."
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid container item style={{ padding: 10 }} xs={4} md={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.projectedQuarter
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.projectedQuarter]}
                      onChange={this.handleChange}
                      name={this.variableCols.projectedQuarter}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.map((y) =>
                          y.type === this.variableCols.projectedQuarter ? (
                            <MenuItem
                              key={y[this.variableCols.projectedQuarter]}
                              value={y[this.variableCols.projectedQuarter]}
                            >
                              {y[this.variableCols.projectedQuarter]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Earliest Open Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.earliestOpenStage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Earliest Open Stage:"
                      name="earliestOpenStage"
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.map(
                          (y) =>
                            y.sequence && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.stage_name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10 }} xs={6}>
              <Grid item container xs={12}>
                <FilterContainer
                  filters={[
                    <OpportunityType
                      Booking_Type_NL_CS_US={this.state.Booking_Type_NL_CS_US}
                      handleChange={this.handleChange}
                      funnelCompareFilters={this.props.funnelCompareFilters}
                    />,
                    <RegionFilter
                      Region_RollupValue={this.state.Region_Rollup}
                      handleChange={this.handleChange}
                      funnelCompareFilters={this.props.funnelCompareFilters}
                    />,
                    <Acct_SegmentFilter
                      selectedAccount_Segment={this.state.Acct_Segment}
                      handleChange={this.handleChange}
                      funnelCompareFilters={this.props.funnelCompareFilters}
                    />,
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {this.props.PBIPipelineData !== "" ? (
          <>
            <Grid
              item
              container
              xs={12}
              justify="space-around"
              className={classes.grid}
            >
              <Grid container component={Card} raised={true}>
                <Grid item xs={12} style={{ padding: "1rem" }}>
                  <Typography
                    align="center"
                    variant="h2"
                    style={{ margin: "1rem" }}
                  >
                    Pipeline by Vertical and Apps Cluster
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {this.props.PBIPipelineData !== "" ? (
                    tableHeaders !== null ? (
                      <div
                        style={{
                          justifyContent: "center",
                          marginTop: 20,
                          margin: "auto",
                          width: "95%",
                        }}
                        id="svgContainer"
                        className="svg-container"
                        ref={this._setRef1.bind(this)}
                      />
                    ) : (
                      <NoDataComponent />
                    )
                  ) : (
                    <Grid container justify="center">
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="80%"
                        height={200}
                        style={{ margin: "20px 0px" }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.grid}>
              <Grid container component={Card}>
                <Grid item container xs={12}>
                  <Grid item xs={12} style={{ padding: "1rem" }}>
                    <Typography
                      align="center"
                      variant="h2"
                      style={{ margin: "1rem" }}
                    >
                      Pipeilne by Vertical and Apps Cluster
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justify="center">
                    {this.props.PBIPipelineData !== "" ? (
                      <Grid container>
                        {tableHeaders !== null ? (
                          <SummaryViewTable
                            data={formatSummaryViewData(
                              this.props.PBIPipelineData,
                              tableHeaders,
                              true
                            )}
                            tableHeaders={tableHeaders}
                            isAcv={true}
                            handleChangeLocation={this.handleChangeLocation}
                            data_test={"pipeline_by_vertical"}
                          />
                        ) : (
                          <NoDataComponent />
                        )}
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="80%"
                          height={200}
                          style={{ margin: "20px 0px" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {this.props.PBIPipelineData !== "" &&
                    tableHeaders !== null ? (
                      <PBISummaryLegend />
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} style={{ padding: "1rem" }}>
                    <Typography
                      align="center"
                      variant="h2"
                      style={{ margin: "1rem" }}
                    >
                      Pipeline by Vertical and Apps Cluster (% of total view)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justify="center">
                    {this.props.PBIPipelineData !== "" ? (
                      <Grid container>
                        {tableHeaders !== null ? (
                          <SummaryViewTable
                            data={formatSummaryViewData(
                              this.props.PBIPipelineData,
                              tableHeaders,
                              false
                            )}
                            tableHeaders={tableHeaders}
                            isAcv={false}
                            handleChangeLocation={this.handleChangeLocation}
                            data_test={"pipeline_by_vertical_total_view"}
                          />
                        ) : (
                          <NoDataComponent />
                        )}
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="80%"
                          height={200}
                          style={{ margin: "20px 0px" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "2rem" }}>
                    {this.props.PBIPipelineData !== "" &&
                    tableHeaders !== null ? (
                      <PBISummaryLegend />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            style={{ marginTop: "1rem" }}
            container
            component={Card}
            justify="center"
            raised={true}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="80%"
              height={200}
              style={{ margin: "20px 0px" }}
            />
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Pipeline by Vertical and Apps Cluster"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              {console.log(this.props.drilldownOnGraphData)}
              <WinAcvDrillDownDrilldownBody
                header={"pipeline by Vertical and Apps Cluster"}
                body={this.props.drilldownOnGraphData}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}

        {this.state.location === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{
              position: "absolute",
              width: "100%",
              marginTop: "30px",
            }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    PBIPipelineFilters,
    PBIPipelineData,
    user,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelCompareFilters: PBIPipelineFilters,
    PBIPipelineData: PBIPipelineData,
    company: user.company,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getPBIPipelineFilters: (company) => {
    dispatch({ type: "get_pbi_pipeline_filters_request" });
    appService.getChartFilters("C0003.CHID00000014").then(
      (json) => {
        dispatch({ type: "get_pbi_pipeline_filters_success", json });
        dispatch({ type: "get_pbi_pipeline_data_request" });
        const stageSequence = 3;
        const defaultStages = json.message
          .filter((f) => f.type === "stage")
          .filter(
            (item) =>
              item.sequence >= stageSequence &&
              ![null, undefined, ""].includes(item["stage_name"])
          )
          .map((i) => i.stage_name);
        const filters = [
          {
            name: "projected_close_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "projected_close_fiscal_quarter"
            ),
            string: true,
          },
          { name: "stage", string: true, value: defaultStages },
        ];
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getChartData(
            "C0003.CHID00000014",
            json.message.length === 0 ? [] : filters
          )
          .then(
            (json) => {
              dispatch({ type: "get_pbi_pipeline_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_pbi_pipeline_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_pbi_pipeline_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pbi_pipeline_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_pbi_pipeline_mix_filters_failure", error });
      }
    );
  },
  getPbiSummaryDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPbiSummaryDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.result.recordsets[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.result.recordsets[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  getPBIPipelineData: (company, filters = []) => {
    dispatch({ type: "get_pbi_pipeline_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getChartData("C0003.CHID00000014", filters).then(
      (json) => {
        dispatch({ type: "get_pbi_pipeline_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pbi_pipeline_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_pbi_pipeline_data_failure", error });
      }
    );
  },
});
const connectedPBIPipelineView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PBIPipelineView));
export default connectedPBIPipelineView;
