import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, TableCell, TableRow, Table } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import GetApp from '@material-ui/icons/GetApp';
import {getCSVFileNameString, csvDateFormatter, _isNaN, agGridDateFormatter, dateFormatterv2, dateFormatterv3} from '../../util/customFunctions'
import './DrilldownBody.scss';
import Skeleton from '@material-ui/lab/Skeleton'
import * as d3 from 'd3'
import ExportCSVButton from '../../components/Drilldown/DrilldownSubComponents/ExportCSVButton'
import { countOrACVFormatter, } from '../../util/customFunctions';
import SummaryInfo from './SummaryInfo';

const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    tableHeader: {
        // fontSize: '15px',
        whiteSpace: 'normal',
        textAlign: 'center'
    },
    tableCell: {
        // fontSize: '14px',
        whiteSpace: 'normal',
    },
    titleContainer: {
        width: '100%'
    },
    bottomSpace: {
        paddingBottom: '0.25rem'
    },
    colHeader: {
        fontSize: '0.85rem',
        border: 'inherit',
        padding: '7px 2px',
        color: 'inherit',
        // width: 120,
        fontWeight: 'inherit',
        backgroundColor: 'inherit'
    },
    fontBold: {
        fontWeight: 600
    },
    noBorder: {
        border: 'none'
    },
    labelWidth: {
        width: '22%'
    },
}))


const dateAndTimeFormatter = (params) => {
    
    return new Intl.DateTimeFormat('en-US',
        {
            timeZone: 'UTC',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
        }).format(new Date(params.value))

}

const NQPDrilldownBody = (props) => {
    const classes = useStyle()
    const [body, setBody] = useState([])
    const containerHeight = props.body.recordset ? props.body.recordset.length * 50 < window.innerHeight - 300 ? '-' : window.innerHeight - 300 : 500
    const domLayout = props.body.recordset ? props.body.recordset.length * 50 < window.innerHeight - 300 ? 'autoHeight' : 'normal' : 'normal'
    

    const defaultColDef = {
        flex: 1,
        cellClass: 'cell-wrap-text',
        autoHeight: true,
        sortable: true,
        resizable: true,
        columnDefs: [],
        rowData: [],
        containerWidth: '100%',
        colResizeDefault: 'shift',
        getRowHeight: function (params) {
            return params.data.rowHeight;
        }


    }


    let gridApi, columnApi, params;

    const gridReady = params => {
        gridApi = params.api
        columnApi = params.columnApi
        params = params
    }
    const onFirstDataRendered = params => {
        if (window.innerWidth < 500) {
            var allColumns = params.columnApi.getAllColumns()
            var allColumnIds = allColumns.map(c => c.colId)
            columnApi.autoSizeColumns(allColumnIds, true)
        }
    }

    const onColumnResized = params => {
        params.api.resetRowHeights()
    }
    console.log(props.body, props.nqpScoreCard)

    const exportCSV = () => {
        var params = {
            skipHeader: false,
            skipFooters: true,
            allColumns: true,
            onlySelected: false,
            suppressQuotes: true,
            processCellCallback: (cellParams) => {
                // const rowIndex = cellParams.column.colId;
                // if(cellParams && rowIndex === "projected_close_date" || rowIndex === "stage_change_date" ) {
                //   return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter  
                // } else if(cellParams && rowIndex === "OppName" || rowIndex === "Account_Name") {
                //   return `"${cellParams.value}"`; //apply your string formatter  
                // } else if(cellParams && rowIndex === "acvAtStage"){
                //   return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value)
                // }
                // else 
                //  return cellParams.value // no formatting
                const rowIndex = cellParams.column.colId;
                // if(cellParams && rowIndex === "projected_close_date" ) {
                //     return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
                //   } else if(cellParams && rowIndex === "OppName" || rowIndex === "Account_Name" || rowIndex === "Owner_Name") {
                //     return `"${cellParams.value}"`; //apply your string formatter
                //   } else if(cellParams && rowIndex === "ACV" || rowIndex === "acv"){
                //     return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value)
                //   }
                const dateTypes = props.body.columns
                  .filter((f) => f.type === "date")
                  .map((f) => f.field);
                const dateTimeTypes = props.body.columns
                  .filter((f) => f.type === "datetime")
                  .map((f) => f.field);
                const stringTypes = props.body.columns
                  .filter((f) => f.type === "string")
                  .map((f) => f.field);
                const integerTypes = props.body.columns
                  .filter((f) => f.type === "integer")
                  .map((f) => f.field);
                const currencyTypes = props.body.columns
                  .filter((f) => f.type === "currency")
                  .map((f) => f.field);
                // console.log(dateTypes,dateTimeTypes,stringTypes,integerTypes,currencyTypes, 'BODY123')
                if (cellParams && dateTypes.includes(rowIndex)) {
                  return cellParams.value ? dateFormatterv2(cellParams.value) : null; //apply your timestamp formatter
                } else if (cellParams && stringTypes.includes(rowIndex)) {
                  return `"${cellParams.value}"`; //apply your string formatter
                } else if (cellParams && dateTimeTypes.includes(rowIndex)) {
                  return cellParams.value ? dateFormatterv3(cellParams.value) : null; //apply your timestamp formatter
                } else return cellParams.value; // no formatting
              },
            fileName: getCSVFileNameString(props.header),
            columnSeparator: ','
        };
        gridApi.exportDataAsCsv(params)
    }

    const onCellClicked= e => props.handleSecondLevelDrilldown("drilldownOppId",  e.data.Opp_ID)


    var metaArr = undefined;
    if(props.metaArr !== undefined){
        metaArr = props.metaArr[0];
    }
    return (
        <Grid container className={classes.root}>
            {props.body ?
                <React.Fragment >
                    <Grid container justify="space-betweeen" className={classes.titleContainer} xs={12}>
                        <Grid item container xs={11} justify="flex-start" alignItems="flex-end" className={classes.bottomSpace}>
                        {
                                metaArr !== undefined ? <> 
                                {
                                    props.showCurrentACV !== 'Acv' ?
                                    <Typography variant="body1" style={{ fontWeight: '600',padding:'0.3rem' }}>
                                    <>{metaArr.baseInfo[0].label}</>&nbsp;&nbsp;&nbsp;&nbsp;
                                    # of Opps: <span data-test="baseInfo-count">{d3.format(",")(props.body.opportunities[0][0].count)}</span>&nbsp;&nbsp;&nbsp;&nbsp;Curr. ACV: $<span data-test="baseInfo-acv">{d3.format(",")(Math.round(props.body.opportunities[0][0].currentAcv))}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;<>Curr. Avg. deal size: $<span data-test="baseInfo-avgAcv">{d3.format(",")(Math.round(props.body.opportunities[0][0].currentAcvAvg))}</span></>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trailing average&nbsp;&nbsp;&nbsp;<># of Opps: <span data-test="averageInfo-count">{props.body.opportunities[0][0].count_avg.toFixed(1)}</span></>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <>Curr. ACV: $<span data-test="averageInfo-acv">{d3.format(",")(Math.round(props.body.opportunities[0][0].current_acv_avg))}</span></>
                            </Typography>
                                     :
                                    <Typography variant="body1" style={{ fontWeight: '600',padding:'0.3rem' }}>
                                    <>{metaArr.baseInfo[0].label}</>&nbsp;&nbsp;&nbsp;&nbsp;
                                    # of Opps: <span data-test="baseInfo-count">{d3.format(",")(props.body.opportunities[0][0].count)}</span>&nbsp;&nbsp;&nbsp;&nbsp;ACV: $<span data-test="baseInfo-acv">{d3.format(",")(Math.round(props.body.opportunities[0][0].acv))}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;<>Avg. deal size: $<span data-test="baseInfo-avgAcv">{d3.format(",")(Math.round(props.body.opportunities[0][0].avg))}</span></>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trailing average&nbsp;&nbsp;&nbsp;<># of Opps: <span data-test="averageInfo-count">{props.body.opportunities[0][0].count_avg.toFixed(1)}</span></>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <>ACV: $<span data-test="averageInfo-acv">{d3.format(",")(Math.round(props.body.opportunities[0][0].acv_avg))}</span></>
                            </Typography>
                                }
                                   
                                 </> :
                                 <>
                                 {
                                    props.nqpScoreCard === true ?
                                    <Typography variant="body1" style={{ fontWeight: '600' }}>Number of opportunities: <span data-test="count">{d3.format(",")(props.body.opportunities[0][0].count)}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: $<span data-test="acv">{d3.format(",")(Math.round(props.body.opportunities[0][0].acv))}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Current ACV: $<span data-test="current-acv">{d3.format(",")(Math.round(props.body.opportunities[0][0].currentAcv))}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                    :
                                    <Typography variant="body1" style={{ fontWeight: '600' }}>Number of opportunities: <span data-test="count">{d3.format(",")(props.body.opportunities[0][0].count)}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: $<span data-test="acv">{d3.format(",")(Math.round(props.body.opportunities[0][0].acv))}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                 }
                               </>
                            }
                            {/*  */}
                        </Grid>
                        <Grid item xs={1} justify="flex-end" alignContent="flex-end" alignItems="flex-end" container className={classes.bottomSpace}>
                            <ExportCSVButton exportCSV={exportCSV} />
                        </Grid>
                        <Grid direction="column" item container xs={1} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.bottomSpace}>

                        </Grid>
                    </Grid>


                    <div
                        className="ag-theme-custom-react_DB"
                        style={{
                            height: containerHeight,
                            width: '100%',
                        }}
                    >
                        <AgGridReact
                            columnDefs={[
                                {
                                    headerName: "#",
                                    field: "OppNo",
                                    type: "rightAligned",
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-right": "3px",
                                    },
                                    headerClass: classes.tableHeader,
                                    maxWidth: 45,
                                },
                                {
                                    headerName: "Date & Time",
                                    field: "stage_change_date",
                                    // minWidth: 100,
                                    flex: 0.8,
                                    suppressSizeToFit: true,
                                    sortable: true,
                                    filter: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-left": "5px",
                                    },
                                    cellClass: classes.tableCell,
                                    headerClass: classes.tableHeader,
                                    valueFormatter: dateAndTimeFormatter

                                },
                                {
                                    headerName: "Account",
                                    field: "Account_Name",
                                    // minWidth: 100,
                                    flex: 1.4,
                                    sortable: true,
                                    filter: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-left": "5px",
                                    },
                                    cellClass: classes.tableCell,
                                    headerClass: classes.tableHeader,
                                    
                                },
                                {
                                    headerName: "Customer Type",
                                    field: "Cust_Type",
                                    // minWidth: 100,
                                    flex: 0.8,
                                    sortable: true,
                                    filter: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-left": "5px",
                                    },
                                    cellClass: classes.tableCell,
                                    headerClass: classes.tableHeader,
                                    
                                },
                                {
                                    headerName: "Opp Name",
                                    field: "OppName",
                                    // minWidth: 100,
                                    flex: 1.6,
                                    suppressSizeToFit: true,
                                    sortable: true,
                                    filter: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-left": "5px",
                                    },
                                    cellClass: classes.tableCell,
                                    headerClass: classes.tableHeader,
                                    
                                },
                                {
                                    headerName: "From Stage",
                                    // minWidth: 80,
                                    flex: 0.6,
                                    field: "fromStage",
                                    type: "rightAligned",
                                    sortable: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-right": "5px",
                                    },
                                    headerClass: classes.tableHeader,

                                },
                                {
                                    headerName: "To Stage",
                                    // minWidth: 80,
                                    flex: 0.6,
                                    field: "toStage",
                                    type: "rightAligned",
                                    sortable: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-right": "5px",
                                    },
                                    headerClass: classes.tableHeader,

                                },
                                {
                                    headerName: "ACV at change",
                                    // minWidth: 60,
                                    flex: 0.6,
                                    field: "acvAtStage",
                                    type: "rightAligned",
                                    sortable: true,
                                    filter: "agNumberColumnFilter",
                                    filterParams: { defaultOption: 'greaterThanOrEqual' },
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-right": "5px",
                                    },
                                    headerClass: classes.tableHeader,
                                    sortingOrder: ["desc", "asc"],
                                    valueFormatter: (x) => {
                                        return "$ " + d3.format(",")(Math.round(x.value));
                                    },
                                },
                                {
                                    headerName: "Current ACV",
                                    // minWidth: 60,
                                    flex: 0.6,
                                    field: "acv",
                                    type: "rightAligned",
                                    sortable: true,
                                    filter: "agNumberColumnFilter",
                                    filterParams: { defaultOption: 'greaterThanOrEqual' },
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-right": "5px",
                                    },
                                    headerClass: classes.tableHeader,
                                    sortingOrder: ["desc", "asc"],
                                    valueFormatter: (x) => {
                                        return "$ " + d3.format(",")(Math.round(x.value));
                                    },
                                },
                                {
                                    headerName: "Projected Close Date",
                                    // minWidth: 80,
                                    flex: 0.7,
                                    field: "Projected_Close_Date",
                                    type: "rightAligned",
                                    sortable: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-right": "5px",
                                    },
                                    headerClass: classes.tableHeader,
                                    valueFormatter: agGridDateFormatter
                                },
                                // {
                                //     headerName: "Solution Category",
                                //     minWidth: 80,
                                //     flex: 1,
                                //     field: "Solution_Cat",
                                //     type: "rightAligned",
                                //     sortable: true,
                                //     cellStyle: {
                                //         "border-right-color": "lightgrey",
                                //         "padding-right": "5px",
                                //     },
                                //     headerClass: classes.tableHeader,

                                // },
                                {
                                    headerName: "Owner",
                                    field: "Owner_Name",
                                    // minWidth: 80,
                                    flex: 0.6,
                                    sortable: true,
                                    filter: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-left": "5px",
                                    },
                                    cellClass: classes.tableCell,
                                    headerClass: classes.tableHeader,
                                },
                                {
                                    headerName: "Team",
                                    resizable: false,
                                    field: "Team",
                                    // minWidth: 80,
                                    flex: 0.8,
                                    sortable: true,
                                    filter: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-left": "5px",
                                    },
                                    cellClass: classes.tableCell,
                                    headerClass: classes.tableHeader,
                                    
                                },
                                {
                                    headerName: "Deal Category",
                                    resizable: false,
                                    field: "deal_category__c",
                                    // minWidth: 80,
                                    flex: 0.8,
                                    sortable: true,
                                    filter: true,
                                    cellStyle: {
                                        "border-right-color": "lightgrey",
                                        "padding-left": "5px",
                                    },
                                    cellClass: classes.tableCell,
                                    headerClass: classes.tableHeader,
                                },
                            ]}
                            sizeColumnsToFit={true}
                            domLayout={domLayout}
                            headerHeight='55'
                            rowData={props.body.recordset}
                            onFirstDataRendered={onFirstDataRendered}
                            onCellClicked={onCellClicked}
                            // pagination={true}
                            // paginationPageSize={6}
                            defaultColDef={defaultColDef}
                            onColumnResized={props.body.recordset.length <= 10 ? onColumnResized : null}
                            enableCellTextSelection={true}
                            // autoHeight={true}
                            colResizeDefault={'shift'}
                            debounceVerticalScrollbar={true}
                            onGridReady={gridReady}
                        // cellClass='cell-wrap-text'

                        >
                            
                        </AgGridReact>
                    </div>
                </React.Fragment>
                :
                <Skeleton animation="wave" variant="rect" width='100%' height={280} style={{ margin: '20px 0px' }} />


            }
        </Grid>

    );
}

export default NQPDrilldownBody;