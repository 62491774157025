import React from "react";
import D3StackedBar from "./D3StackedBar";
import {
  Card,
  Paper,
  CardContent,
  InputAdornment,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Link,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import CompetitorDrilldown from "../CompetitorPerformance/CompetitorDrilldown";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
// import FunnelDrilldownOnOpenBody from './FunnelDrilldownOnOpenBody'
import { AgGridReact } from "ag-grid-react";
import {
  getDefaultSelectedFiscalQuarters,
  getDefaultSalesType,
  getUniqueValues,
} from "../../util/customFunctions";
import Legend from "./Legends";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import NoDataComponent from "../../components/NoDataComponent";
import { styled } from "@mui/material/styles";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import addHeaderdescription from "../../components/HeaderDescription";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root": {
    flexWrap: "nowrap !important",
    overflow: "hidden !important",
    padding: "0 !important",
    fontSize: "1rem !important",
  },

  "& .MuiAutocomplete-input": {
    marginTop: "0.1rem !important",
    marginBottom: "0.3rem !important",
  },
  "& .MuiTypography-root": {
    marginTop: "-0.5rem !important",
  },
  "& .MuiInputBase-input": {
    padding: "0 !importrant",
    paddingBottom: "1rem !important",
    marginTop: "0.1rem !important",
    marginBottom: "0.3rem !important",
  },
  "& .MuiInputBase-input": {
    // marginBottom:'-1.5rem',
    fontSize: "1rem",
  },
  "& .MuiAutocomplete-tag": {
    margin: "0 !important",
    marginBottom: "-0.2rem !important",
  },
  "& .MuiChip-label": {
    // marginBottom:'-1.5rem',
    padding: 0,
    fontSize: "1rem",
    // marginLeft: "-3rem",
  },
  "& .MuiChip-deleteIcon": {
    visibility: "hidden",
    maxWidth: 0,

    // marginBottom:'-13px'
  },
  "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
    backgroundColor: "red",
  },
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddind: "0 ",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    //   "&:hover .MuiOutlinedInput-notchedOutline": {
    //     borderColor: "red"
    //   },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
      background: "red",
    },
  },
  "& .MuiChip-deletable": {
    background: "none !important",
  },
});

const styles = (theme) => ({
  root: {},
  content: { width: "100%" },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  option: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#4472c4",
      borderColor: "transparent",
      color: "white",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#4472c4",
      borderColor: "transparent",
      color: "white",
    },
    '&[aria-activatedscendant="combo-box-demo-option-*"]': {
      backgroundColor: "#4472c4",
      borderColor: "transparent",
      color: "white",
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "30%",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  repNumberOfOpps: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "10%",
    pointer: "cursor",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: 85,
    pointer: "cursor",
  },
  repNumberACVavg: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: 70,
    pointer: "cursor",
  },
  repNumberAvgDays: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    width: "20%",
    pointer: "cursor",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  // filterLabel: {
  //     color: 'black', fontSize: '0.9375rem', paddingTop: 5
  // },
  // disabledFilterLabel: {
  //     color: 'black', fontSize: '0.9375rem', paddingTop: 5
  // },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
});

const nearestCompletedFiscalQuarter = () => {
  let year = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  if (currentMonth < 3) {
    return year - 1 + "-Q4";
  } else if (currentMonth > 2 && currentMonth < 6) {
    return year + "-Q1";
  } else if (currentMonth > 5 && currentMonth < 9) {
    return year + "-Q2";
  } else {
    return year + "-Q3";
  }
};

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const formatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return d3.format(",")(value.toFixed(1));
};

const stringNumFormatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return d3.format(",")(Math.round(value));
};

const currencyFormatter = (value, Currency_Symbol) => {
  if (_isNaN(value) || value === null) return `${Currency_Symbol} 0`;
  return `${Currency_Symbol}` + d3.format(",")(Math.round(value));
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="$"
      allowNegative={false}
    />
  );
}

class Funnel extends React.Component {
  tableRef = React.createRef();
  state = {
    Open_Year_Month: ["All"],
    Opportunity_Record_Type: ["All"],
    Closed_Year_Month: ["All"],
    Projected_Close_Year_Month: ["All"],
    Record_Type_Name__c: ["All"],
    Type: ["All"],
    closed_year: ["All"],
    closed_month: ["All"],
    closed_fiscal_quarter: ["All"],
    activeTimeFilter: "closed_fiscal_quarter",
    projected_close_calendar_year: ["All"],
    projected_close_calendar_month: ["All"],
    projected_close_fiscal_quarter: ["All"],
    projected_close_date_year: ["All"],
    projected_close_date_month: ["All"],
    projected_close_date_fiscal_quarter: ["All"],
    open_calendar_year: ["All"],
    open_calendar_month: ["All"],
    open_fiscal_quarter: ["All"],
    validated_year: ["All"],
    validated_month: ["All"],
    validated_fiscal_quarter: ["All"],
    rep: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    Cust_Type: ["All"],
    Region: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    filtersForDrilldown: [],
    Solution_Cat: ["All"],
    Lost_Reason: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [
      // 'year', 'month', 'quarter', 'team', 'acv'
    ],
    repTeam: "rep",
    acv: null,
    acvlt: 0,
    acvPreset: "All",
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    location: "",
    secondLevelLocation: "",
    repvalues: this.rep,
    Earliest_Lost_Stage: [],
    Exclude_Lost_Reason: ["None"],
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          quarter: "validated_fiscal_quarter",
          year: "validated_year",
          month: "validated_month",
          Open_Year_Month: "Open_Year_Month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
          Projected_Close_Year_Month: "Projected_Close_Year_Month",
          Earliest_Lost_Stage: "Earliest_Lost_Stage",
          Exclude_Lost_Reason: "Exclude_Lost_Reason",
          Closed_Year_Month: "Closed_Year_Month",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          LeadSource: "Lead_Source",
          quarter: "open_fiscal_quarter",
          year: "open_calendar_year",
          Open_Year_Month: "Open_Year_Month",
          month: "open_calendar_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
          Projected_Close_Year_Month: "Projected_Close_Year_Month",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Earliest_Lost_Stage: "Earliest_Lost_Stage",
          Exclude_Lost_Reason: "Exclude_Lost_Reason",
          Closed_Year_Month: "Closed_Year_Month",
        };

  filters = [
    "closed_year",
    "closed_month",
    "closed_fiscal_quarter",
    "Projected_Close_Year_Month",
    "Open_Year_Month",
    this.variableCols.projectedMonth,
    this.variableCols.projectedYear,
    this.variableCols.projectedQuarter,
    this.variableCols.year,
    this.variableCols.month,
    this.variableCols.quarter,
    "rep",
    "team",
    this.variableCols.CustomerSegment,
    this.variableCols.CustomerType,
    this.variableCols.Industry,
    "Acct_Segment",
    this.variableCols.LeadSource,
    "Opp_Created_By",
    "Solution_Cat",
    "Lost_Reason",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "Earliest_Lost_Stage",
    "Exclude_Lost_Reason",
    "Opportunity_Record_Type",
    "Record_Type_Name__c",
    "Type",
    "Closed_Year_Month",
  ];

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Qtr.",
          value: this.state.closed_fiscal_quarter,
        },
      ];
    }
    if (!this.state.closed_year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Calendar Year", value: this.state.closed_year },
      ];
    }
    if (!this.state.closed_month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Month",
          value: this.state.closed_month.map((i) => this.months[i - 1]),
        },
      ];
    }

    if (!this.state.Closed_Year_Month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Month",
          value: this.state.Closed_Year_Month,
        },
      ];
    }

    if (!this.state.Projected_Close_Year_Month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Month",
          value: this.state.Projected_Close_Year_Month,
        },
      ];
    }

    if (!this.state.Projected_Close_Year_Month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Month",
          value: this.state.Projected_Close_Year_Month,
        },
      ];
    }

    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (this.state.acv !== null && this.state.acv !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} >=`,
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (!this.state.acvlt !== null && this.state.acvlt !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Less Than`,
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt
            ),
        },
      ];

    if (!this.state[this.variableCols.projectedQuarter].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Closed Fiscal Qtr.",
          value: this.state[this.variableCols.projectedQuarter],
        },
      ];

    if (!this.state[this.variableCols.projectedYear].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Calendar Year:",
          value: this.state[this.variableCols.projectedYear],
        },
      ];

    if (!this.state[this.variableCols.projectedMonth].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Calendar Month",
          value: this.state[this.variableCols.projectedMonth].map(
            (i) => this.months[i - 1]
          ),
        },
      ];

    if (!this.state[this.variableCols.Industry].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state[this.variableCols.Industry],
        },
      ];

    if (!this.state[this.variableCols.CustomerSegment].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Customer Segment",
          value: this.state[this.variableCols.CustomerSegment],
        },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state[this.variableCols.deal_category__c].includes("All")
    )
      filtersArray = [
        ...filtersArray,
        {
          title: "Deal Category",
          value: this.state[this.variableCols.deal_category__c],
        },
      ];

    if (!this.state[this.variableCols.CustomerType].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value: this.state[this.variableCols.CustomerType],
        },
      ];

    if (!this.state[this.variableCols.quarter].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Stage E Fiscal Qtr.:"
              : "Open Fiscal Qtr.:",
          value: this.state[this.variableCols.quarter],
        },
      ];

    if (!this.state[this.variableCols.year].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Stage E Fiscal Year:"
              : "Open Fiscal Year:",
          value: this.state[this.variableCols.year],
        },
      ];

    if (!this.state[this.variableCols.month].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Stage E Fiscal Month:"
              : "Open Fiscal Month:",
          value: this.state[this.variableCols.month].map(
            (i) => this.months[i - 1]
          ),
        },
      ];

    if (!this.state.cross_sell1__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Sales Type", value: this.state.cross_sell1__c },
      ];

    if (!this.state.Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0017" ? "Opp. Type" : "Deal Type",
          value: this.state.Type,
        },
      ];

    if (!this.state[this.variableCols.LeadSource].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Lead Source",
          value: this.state[this.variableCols.LeadSource],
        },
      ];

    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    if (!this.state.Opportunity_Record_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];

    if (!this.state.Record_Type_Name__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Opp Record Type",
          value: this.state.Record_Type_Name__c,
        },
      ];

    if (!this.state.Lost_Reason.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lost Reason", value: this.state.Lost_Reason },
      ];

    return filtersArray;
  };

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  staticCustSegment =
    this.props.company === "C0002"
      ? [
          { value: "<=$100M", displayValue: "<=$100M" },
          { value: "$100M-$1B", displayValue: "$100M-$1B" },
          { value: ">$1B", displayValue: ">$1B" },
        ]
      : [
          { value: "<=$25M", displayValue: "<=$25M" },
          { value: "$25-50M", displayValue: "$25M-50M" },
          { value: "$50-250M", displayValue: "$50M-250M" },
          { value: "$250-500M", displayValue: "$250M-500M" },
          { value: "$500M-2B", displayValue: "$500M-2B" },
          { value: ">$2B", displayValue: ">$2B" },
        ];

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
    this.props.getFunnelFilters(
      this.props.company,
      this.state.activeTimeFilter
    );
  }
  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChangeLocation = (location, stage, data, type, quarter) => {
    console.log(location, stage, data, type, quarter, "handleChange");
    if (location === "drilldownOnGraph" && data !== "lostReason") {
      let filters = [...this.props.currentFilters];
      filters = [...filters, { name: "Stage", value: stage, string: true }];
      this.props.getLostOppDrilldown(filters);

      let filtersForDrilldown = [
        { title: "Stage", value: stage },
        ...this.state.filtersForDrilldown.filter((i) => i.title !== "Stage"),
      ];

      this.setState({
        ...this.state,
        location: location,
        filtersForDrilldown,
      });
    }
    if (location === "drilldownOnGraph" && data === "lostReason") {
      let filters = [
        ...this.props.currentFilters.filter((c) => c.name !== "Lost_Reason"),
      ];
      filters =
        stage.cell === "Total"
          ? filters
          : [
              ...filters.filter((c) => c.name !== "closed_fiscal_quarter"),
              {
                name: this.state.activeTimeFilter,
                string: true,
                value:
                  typeof stage.cell === "string" ? [stage.cell] : stage.cell,
              },
            ];
      console.log("After_Change", ...filters);

      const stages = this.props.lostResonData.data
        .filter((s) => s.quarter !== "Total" && s.lostReason !== "Total")
        .map((s) => s.lostReason)
        .filter(getUniqueValues);
      console.log(filters);
      let drilldownFilters = [
        ...filters,
        {
          name: "Lost_Reason",
          value: stage.row === "Total" ? stages : [stage.row],
          string: true,
        },
      ];
      let lostResonfilters = { filters, drilldownFilters };
      console.log(JSON.stringify({ filters, drilldownFilters }));
      this.props.getLostResonDrilldown(filters, drilldownFilters);
      console.log(this.state.filtersForDrilldown);
      let filtersForDrilldown = [
        // {
        //   title: "Closed Fiscal Quarter",
        //   value: filters.filter((c) => c.name === "closed_fiscal_quarter")[0]
        //     .value,
        // },
        {
          title: "Lost Reason",
          value: stage.row === "Total" ? stages : stage.row,
        },
        ...this.state.filtersForDrilldown.filter(
          (i) =>
            i.title !== "Lost Reason" &&
            i.title !== "Closed Fiscal Qtr." &&
            i.title !== "Stage"
        ),
      ];

      this.setState({
        ...this.state,
        location: location,
        filtersForDrilldown,
      });
    }
  };

  // handleChangeLocationBack = () => {
  //     this.setState({
  //         ...this.state,
  //         location: '',

  //     })
  // }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    D3StackedBar.destroy(this._rootNode);
    this._chart = D3StackedBar.create(this._rootNode, {
      data: this.props.funnelData,
      stage: this.props.stageData,
      Value_Label: `${this.props.Value_Label}`,
      handleChangeLocation: this.handleChangeLocation,
      company: this.props.company,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      let solutioncat = ["All"];
      dealsTypes = getDefaultSalesType(
        this.props.funnelFilters.filter((s) => s.type === "Type"),
        "Type"
      );
      solutioncat = getDefaultSalesType(
        this.props.funnelFilters.filter((s) => s.type === "Solution_Cat"),
        "Solution_Cat"
      );
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelFilters.filter((s) => s.type === "cross_sell1__c"),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }

      const earliestLostStage = this.props.funnelFilters
        .filter(
          (item) =>
            item.type === "Earliest_Lost_Stage" &&
            item.selected_by_default === "Y"
        )
        .map((i) => i.stage_name);

      this.setState({
        ...prevState,
        closed_fiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelFilters,
          "closed_fiscal_quarter"
        ),
        Earliest_Lost_Stage: earliestLostStage,
        filtersForDrilldown: [
          {
            title: "Closed Fiscal Qtr.",
            value: getDefaultSelectedFiscalQuarters(
              this.props.funnelFilters,
              "closed_fiscal_quarter"
            ),
          },
        ],
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        Solution_Cat: solutioncat,
      });
    }
    if (this.props.funnelData !== "" && prevProps.funnelData === "") {
      this._chart = D3StackedBar.create(this._rootNode, {
        data: this.props.funnelData,
        stage: this.props.stageData,
        Value_Label: `${this.props.Value_Label}`,
        handleChangeLocation: this.handleChangeLocation,
        company: this.props.company,
      });
    }
    if (
      this.props.funnelData !== prevProps.funnelData &&
      prevProps.funnelData !== ""
    ) {
      // D3 Code to update the chart
      D3StackedBar.destroy(this._rootNode);
      this._chart = D3StackedBar.create(this._rootNode, {
        data: this.props.funnelData,
        stage: this.props.stageData,
        Value_Label: `${this.props.Value_Label}`,
        handleChangeLocation: this.handleChangeLocation,
        company: this.props.company,
      });
    }
    console.log(this.props);
  }

  /* componentWillUnmount() {
        D3Funnel.destroy(this._rootNode);
    } */

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }

  handleChange = (e) => {
    const activeTimeFilter = [
      "closed_fiscal_year",
      "closed_fiscal_quarter",
      "Closed_Year_Month",
      // "Projected_Close_Year_Month",
      // "Open_Initial_Year_Month",
      // "Open_Year_Month",
    ].includes(e.target.name)
      ? e.target.name
      : this.state.activeTimeFilter;
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        activeTimeFilter,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else if (typeof e.target.value === "string") {
      this.setState({
        ...this.state,
        activeTimeFilter,
        [e.target.name]: [e.target.value],
      });
    } else if (typeof e.target.value === "string") {
      this.setState({
        ...this.state,
        activeTimeFilter,
        [e.target.name]: [e.target.value],
      });
    } else {
      this.setState({
        ...this.state,
        activeTimeFilter,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleChangeLostreason = (e) => {
    this.setState({
      ...this.state,
      Exclude_Lost_Reason:
        this.state[e.target.name].length === 1 &&
        this.state[e.target.name].includes("None")
          ? e.target.value.filter((v) => v !== "None").length > 0
            ? e.target.value.filter((v) => v !== "None")
            : ["None"]
          : e.target.value.includes("None")
          ? ["None"]
          : e.target.value,
    });
  };

  getSelectedRepIDs = () => {
    console.log("test");
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .filter(getUniqueValues)
      .map((f) => {
        console.log(this.state[f]);
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string:
            f === "team"
              ? true
              : this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "None") ||
          !f.value.includes("None")
      )
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    this.props.getFunnelDataNew(filters, this.state.activeTimeFilter);

    this.setState({ filtersForDrilldown: this.passFiltersForDrilldown() });
  };

  orderRepsByLastName = () => {
    let temp = this.props.funnelFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleChangeLocationBack = (location) => {
    console.log(location);
    this.setState({
      ...this.state,
      location: "",
      secondLevelLocation: "",
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  labelMap = {
    closed_fiscal_quarter: "Closed Fiscal Qtr.",
    Closed_Year_Month: "Closed Month",
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Lost Opportunities Analysis
          </Typography>
          <Typography variant="body1">
            Analyze when and where opportunities were lost from across multiple
            dimensions.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Lost Opportunities Analysis",
          "Analyze when and where opportunities were lost from across multiple dimensions."
        )}

        {this.props.funnelFilters !== "" ? (
          <>
            {/* closed dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
              md={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.closed_year[0] !== "All" ||
                      this.state.closed_month[0] !== "All" ||
                      this.state.Closed_Year_Month[0] !== "All"
                    }
                  >
                    <InputLabel
                      style={{
                        color: !this.state.closed_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-fiscalQuarter-label"
                    >
                      Closed Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state.closed_fiscal_quarter}
                      onChange={this.handleChange}
                      name="closed_fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "closed_fiscal_quarter" ? (
                            <MenuItem
                              key={y["closed_fiscal_quarter"]}
                              value={y["closed_fiscal_quarter"]}
                            >
                              {y["closed_fiscal_quarter"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                  >
                    <InputLabel
                      style={{
                        color: !this.state.closed_year.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-year-label"
                    >
                      Closed Month:
                    </InputLabel>
                    <Select
                      value={this.state.Closed_Year_Month}
                      onChange={this.handleChange}
                      name="Closed_Year_Month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter((y) => y.type === "Closed_Year_Month")
                          .sort()
                          .map((y) =>
                            y.type === "Closed_Year_Month" ? (
                              <MenuItem
                                key={y["Closed_Year_Month"]}
                                value={y["Closed_Year_Month"]}
                              >
                                {y["Closed_Year_Month"]}
                              </MenuItem>
                            ) : null
                          )}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      style={{
                        color: !this.state.closed_month.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-month-label"
                    >
                      Closed Calendar Month:
                    </InputLabel>
                    <Select
                      value={this.state.closed_month}
                      onChange={this.handleChange}
                      name="closed_month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>

            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{ color: "#4472c4" }}
                      id="select-industry-label"
                    >
                      Earliest Lost from Stage:
                    </InputLabel>
                    <Select
                      data-test="Earliest lost from Stage"
                      value={this.state.Earliest_Lost_Stage}
                      onChange={this.handleChange}
                      name="Earliest_Lost_Stage"
                    >
                      {/* <MenuItem value={'All'} >All</MenuItem> */}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Earliest_Lost_Stage" &&
                              y.stage_name !== ""
                          )
                          .map((y) => (
                            <MenuItem key={y.stage_name} value={y.stage_name}>
                              {y.stage_name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Rep Team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={
                      this.props.funnelFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField {...params} label="Rep:" variant="standard" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                      isSearchable={true}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* ACV and GO */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              {/* <Grid item spacing={2} xs={8} xl={9} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ paddingTop: 0, height: "0px" }}
                >
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    getOptionLabel={(option) =>
                      typeof option === "string"
                        ? option
                        : stringNumFormatter(parseInt(option))
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: 4 }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid> */}
              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  disabled={this.state.acvError !== ""}
                >
                  GO
                </Button>
              </Grid>
            </Grid>

            {/* projected close dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.projected_close_calendar_year[0] !== "All" ||
                      this.state.projected_close_calendar_month[0] !== "All" ||
                      this.state.Projected_Close_Year_Month[0] !== "All"
                    }
                  >
                    <InputLabel
                      style={{
                        color: !this.state[
                          this.variableCols.projectedQuarter
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.projectedQuarter]}
                      onChange={this.handleChange}
                      name={this.variableCols.projectedQuarter}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === this.variableCols.projectedQuarter ? (
                            <MenuItem
                              key={y[this.variableCols.projectedQuarter]}
                              value={y[this.variableCols.projectedQuarter]}
                            >
                              {y[this.variableCols.projectedQuarter]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.projected_close_fiscal_quarter[0] !== "All"
                    }
                  >
                    <InputLabel
                      style={{
                        color: !this.state[
                          this.variableCols.Projected_Close_Year_Month
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-year-label"
                    >
                      Proj. Close Month:
                    </InputLabel>
                    <Select
                      value={
                        this.state[this.variableCols.Projected_Close_Year_Month]
                      }
                      onChange={this.handleChange}
                      name={this.variableCols.Projected_Close_Year_Month}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type ===
                          this.variableCols.Projected_Close_Year_Month ? (
                            <MenuItem
                              key={
                                y[this.variableCols.Projected_Close_Year_Month]
                              }
                              value={
                                y[this.variableCols.Projected_Close_Year_Month]
                              }
                            >
                              {y[this.variableCols.Projected_Close_Year_Month]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={
                      this.state.projected_close_fiscal_quarter[0] !== "All"
                    }
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      style={{
                        color: !this.state[
                          this.variableCols.projectedMonth
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-month-label"
                    >
                      Proj. Close Calendar Month:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.projectedMonth]}
                      onChange={this.handleChange}
                      name={this.variableCols.projectedMonth}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>

            {/* industry, customer segment, customer type */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={12}
              lg={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                {/* <Grid item xs={12} style={{ paddingBottom: 8 }}>
                            <Typography variant='body1'>Customer</Typography>
                        </Grid> */}

                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-region-label"
                    >
                      Customer Segment:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Region:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Open/Validated  dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      !this.state[this.variableCols.year].includes("All") ||
                      !this.state[this.variableCols.month].includes("All") ||
                      !this.state[this.variableCols.Open_Year_Month].includes(
                        "All"
                      )
                    }
                  >
                    <InputLabel
                      style={{
                        color: !this.state[this.variableCols.quarter].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-fiscalQuarter-label"
                    >
                      {this.props.company === "C0003" ? "Qualifying " : "Open "}{" "}
                      Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.quarter]}
                      onChange={this.handleChange}
                      name={this.variableCols.quarter}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === this.variableCols.quarter ? (
                            <MenuItem
                              key={y[this.variableCols.quarter]}
                              value={y[this.variableCols.quarter]}
                            >
                              {y[this.variableCols.quarter]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      !this.state[this.variableCols.quarter].includes("All")
                    }
                  >
                    <InputLabel
                      style={{
                        color: !this.state[this.variableCols.year].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-year-label"
                    >
                      {this.props.company === "C0003" ? "Qualifying " : "Open "}{" "}
                      {/* Calendar Year: */}
                      Month
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Open_Year_Month]}
                      onChange={this.handleChange}
                      name={this.variableCols.Open_Year_Month}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === this.variableCols.Open_Year_Month ? (
                            <MenuItem
                              key={y[this.variableCols.Open_Year_Month]}
                              value={y[this.variableCols.Open_Year_Month]}
                            >
                              {y[this.variableCols.Open_Year_Month]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={
                      !this.state[this.variableCols.quarter].includes("All")
                    }
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      style={{
                        color: !this.state[this.variableCols.month].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-month-label"
                    >
                      {this.props.company === "C0003" ? "Qualifying " : "Open "}{" "}
                      Calendar Month:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.month]}
                      onChange={this.handleChange}
                      name={this.variableCols.month}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>

            {/* Lead source, opportunity created by, solution cat */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={12}
              lg={this.props.company === "C0003" ? 4 : 6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                {/* <Grid item xs={12} style={{ paddingBottom: 8 }}>
                            <Typography variant='body1'>Filters</Typography>
                        </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={this.props.company === "C0003" ? 6 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state[
                          this.variableCols.LeadSource
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-region-label"
                    >
                      Lead Source:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.LeadSource]}
                      onChange={this.handleChange}
                      name={this.variableCols.LeadSource}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.LeadSource &&
                              y[this.variableCols.LeadSource] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.LeadSource] <
                            b[this.variableCols.LeadSource]
                              ? -1
                              : a[this.variableCols.LeadSource] >
                                b[this.variableCols.LeadSource]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.LeadSource]}
                              value={y[this.variableCols.LeadSource]}
                            >
                              {y[this.variableCols.LeadSource]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={this.props.company === "C0003" ? 6 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state.Opp_Created_By.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-industry-label"
                    >
                      Opportunity created by:
                    </InputLabel>
                    <Select
                      value={this.state.Opp_Created_By}
                      onChange={this.handleChange}
                      name="Opp_Created_By"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Opp_Created_By" &&
                              y["Opp_Created_By"] !== ""
                          )
                          .sort((a, b) =>
                            a["Opp_Created_By"] < b["Opp_Created_By"]
                              ? -1
                              : a["Opp_Created_By"] > b["Opp_Created_By"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y["Opp_Created_By"]}
                              value={y["Opp_Created_By"]}
                            >
                              {y["Opp_Created_By"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                {this.props.company !== "C0003" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        style={{
                          color: !this.state.Solution_Cat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        className={classes.filterLabel}
                        id="select-industry-label"
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        value={this.state.Solution_Cat}
                        onChange={this.handleChange}
                        name="Solution_Cat"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Solution_Cat" &&
                                y["Solution_Cat"] !== ""
                            )
                            .sort((a, b) =>
                              a["Solution_Cat"] < b["Solution_Cat"]
                                ? -1
                                : a["Solution_Cat"] > b["Solution_Cat"]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y["Solution_Cat"]}
                                value={y["Solution_Cat"]}
                              >
                                {y["Solution_Cat"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {this.props.company === "C0008" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                justify="space-between"
                xs={4}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.cross_sell1__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Sales Type:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.cross_sell1__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.cross_sell1__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.cross_sell1__c &&
                                y[this.variableCols.cross_sell1__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.cross_sell1__c]}
                                value={y[this.variableCols.cross_sell1__c]}
                              >
                                {y[this.variableCols.cross_sell1__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[this.variableCols.Type].includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Type:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.Type]}
                        onChange={this.handleChange}
                        name={this.variableCols.Type}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Type &&
                                y[this.variableCols.Type] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Type]}
                                value={y[this.variableCols.Type]}
                              >
                                {y[this.variableCols.Type]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*  */}
            {this.props.company === "C0013" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                // style={{ padding: 10, paddingTop: 15 }}
                xs={12}
                lg={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Opportunity_Record_Type.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opportunity Record Type:
                      </InputLabel>
                      <Select
                        data-test="Opportunity_Record_Type"
                        value={this.state.Opportunity_Record_Type}
                        onChange={this.handleChange}
                        name="Opportunity_Record_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Opportunity_Record_Type" &&
                                y.Opportunity_Record_Type !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Opportunity_Record_Type}
                                value={y.Opportunity_Record_Type}
                              >
                                {y.Opportunity_Record_Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* Adding Acv Value */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              md={4}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "4px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "4px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {this.props.company !== "C0003" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                justify="space-between"
                xs={12}
                lg={this.props.company === "C0001" ? 4 : 2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={this.props.company === "C0001" ? 12 : 12}
                  container
                  component={Paper}
                >
                  <Grid
                    item
                    xs={this.props.company === "C0001" ? 6 : 12}
                    style={{ paddingTop: 0 }}
                  >
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        style={{
                          color: !this.state.Lost_Reason.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        className={classes.filterLabel}
                        id="select-industry-label"
                      >
                        Lost Reason:
                      </InputLabel>
                      <Select
                        value={this.state.Lost_Reason}
                        onChange={this.handleChange}
                        name="Lost_Reason"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Lost_Reason" &&
                                y["Lost_Reason"] !== ""
                            )
                            .sort((a, b) =>
                              a["Lost_Reason"] < b["Lost_Reason"]
                                ? -1
                                : a["Lost_Reason"] > b["Lost_Reason"]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y["Lost_Reason"]}
                                value={y["Lost_Reason"]}
                              >
                                {y["Lost_Reason"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.props.company === "C0001" && (
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.deal_category__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Category:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={this.state[this.variableCols.deal_category__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.deal_category__c}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols.deal_category__c &&
                                  y[this.variableCols.deal_category__c] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.deal_category__c]}
                                  value={y[this.variableCols.deal_category__c]}
                                >
                                  {y[this.variableCols.deal_category__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            {(
              this.props.company !== "C0003" &&
              this.props.funnelFilters.filter(
                (y) => y.type === "Exclude_Lost_Reason"
              )
            ).length > 0 && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                justify="space-between"
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        style={{
                          color: !this.state.Exclude_Lost_Reason.includes(
                            "None"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        className={classes.filterLabel}
                        id="select-industry-label"
                      >
                        Exclude Lost Reason:
                      </InputLabel>
                      <Select
                        value={this.state.Exclude_Lost_Reason}
                        onChange={this.handleChangeLostreason}
                        name="Exclude_Lost_Reason"
                        multiple
                      >
                        <MenuItem value={"None"}>None</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Exclude_Lost_Reason" &&
                                y["Exclude_Lost_Reason"] !== ""
                            )
                            .sort((a, b) =>
                              a["Exclude_Lost_Reason"] <
                              b["Exclude_Lost_Reason"]
                                ? -1
                                : a["Exclude_Lost_Reason"] >
                                  b["Exclude_Lost_Reason"]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y["Exclude_Lost_Reason"]}
                                value={y["Exclude_Lost_Reason"]}
                              >
                                {y["Exclude_Lost_Reason"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* C0010 */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                // style={{ padding: 10, paddingTop: 15 }}
                xs={12}
                lg={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChange}
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Record_Type_Name__c" &&
                                y.Record_Type_Name__c !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Record_Type_Name__c}
                                value={y.Record_Type_Name__c}
                              >
                                {y.Record_Type_Name__c}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0017" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                // style={{ padding: 10, paddingTop: 15 }}
                xs={12}
                lg={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp. Type:
                      </InputLabel>
                      <Select
                        data-test="Type"
                        value={this.state.Type}
                        onChange={this.handleChange}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter((y) => y.type === "Type" && y.Type !== "")
                            .map((y) => (
                              <MenuItem key={y.Type} value={y.Type}>
                                {y.Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        {/* Summary Table and Bar Graph */}
        <Grid
          item
          container
          spacing={1}
          justify="space-around"
          component={Paper}
          style={{ marginTop: "10px", marginLeft: 2, marginRight: 8 }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              align="center"
              style={{ marginBottom: 10 }}
            >
              Opportunities Lost from stage
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            {this.props.funnelData ? (
              <div
                className={classes.root}
                style={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CardContent className={classes.content}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{ marginTop: 20, width: "100%" }}
                      className="line-container"
                      ref={this._setRef.bind(this)}
                    />
                  </div>
                  {/* <Legend legends={this.props.stageData} /> */}
                  {/*  {
                                               !['',undefined,null].includes(this.props.stageData) && <div> <Legend legends={this.props.stageData} /> </div>
                                        } */}
                </CardContent>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="100%"
                  height={150}
                  style={{ margin: "20px 0px" }}
                />
              </div>
            )}
          </Grid>
          {/* For stage_names display */}
          <Grid item xs={12} sm={12} md={12}>
            {this.props.funnelData ? (
              <Legend legends={this.props.stageData} />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="100%"
                  height={150}
                  style={{ margin: "20px 0px" }}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} style={{ marginTop: 20 }}>
            {this.props.funnelData && this.props.funnelData !== "" ? (
              <div
                className={classes.root}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CardContent className={classes.content}>
                  <Grid container justify="center">
                    <TableContainer
                      className={classes.mainTable}
                      // component={Paper}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                          // marginRight: "10px",
                        }}
                      >
                        <CopyTablesObject tableRef={this.tableRef} />
                      </div>
                      <Table
                        ref={this.tableRef}
                        size="small"
                        aria-label="html table"
                        style={{ tableLayout: "auto" }}
                        data-test="lost_opp_table"
                      >
                        <TableHead>
                          <TableRow
                            style={{
                              fontWeight: 600,
                              background: "#4472c4",
                              color: "white",
                              paddingLeft: 5,
                            }}
                          >
                            <TableCell
                              align="center"
                              className={classes.repHeader}
                            >
                              Lost from Stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.repHeader}
                            >
                              # of Opps
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.repHeader}
                            >
                              {/* ACV */}
                              {`${this.props.Value_Label}`}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.repHeader}
                            >
                              {/* Avg. ACV */}
                              {`Avg. ${this.props.Value_Label}`}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.repHeader}
                            >
                              Avg. days open
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.funnelData.map((i) => {
                            return (
                              <StyledTableRow key={i.stage_name}>
                                <TableCell
                                  align="center"
                                  className={classes.repText}
                                >
                                  {i.stage_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleChangeLocation(
                                      "drilldownOnGraph",
                                      [i.stage_name]
                                    )
                                  }
                                  className={classes.repNumberOfOpps}
                                >
                                  {formatter(i.count)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleChangeLocation(
                                      "drilldownOnGraph",
                                      [i.stage_name]
                                    )
                                  }
                                  className={classes.repNumberACV}
                                >
                                  {currencyFormatter(
                                    i.acv,
                                    this.props.Currency_Symbol
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleChangeLocation(
                                      "drilldownOnGraph",
                                      [i.stage_name]
                                    )
                                  }
                                  className={classes.repNumberACVavg}
                                >
                                  {currencyFormatter(
                                    i.acv / i.count,
                                    this.props.Currency_Symbol
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleChangeLocation(
                                      "drilldownOnGraph",
                                      [i.stage_name]
                                    )
                                  }
                                  className={classes.repNumberAvgDays}
                                >
                                  {formatter(i.avg_cycle_time)}
                                </TableCell>
                              </StyledTableRow>
                            );
                          })}

                          <StyledTableRow style={{ fontWeight: 600 }}>
                            <TableCell
                              align="center"
                              className={classes.repTextH}
                            >
                              {"Total"}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.handleChangeLocation(
                                  "drilldownOnGraph",
                                  this.props.funnelData.map((i) => i.stage_name)
                                )
                              }
                              className={classes.repNumberH}
                            >
                              {formatter(
                                this.props.funnelData.reduce(
                                  (total, i) => total + i.count,
                                  0
                                )
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.handleChangeLocation(
                                  "drilldownOnGraph",
                                  this.props.funnelData.map((i) => i.stage_name)
                                )
                              }
                              className={classes.repNumberH}
                            >
                              {currencyFormatter(
                                this.props.funnelData.reduce(
                                  (total, i) => total + i.acv,
                                  0
                                ),
                                this.props.Currency_Symbol
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.handleChangeLocation(
                                  "drilldownOnGraph",
                                  this.props.funnelData.map((i) => i.stage_name)
                                )
                              }
                              className={classes.repNumberH}
                            >
                              {currencyFormatter(
                                this.props.funnelData.reduce(
                                  (total, i) => total + i.acv,
                                  0
                                ) /
                                  this.props.funnelData.reduce(
                                    (total, i) => total + i.count,
                                    0
                                  ),
                                this.props.Currency_Symbol
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.handleChangeLocation(
                                  "drilldownOnGraph",
                                  this.props.funnelData.map((i) => i.stage_name)
                                )
                              }
                              className={classes.repNumberH}
                            >
                              {formatter(
                                Math.round(
                                  this.props.funnelData.reduce(
                                    (total, i) => total + i.avg_cycle_time,
                                    0
                                  ) / this.props.funnelData.length
                                )
                              )}
                            </TableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </CardContent>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* <Skeleton animation="wave" variant="text" width='60%' style={{ margin: '20px 10px', alignSelf: 'flex-start' }} />
                                <Divider style={{ width: '100%' }} /> */}
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="100%"
                  height={150}
                  style={{ margin: "20px 0px" }}
                />
              </div>
            )}
          </Grid>
        </Grid>

        {this.props.company !== "C0003" && (
          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
            style={{ marginTop: "1rem" }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "2rem",
                padding: "0.5reme",
                paddingBottom: "2rem",
              }}
            >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.lostResonData && this.props.lostResonData !== "" ? (
                  <Grid item container xs={12} justify="center">
                    <Grid className={classes.grid} item xs={12}>
                      <DataVisualizer
                        chartName={`Lost Reason`}
                        title={`Lost Reason`}
                        type={this.props.lostResonData.type}
                        chartType={"acv"}
                        Value_Label={this.props.Value_Label}
                        winACVMixAnalysisData={this.props.lostResonData.data
                          .filter(
                            (s) =>
                              s.quarter !== "Total" && s.lostReason !== "Total"
                          )
                          .filter((it) => !(it.acv === 0 && it.count === 0))
                          .sort((a, b) => b.acv - a.acv)}
                        handleChangeLocation={this.handleChangeLocation}
                        quarter={this.props.lostResonData.timeDimension}
                        //   tableComponent={type === "Acct_Segment" ? <DynamicTableSegment
                        //                                     type={'cfq'} quarter={"projected_close_fiscal_quarter"} winAcvData={this.props.lostResonData.data} handleChangeLocation={this.handleChangeLocation} /> : undefined }
                        lostReason={undefined}
                        unit={"acv"}
                        company={this.props.company}
                        clusterTotal={[]}
                        sortByTotal={false}
                        label={this.props.lostResonData.label}
                        cellHeader={this.labelMap[this.state.activeTimeFilter]}
                        //   toggleValue={this.state.toggleValue}
                        //   handleChangeToggle={this.handleChangeToggle}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {/* <Skeleton animation="wave" variant="text" width='60%' style={{ margin: '20px 10px', alignSelf: 'flex-start' }} />
                                <Divider style={{ width: '100%' }} /> */}
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="100%"
                      height={150}
                      style={{ margin: "20px 0px" }}
                    />
                  </div>
                )}
              </Card>
            </Grid>
          </Grid>
        )}

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Lost Opportunities Analysis"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={"Lost Opportunities Analysis"}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}

        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

function mapStateToProps(state) {
  const {
    closedLostOppFilters,
    closedLostOppData,
    persistentAlert,
    user,
    updatingFunnelData,
    currentFilters,
    drilldownOnGraphData,
    ExpectedAcvData,
  } = state.app;

  console.log(drilldownOnGraphData);
  return {
    funnelFilters: closedLostOppFilters,
    funnelData: closedLostOppData[0],
    tableData: closedLostOppData[1],
    stageData: closedLostOppData[2],
    lostResonData: closedLostOppData[3],
    persistentAlert,
    company: user.company,
    Value_Label: user.Value_Label,
    Currency_Symbol: user.Currency,
    updatingFunnelData,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: (company, key) => {
    dispatch({ type: "get_closed_lost_opp_analysis_filters_request" });
    appService.getClosedLostOppAnalysisFilters().then(
      (json) => {
        dispatch({
          type: "get_closed_lost_opp_analysis_filters_success",
          json,
        });
        dispatch({ type: "get_closed_lost_opp_analysis_data_request" });
        var filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];

        const deaultOppType = getDefaultSalesType(
          json.message.filter((i) => i.type === "Type"),
          "Type"
        );
        const defaultSolutionCat = getDefaultSalesType(
          json.message.filter((i) => i.type === "Solution_Cat"),
          "Solution_Cat"
        );

        const defaultStages = json.message
          .filter(
            (item) =>
              item.type === "Earliest_Lost_Stage" &&
              item.selected_by_default === "Y"
          )
          .map((i) => i.stage_name);
        if (defaultStages.length >= 1) {
          filters = [
            ...filters,
            { name: "Earliest_Lost_Stage", value: defaultStages, string: true },
          ];
        }

        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }

        filters =
          deaultOppType[0] === "All"
            ? filters
            : [...filters, { name: "Type", value: deaultOppType }];
        filters =
          defaultSolutionCat[0] === "All"
            ? filters
            : [...filters, { name: "Solution_Cat", value: defaultSolutionCat }];

        dispatch({ type: "current_selected_filters", filters });
        appService.getClosedLostOppAnalysisData(filters, key).then(
          (json) => {
            let counter = 0;
            let temp = json.message[1].map((i) => {
              return {
                ...i,
                OppNo: ++counter,
              };
            });
            json = {
              ...json,
              message: [
                json.message[0],
                temp,
                json.message[2],
                json.message[3],
              ],
            };
            dispatch({
              type: "get_closed_lost_opp_analysis_data_success",
              json,
            });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_closed_lost_opp_analysis_failure",
                error: "Something went wrong",
              });
            else
              dispatch({ type: "get_closed_lost_opp_analysis_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_closed_lost_opp_analysis_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_closed_lost_opp_analysis_filters_failure",
            error,
          });
      }
    );
  },
  getFunnelDataNew: (filters, key) => {
    dispatch({ type: "get_closed_lost_opp_analysis_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getClosedLostOppAnalysisData(filters, key).then(
      (json) => {
        let counter = 0;
        let temp = json.message[1].map((i) => {
          console.log(json.message);
          return {
            ...i,
            OppNo: ++counter,
          };
        });
        json = {
          ...json,
          message: [json.message[0], temp, json.message[2], json.message[3]],
        };
        dispatch({ type: "get_closed_lost_opp_analysis_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_closed_lost_opp_analysis_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_closed_lost_opp_analysis_failure", error });
      }
    );
  },
  getLostOppDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getClosedLostOppAnalysisDrilldown(filters).then(
      (json) => {
        console.log(json);
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getLostResonDrilldown: (filters, drilldownFilters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService
      .getClosedLostOppAnalysisLostResonDrilldown(filters, drilldownFilters)
      .then(
        (json) => {
          console.log(json.message);
          let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
            a.ACV > b.ACV ? -1 : 1
          );
          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json.message = {
            ...json,
            columns: json.message.columns,
            count_acv: [json.message.opportunities[0]],
            opportunities: tempOpportunities,
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const connectedFunnel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Funnel));
export { connectedFunnel as ClosedLost };
