import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import React from "react";

function ProjectedQuarterMonthYearFilter(props) {
  return (
    <Grid
      item
      spacing={2}
      xs={12}
      container
      component={Paper}
      style={{ paddingTop: 8 }}
    >
      {/* Proj. Close Fiscal Quarter Filter */}
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <FormControl
          fullWidth
          disabled={
            !props.Projected_Close_Year_Month ||
            !props.Projected_Close_Year_Month.includes("All")
          }
        >
          <InputLabel
            style={{
              color:
                props.Projected_Close_Year_Month &&
                props.Projected_Close_Year_Month.includes("All")
                  ? "#4472c4"
                  : "#000",
            }}
            id="select-projFiscalQuarter-label"
          >
            Proj. Close Fiscal Qtr.:
          </InputLabel>
          <Select
            value={props.projectedCloseFiscalQuarter}
            onChange={props.handleChange}
            name="projected_close_fiscal_quarter"
            data-test="projected-close-fiscal-quarter"
            multiple
          >
            <MenuItem value={"All"}>All</MenuItem>
            {props.funnelCompareFilters !== "" &&
              props.funnelCompareFilters.map((y) =>
                y.type === "projected_close_fiscal_quarter" ? (
                  <MenuItem
                    key={y.projected_close_fiscal_quarter}
                    value={y.projected_close_fiscal_quarter}
                  >
                    {y.projected_close_fiscal_quarter}
                  </MenuItem>
                ) : null
              )}
          </Select>
        </FormControl>
      </Grid>

      {/* Projected Close Year Month Filter */}
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <FormControl
          fullWidth
          disabled={
            !props.projectedCloseFiscalQuarter ||
            !props.projectedCloseFiscalQuarter.includes("All")
          }
        >
          <InputLabel
            style={{
              color:
                props.projectedCloseFiscalQuarter &&
                props.projectedCloseFiscalQuarter.includes("All")
                  ? "#4472c4"
                  : "#000",
            }}
            id="select-pcyMonth-label"
          >
            Proj. Close Month:
          </InputLabel>
          <Select
            value={props.Projected_Close_Year_Month}
            onChange={props.handleChange}
            name="Projected_Close_Year_Month"
            data-test="projected-close-year-month"
            multiple
          >
            <MenuItem value={"All"}>All</MenuItem>
            {props.funnelCompareFilters !== "" &&
              props.funnelCompareFilters
                .filter(
                  (y) =>
                    y.type === "Projected_Close_Year_Month" &&
                    y.Projected_Close_Year_Month !== "" &&
                    y.Projected_Close_Year_Month !== null
                )
                .map((y) => (
                  <MenuItem
                    key={y.Projected_Close_Year_Month}
                    value={y.Projected_Close_Year_Month}
                  >
                    {y.Projected_Close_Year_Month}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Earliest Open Stage Filter */}
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <FormControl fullWidth>
          <InputLabel
            id="select-stage-label"
            style={{ color: "#4472c4", width: 144 }}
          >
            Earliest Open Stage:
          </InputLabel>
          <Select
            labelId="select-stage-label"
            id="select-stage"
            value={props.earliestOpenStage}
            onChange={props.handleChangeEarliestOpenStage}
            name="earliestOpenStage"
            data-test="earliestOpenStage"
          >
            {props.funnelCompareFilters !== "" &&
              props.funnelCompareFilters.map(
                (y) =>
                  y.stage_name && (
                    <MenuItem key={y.sequence} value={y.sequence}>
                      {y.stage_name}
                    </MenuItem>
                  )
              )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default ProjectedQuarterMonthYearFilter;
