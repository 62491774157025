import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  withStyles,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import {
  getUniqueValues,
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  staticCustSegment,
} from "../../util/customFunctions";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ScoreCardDrilldownBody from "../../RepPerformance/ScoreCard/ScoreCardDrilldownBody";
import WaterfallDrilldownBody from "../Waterfall/WaterfallDrilldownBody";
import WinAcvDrillDownDrilldownBody from "../WinACVAnalysis/WinAcvDrillDown";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import { getMixKey } from "../../util/generateHeadings";
import { deSnake, getLineChartFormatedData } from "../../util/dataFormatter";
import YearQuarterFilterCombo from "../../Filters/YearQuarterFilterCombo";
import DynamicTableSegment from "../../components/DyanmicTableSegment";
import D3LineChart from "./D3LineChart";
import D3LineChart2 from "../WinACVMixAnalysis/D3LineChart";
import AverageDealSizeTable from "./Table";
import Legend from "./Legend";
import SegmentLegend from "../../RepPerformance/AsOfDate/Legend";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",

    justifyContent: "center",
    width: "95%",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "90%",
    margin: "auto",
    marginTop: "1rem",
  },
});

class WinACVMixGAAPAnalysis extends Component {
  state = {
    wonACVMix: ["All"],
    key: "",
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    closed_fiscal_quarter: ["All"],
    Acct_Vertical: ["All"],
    Apps_Cluster: ["All"],
    closed_fiscal_year: ["All"],
    disabled: ["year", "month", "quarter"],
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Solution_Cat: ["All"],
    Acct_Segment: ["All"],
    Cust_Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    activeTimeFilter: "closed_fiscal_quarter",
    filtersForDrilldownCYQ: ["All"],
    quarterOrYear: "closed_fiscal_quarter",
  };
  componentDidMount() {
    this.props.setTable("");
    this.props.getfunnelCompareFilters();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      this.setState({
        ...prevState,
        closed_fiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "closed_fiscal_quarter"
        ),
        filtersForDrilldownCYQ: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "closed_fiscal_quarter"
        ),
      });
    }

    if (this.props.winACVMixAnalysisData !== "") {
      let quarter = this.state.quarterOrYear;
      D3LineChart.destroy(this._rootNode2);
      D3LineChart2.destroy(this._rootNode1);
      const data = this.props.winACVMixAnalysisData.filter(
        (i) => i.type === "Booking_Type_NL_CS_US"
      )[0].data;
      let data2 = this.props.winACVMixAnalysisData
        .filter((i) => i.type === "Acct_Segment")[0]
        .data.map((item) => ({ ...item, deals: item.count }));
      console.log(data2);
      const uniqueSegments = data2
        .map((x) => x.Acct_Segment_Rollup)
        .filter(getUniqueValues);
      console.log(uniqueSegments);
      const uniqueQuarters = data
        .map((item) => item[quarter])
        .filter(getUniqueValues);
      const segmentTotal = uniqueQuarters.map((q) => ({
        acv:
          data2
            .filter((x) => x[this.state.quarterOrYear] === q)
            .map((y) => y.acv)
            .reduce((a, b) => a + b, 0) /
          data2
            .filter((x) => x[this.state.quarterOrYear] === q)
            .map((y) => y.deals)
            .reduce((a, b) => a + b, 0),
        deals: data2
          .filter((x) => x[this.state.quarterOrYear] === q)
          .map((y) => y.deals)
          .reduce((a, b) => a + b, 0),
        [this.state.quarterOrYear]: q,
        Acct_Segment_Rollup: "Total",
      }));

      data2 = uniqueQuarters.map((q) =>
        uniqueSegments.map((item) => ({
          acv:
            data2
              .filter(
                (x) =>
                  x.Acct_Segment_Rollup === item &&
                  x[this.state.quarterOrYear] === q
              )
              .map((y) => y.acv)
              .reduce((a, b) => a + b, 0) /
            data2
              .filter(
                (x) =>
                  x.Acct_Segment_Rollup === item &&
                  x[this.state.quarterOrYear] === q
              )
              .map((y) => y.deals)
              .reduce((a, b) => a + b, 0),
          deals: data2
            .filter(
              (x) =>
                x.Acct_Segment_Rollup === item &&
                x[this.state.quarterOrYear] === q
            )
            .map((y) => y.deals)
            .reduce((a, b) => a + b, 0),
          [this.state.quarterOrYear]: q,
          Acct_Segment_Rollup: item,
        }))
      );

      data2 = [...data2.flat(), ...segmentTotal];

      this._chart2 = D3LineChart.create(this._rootNode2, {
        data: data,
        type: "acv",
        uniqueQuarters:
          uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
        quarter,
      });
      this._chart1 = D3LineChart2.create(this._rootNode1, {
        data: data2.flat(),
        type: "acv",
        uniqueQuarters:
          uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
        quarter,
        avgDealSize: "avgDealSize",
      });
    }
  }

  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }

  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  onResize = async () => {
    D3LineChart.destroy(this._rootNode2);
    D3LineChart2.destroy(this._rootNode1);
    let quarter = this.state.quarterOrYear;

    const data = this.props.winACVMixAnalysisData.filter(
      (i) => i.type === "Booking_Type_NL_CS_US"
    )[0].data;
    let data2 = this.props.winACVMixAnalysisData
      .filter((i) => i.type === "Acct_Segment")[0]
      .data.map((item) => ({ ...item, deals: item.count }));
    console.log(data2);
    const uniqueSegments = data2
      .map((x) => x.Acct_Segment_Rollup)
      .filter(getUniqueValues);
    console.log(uniqueSegments);
    const uniqueQuarters = data
      .map((item) => item[quarter])
      .filter(getUniqueValues);
    const segmentTotal = uniqueQuarters.map((q) => ({
      acv:
        data2
          .filter((x) => x[this.state.quarterOrYear] === q)
          .map((y) => y.acv)
          .reduce((a, b) => a + b, 0) /
        data2
          .filter((x) => x[this.state.quarterOrYear] === q)
          .map((y) => y.deals)
          .reduce((a, b) => a + b, 0),
      deals: data2
        .filter((x) => x[this.state.quarterOrYear] === q)
        .map((y) => y.deals)
        .reduce((a, b) => a + b, 0),
      [this.state.quarterOrYear]: q,
      Acct_Segment_Rollup: "Total",
    }));
    data2 = uniqueQuarters.map((q) =>
      uniqueSegments.map((item) => ({
        acv:
          data2
            .filter(
              (x) =>
                x.Acct_Segment_Rollup === item &&
                x[this.state.quarterOrYear] === q
            )
            .map((y) => y.acv)
            .reduce((a, b) => a + b, 0) /
          data2
            .filter(
              (x) =>
                x.Acct_Segment_Rollup === item &&
                x[this.state.quarterOrYear] === q
            )
            .map((y) => y.deals)
            .reduce((a, b) => a + b, 0),
        deals: data2
          .filter(
            (x) =>
              x.Acct_Segment_Rollup === item &&
              x[this.state.quarterOrYear] === q
          )
          .map((y) => y.deals)
          .reduce((a, b) => a + b, 0),
        [this.state.quarterOrYear]: q,
        Acct_Segment_Rollup: item,
      }))
    );

    data2 = [...data2.flat(), ...segmentTotal];

    this._chart2 = D3LineChart.create(this._rootNode2, {
      data: data,
      type: "acv",
      uniqueQuarters:
        uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
      quarter,
    });
    this._chart1 = D3LineChart2.create(this._rootNode1, {
      data: data2.flat(),
      type: "acv",
      uniqueQuarters:
        uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
      quarter,
      avgDealSize: "avgDealSize",
    });
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          Apps_Cluster: "Apps_Cluster",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
        };

  staticCustSegment =
    this.props.company === "C0002"
      ? [
          { value: "<=$100M", displayValue: "<=$100M" },
          { value: "$100M-$1B", displayValue: "$100M-$1B" },
          { value: ">$1B", displayValue: ">$1B" },
        ]
      : [
          { value: "<=$25M", displayValue: "<=$25M" },
          { value: "$25-50M", displayValue: "$25M-50M" },
          { value: "$50-250M", displayValue: "$50M-250M" },
          { value: "$250-500M", displayValue: "$250M-500M" },
          { value: "$500M-2B", displayValue: "$500M-2B" },
          { value: ">$2B", displayValue: ">$2B" },
        ];

  orderRepsByLastName = () => {
    let temp = this.props.funnelCompareFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleChangeLocation = (location, data, type, quarter) => {
    const quarterFilter = this.props.currentFilters.filter(
      (item) => item.name === quarter
    );
    if (location !== "overview") {
      this.props.setTable("WinAcv");
      let filters;
      if (type === "Acct_Segment_Rollup") {
        filters = [
          {
            name: quarter,
            value:
              data.cell !== "Total"
                ? [data.cell]
                : quarterFilter.length > 0
                ? quarterFilter[0].value
                : this.props.funnelCompareFilters !== "" &&
                  this.props.funnelCompareFilters
                    .filter((y) => y.type === quarter && y[quarter] !== "")
                    .map((y) => y[quarter])
                    .filter((item) => item !== null),
            string: true,
          },
          { name: type, value: [data.row], string: true },
        ];
      } else if (type === "Booking_Type_NL_CS_US") {
        const uniqueStages = this.props.winACVMixAnalysisData
          .filter((item) => item.type === type)[0]
          .data.map((item) => item[type])
          .filter(getUniqueValues)
          .filter((s) => s !== "Total");
        filters = [
          {
            name: quarter,
            value:
              data.cell !== "Total"
                ? [data.cell]
                : quarterFilter.length > 0
                ? quarterFilter[0].value
                : this.props.funnelCompareFilters !== "" &&
                  this.props.funnelCompareFilters
                    .filter((y) => y.type === quarter && y[quarter] !== "")
                    .map((y) => y[quarter])
                    .filter((item) => item !== null),
            string: true,
          },
          {
            name: "ACV_Column",
            value: data.row !== "Total" ? [data.row] : uniqueStages,
          },
        ];
      } else {
        const uniqueStages = this.props.winACVMixAnalysisData
          .filter((item) => item.type === type)[0]
          .data.map((item) => item[type])
          .filter(getUniqueValues)
          .filter((s) => s !== "Total");
        filters = [
          {
            name: quarter,
            value:
              data.cell !== "Total"
                ? [data.cell]
                : quarterFilter.length > 0
                ? quarterFilter[0].value
                : this.props.funnelCompareFilters !== "" &&
                  this.props.funnelCompareFilters
                    .filter((y) => y.type === quarter && y[quarter] !== "")
                    .map((y) => y[quarter])
                    .filter((item) => item !== null),
            string: true,
          },
          {
            name: type,
            value:
              data.row !== "Total"
                ? data.row === "Others" && type === "Acct_Industry"
                  ? this.props.winACVMixAnalysisData.filter(
                      (item) => item.type === "Acct_Industry"
                    )[0].otherIndustries
                  : [data.row]
                : uniqueStages.includes("Others") && type === "Acct_Industry"
                ? [
                    ...uniqueStages,
                    ...this.props.winACVMixAnalysisData.filter(
                      (item) => item.type === "Acct_Industry"
                    )[0].otherIndustries,
                  ]
                : uniqueStages,
            string: true,
          },
        ];
      }

      let localFilters = this.props.currentFilters;
      console.log(filters);

      filters = [...filters, ...localFilters];
      console.log(filters);
      if (type === "Apps_Cluster") {
        this.props.getPbiSummaryDrilldown(
          filters.filter((i) => i.name !== "Apps_Cluster"),
          {
            appsCluster: data.row !== "Total" ? data.row : "All",
            oppStatus: "won",
            type: "appsCluster",
          }
        );
      } else if (type === "Product_Line") {
        this.props.getPbiSummaryDrilldown(
          filters.filter((i) => i.name !== "Product_Line"),
          {
            appsCluster: data.row !== "Total" ? data.row : "All",
            oppStatus: "won",
            type: "productLine",
          }
        );
      } else {
        this.props.getWinACVDrilldown(filters);
      }

      var filtersForDrilldown = [
        {
          title: type === "Acct_Segment_Rollup" ? "Segment" : getMixKey(type),
          value: data.row !== "Total" ? data.row : "All",
        },
        {
          title: this.labelMap[quarter]
            ? this.labelMap[quarter]
            : deSnake(quarter),
          value:
            data.cell !== "Total"
              ? data.cell
              : this.state.filtersForDrilldownCYQ,
        },
      ];
      this.setState({
        ...this.state,
        location: location,
        filtersForDrilldown,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChange = (e) => {
    if (
      e.target.name === "closed_fiscal_year" &&
      (e.target.value.length === 0 || e.target.value.includes("All"))
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_year") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_quarter") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_quarter",
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };
  handleMixType = (e, value) => {
    this.setState({
      ...this.state,
      wonACVMix: [e.target.value],
    });
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
    this.props.setTable("");
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.props.lkpFilters
      .filter((f) => f !== "ACV_Range")
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    this.setState({
      ...this.state,
      filtersForDrilldownCYQ: this.passFiltersForDrilldown(),
      quarterOrYear: this.state.activeTimeFilter,
    });
    this.props.getWinACVMixData(
      this.state.activeTimeFilter === "closed_fiscal_year"
        ? "closed_fiscal_year"
        : "closed_fiscal_quarter",
      filters
    );
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [...filtersArray, this.state.closed_fiscal_quarter];
    }

    if (!this.state.closed_fiscal_year.includes("All")) {
      filtersArray = [...filtersArray, this.state.closed_fiscal_year];
    }
    if (
      this.state.closed_fiscal_year.includes("All") &&
      this.state.closed_fiscal_quarter.includes("All")
    ) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      location: location,
    });
  };
  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
    });
    this.props.clearExpectedAcvData();
  };

  labelMap = {
    Projected_Close_Year_Month: "Projected Close Month",
    Closed_Year_Month: "Closed Month",
    projected_close_fiscal_quarter: "Projected Close Fiscal Qtr.",
    closed_fiscal_quarter: "Closed Fiscal Qtr.",
  };

  render() {
    const { classes } = this.props;
    console.log("this.props.Value_Label", this.props.Value_Label);
    return (
      <Grid container className={classes.gridContainer}>
        {/* <Grid
          className={classes.grid}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Won ACV GAAP Mix Analysis
          </Typography>
          <Typography variant="body1">
            Analyze won ACV across multiple dimensions to spot trends and
            patterns.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          `Won ${this.props.Value_Label} GAAP Mix Analysis`,
          `Analyze won ${this.props.Value_Label} across multiple dimensions to spot trends and patterns.`
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <Grid
            className={classes.grid}
            item
            container
            justify="flex-start"
            xs={12}
          >
            <Grid container item style={{ padding: 10 }} xs={4}>
              <YearQuarterFilterCombo
                activeTimeFilter={this.state.activeTimeFilter}
                closedFiscalQuarterState={this.state.closed_fiscal_quarter}
                closedFiscalYear={this.state.closed_fiscal_year}
                handleChange={this.handleChange}
                funnelCompareFilters={this.props.funnelCompareFilters}
              />
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    data-test="rep"
                    id="combo-box-demo"
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      data-test="team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Acct_Vertical
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Solution Category:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Acct_Vertical]}
                      onChange={this.handleChange}
                      name={this.variableCols.Acct_Vertical}
                      data-test={this.variableCols.Acct_Vertical}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Acct_Vertical &&
                              y[this.variableCols.Acct_Vertical] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Acct_Vertical] <
                            b[this.variableCols.Acct_Vertical]
                              ? -1
                              : a[this.variableCols.Acct_Vertical] >
                                b[this.variableCols.Acct_Vertical]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Acct_Vertical]}
                              value={y[this.variableCols.Acct_Vertical]}
                            >
                              {y[this.variableCols.Acct_Vertical]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Opportunity Type:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Type"
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003" ? "Region:" : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.company === "C0003"
                        ? "Segment:"
                        : "Customer Segment:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerSegment] <
                            b[this.variableCols.CustomerSegment]
                              ? -1
                              : a[this.variableCols.CustomerSegment] >
                                b[this.variableCols.CustomerSegment]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/*
                            this.props.company === "C0003" && <Grid container item style={{ padding: 10,paddingTop:15 }} xs={2}>
                            <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>

                                 <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <InputLabel className={classes.filterLabel} style={{ color: !this.state[this.variableCols.CustomerSegment].includes('All') ? '#4472c4' : '#000' }} id="select-region-label">Apps Cluster:</InputLabel>
                                        <Select
                                            data-test='Apps_Cluster'
                                            value={this.state[this.variableCols.Apps_Cluster]}
                                            onChange={this.handleChange}
                                            name={this.variableCols.Apps_Cluster}
                                            multiple
                                        >
                                             <MenuItem value={'All'} >All</MenuItem>
                                            {this.props.funnelCompareFilters !== '' && this.props.funnelCompareFilters
                                                .filter(y => y.type === this.variableCols.Apps_Cluster && y[this.variableCols.Apps_Cluster] !== '')
                                                .sort((a, b) => a[this.variableCols.Apps_Cluster] < b[this.variableCols.Apps_Cluster] ? -1 : a[this.variableCols.Apps_Cluster] > b[this.variableCols.Apps_Cluster] ? 1 : 0)
                                                .map(y => <MenuItem key={y[this.variableCols.Apps_Cluster]} value={y[this.variableCols.Apps_Cluster]}>{y[this.variableCols.Apps_Cluster]}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                                        */}
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        <Grid className={classes.grid} item xs={12}>
          <Grid>
            {this.props.winACVMixAnalysisData !== "" ? (
              this.props.winACVMixAnalysisData.filter(
                (item) => item.type === "closed_fiscal_year"
              )[0] !== undefined ? (
                <Grid container>
                  {this.props.winACVMixAnalysisData
                    .filter((item) => item.type !== "closed_fiscal_year")
                    .map(({ type, data, label }, i) => {
                      return (
                        <Grid key={i} className={classes.grid} item xs={12}>
                          <DataVisualizer
                            chartName={`WinAcvAnalysis by ${type}`}
                            title={`Won ${this.props.Value_Label} mix by ${label}`}
                            type={type}
                            chartType="acv"
                            Value_Label={this.props.Value_Label}
                            winACVMixAnalysisData={
                              [
                                "Apps_Cluster",
                                "Booking_Type_NL_CS_US",
                                "Product_Line",
                              ].includes(type)
                                ? data.filter((i) => i[type] !== "Total")
                                : data
                            }
                            handleChangeLocation={this.handleChangeLocation}
                            quarter={"closed_fiscal_year"}
                            tableComponent={
                              type === "Acct_Segment" ? (
                                <DynamicTableSegment
                                  Value_Label={this.props.Value_Label}
                                  type={type}
                                  quarter={"closed_fiscal_year"}
                                  winAcvData={data}
                                  handleChangeLocation={
                                    this.handleChangeLocation
                                  }
                                />
                              ) : undefined
                            }
                            unit="acv"
                            company={this.props.company}
                            clusterTotal={
                              [
                                "Apps_Cluster",
                                "Booking_Type_NL_CS_US",
                                "Product_Line",
                              ].includes(type)
                                ? data.filter((i) => i[type] === "Total")
                                : []
                            }
                            sortByTotal={type !== "Acct_Segment" ? true : false}
                            label={label}
                            disablecolumnTotal={
                              ["Apps_Cluster", "Product_Line"].includes(type)
                                ? true
                                : false
                            }
                          />
                          {type === "Booking_Type_NL_CS_US" && (
                            <Grid
                              item
                              xs={12}
                              style={{ marginTop: "2rem" }}
                              component={Card}
                              raised
                            >
                              <Grid
                                container
                                item
                                style={{ padding: "1rem" }}
                                xs={12}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    align="center"
                                    variant="h2"
                                    style={{ margin: "1rem" }}
                                  >{`Won ${this.props.Value_Label} mix by ${label} - Avg. Deal Size`}</Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    justifyContent: "center",
                                    marginTop: 20,
                                    margin: "auto",
                                    width: "95%",
                                  }}
                                  id="svgContainer"
                                  className="svg-container"
                                  ref={this._setRef2.bind(this)}
                                />
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    margin: "2rem",
                                    marginTop: "0rem",
                                    paddingRight: "5rem",
                                    marginBottom: "2rem",
                                  }}
                                  justify="flex-start"
                                  container
                                >
                                  <Legend
                                    square={15}
                                    data={data}
                                    type="Booking_Type_NL_CS_US"
                                  />{" "}
                                  <AverageDealSizeTable
                                    Value_Label={this.props.Value_Label}
                                    clusterTotal={
                                      ["Booking_Type_NL_CS_US"].includes(type)
                                        ? data.filter(
                                            (i) => i[type] === "Total"
                                          )
                                        : []
                                    }
                                    winAcvData={
                                      ["Booking_Type_NL_CS_US"].includes(type)
                                        ? data.filter(
                                            (i) => i[type] !== "Total"
                                          )
                                        : data
                                    }
                                    quarter={"closed_fiscal_year"}
                                    type={type}
                                    handleChangeLocation={
                                      this.handleChangeLocation
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          {type === "Acct_Segment" && (
                            <Grid
                              item
                              xs={12}
                              style={{ marginTop: "2rem" }}
                              component={Card}
                              raised
                            >
                              <Grid
                                container
                                item
                                style={{ padding: "1rem" }}
                                xs={12}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    align="center"
                                    variant="h2"
                                    style={{ margin: "1rem" }}
                                  >{`Won ${this.props.Value_Label} mix by ${label} - Avg. Deal Size`}</Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    justifyContent: "center",
                                    marginTop: 20,
                                    margin: "auto",
                                    width: "95%",
                                  }}
                                  id="svgContainer"
                                  className="svg-container"
                                  ref={this._setRef1.bind(this)}
                                />
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    margin: "2rem",
                                    marginTop: "0rem",
                                    paddingRight: "5rem",
                                    marginBottom: "2rem",
                                  }}
                                  justify="flex-start"
                                  container
                                >
                                  <Legend
                                    square={15}
                                    data={data}
                                    type="Acct_Segment_Rollup"
                                  />
                                  <DynamicTableSegment
                                    Value_Label={this.props.Value_Label}
                                    type={type}
                                    quarter={"closed_fiscal_year"}
                                    winAcvData={data}
                                    avgDealSize={"Avg. Deal Size"}
                                    handleChangeLocation={
                                      this.handleChangeLocation
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
              ) : (
                <Grid container>
                  {this.props.winACVMixAnalysisData.map(
                    ({ type, data, label }, i) => {
                      return (
                        <Grid key={i} className={classes.grid} item xs={12}>
                          <DataVisualizer
                            chartName={`WinAcvAnalysis by ${type}`}
                            title={`Won ${this.props.Value_Label} mix by ${label}`}
                            type={type}
                            chartType="acv"
                            Value_Label={this.props.Value_Label}
                            winACVMixAnalysisData={
                              [
                                "Apps_Cluster",
                                "Booking_Type_NL_CS_US",
                                "Product_Line",
                              ].includes(type)
                                ? data.filter((i) => i[type] !== "Total")
                                : data
                            }
                            handleChangeLocation={this.handleChangeLocation}
                            quarter={"closed_fiscal_quarter"}
                            tableComponent={
                              type === "Acct_Segment" ? (
                                <DynamicTableSegment
                                  Value_Label={this.props.Value_Label}
                                  type={type}
                                  quarter={"closed_fiscal_quarter"}
                                  winAcvData={data}
                                  handleChangeLocation={
                                    this.handleChangeLocation
                                  }
                                />
                              ) : undefined
                            }
                            unit="acv"
                            company={this.props.company}
                            clusterTotal={
                              [
                                "Apps_Cluster",
                                "Booking_Type_NL_CS_US",
                                "Product_Line",
                              ].includes(type)
                                ? data.filter((i) => i[type] === "Total")
                                : []
                            }
                            sortByTotal={type !== "Acct_Segment" ? true : false}
                            label={label}
                            disablecolumnTotal={
                              ["Apps_Cluster", "Product_Line"].includes(type)
                                ? true
                                : false
                            }
                          />
                          {type === "Booking_Type_NL_CS_US" && (
                            <Grid
                              item
                              xs={12}
                              style={{ marginTop: "2rem" }}
                              component={Card}
                              raised
                            >
                              <Grid
                                container
                                item
                                style={{ padding: "1rem" }}
                                xs={12}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    align="center"
                                    variant="h2"
                                    style={{ margin: "1rem" }}
                                  >{`Won ${this.props.Value_Label} mix by ${label} - Avg. Deal Size`}</Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    justifyContent: "center",
                                    marginTop: 20,
                                    margin: "auto",
                                    width: "95%",
                                  }}
                                  id="svgContainer"
                                  className="svg-container"
                                  ref={this._setRef2.bind(this)}
                                />
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    margin: "2rem",
                                    marginTop: "0rem",
                                    paddingRight: "5rem",
                                    marginBottom: "2rem",
                                  }}
                                  justify="flex-start"
                                  container
                                >
                                  <Legend
                                    square={15}
                                    data={data}
                                    type="Booking_Type_NL_CS_US"
                                  />
                                  <AverageDealSizeTable
                                    Value_Label={this.props.Value_Label}
                                    clusterTotal={
                                      ["Booking_Type_NL_CS_US"].includes(type)
                                        ? data.filter(
                                            (i) => i[type] === "Total"
                                          )
                                        : []
                                    }
                                    winAcvData={
                                      ["Booking_Type_NL_CS_US"].includes(type)
                                        ? data.filter(
                                            (i) => i[type] !== "Total"
                                          )
                                        : data
                                    }
                                    quarter={"closed_fiscal_quarter"}
                                    type={type}
                                    handleChangeLocation={
                                      this.handleChangeLocation
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}

                          {type === "Acct_Segment" && (
                            <Grid
                              item
                              xs={12}
                              style={{ marginTop: "2rem" }}
                              component={Card}
                              raised
                            >
                              <Grid
                                container
                                item
                                style={{ padding: "1rem" }}
                                xs={12}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    align="center"
                                    variant="h2"
                                    style={{ margin: "1rem" }}
                                  >{`Won ${this.props.Value_Label} mix by ${label} - Avg. Deal Size`}</Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    justifyContent: "center",
                                    marginTop: 20,
                                    margin: "auto",
                                    width: "95%",
                                  }}
                                  id="svgContainer"
                                  className="svg-container"
                                  ref={this._setRef1.bind(this)}
                                />
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    margin: "2rem",
                                    marginTop: "0rem",
                                    paddingRight: "5rem",
                                    marginBottom: "2rem",
                                  }}
                                  justify="flex-start"
                                  container
                                >
                                  <Legend
                                    square={15}
                                    data={data}
                                    type="Acct_Segment_Rollup"
                                  />
                                  <DynamicTableSegment
                                    Value_Label={this.props.Value_Label}
                                    type={type}
                                    quarter={"closed_fiscal_quarter"}
                                    winAcvData={data}
                                    avgDealSize={"Avg. Deal Size"}
                                    handleChangeLocation={
                                      this.handleChangeLocation
                                    }
                                  />
                                  {/* <AverageDealSizeTable                                                      
                                                        clusterTotal={['Booking_Type_NL_CS_US'].includes(type) ? data.filter(i=>i[type] ==='Total') : []} 
                                                        winAcvData={['Booking_Type_NL_CS_US'].includes(type) ? data.filter(i=>i[type] !=='Total') : data}
                                                        quarter={"closed_fiscal_quarter"}
                                                        type={type}
                                                        handleChangeLocation={this.handleChangeLocation}
                                                        /> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              )
            ) : (
              <Card>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={230}
                  style={{ margin: "2rem" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Won Opportunities"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <WinAcvDrillDownDrilldownBody
                header={"Won Opportunities"}
                chart={"GAAP"}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    winAcvMixFilters,
    winAcvAnalysisData,
    user,
    drilldownOnGraphData,
    currentFilters,
    lkpFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelCompareFilters: winAcvMixFilters,
    winACVMixAnalysisData: winAcvAnalysisData,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    currentFilters,
    lkpFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },

  getfunnelCompareFilters: () => {
    dispatch({ type: "get_win_acv_filters_request" });
    appService.getWinACVMixGaapAnalysisFilters().then(
      (json) => {
        var lkpFilters = Object.values(
          json.message.lkpFilters.reduce((r, c) => Object.assign(r, c), {})
        );
        json.message = json.message.computedFilters;
        json.lkpFilters = lkpFilters;
        dispatch({ type: "get_win_acv_mix_filters_success", json });
        dispatch({ type: "get_win_acv_mix_analysis_data_request" });
        const filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getWinACVMixGaapAnalysisData(
            "closed_fiscal_quarter",
            json.message.length === 0 ? [] : filters
          )
          .then(
            (json) => {
              dispatch({ type: "get_win_acv_mix_analysis_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_win_acv_mix_analysis_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_win_acv_mix_analysis_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_win_acv_mix_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_win_acv_mix_filters_failure", error });
      }
    );
  },
  getWinACVDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getWinAcvMixGaapAnalysisdrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            // let createdDateString = (item.Created_Date.split('T')[0])
            // var [createdYear, createdMonth, createdDate] = createdDateString.split("-")
            let x = {
              ...item,
              // Created_Date: item.Created_Date.split('T')[0],
              // closed_date: item.closed_date !== undefined && item.closed_date !== null ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
              // cycle_time_closed_orders: _isNaN(parseInt(item.cycle_time_closed_orders)) ? "" : parseInt(item.cycle_time_closed_orders),
              OppNo: index + 1,
              ACV: item.ACV ?? item.acv,
              // Age: _isNaN(Math.ceil(
              //     (new Date(Date.now()).setHours(0) - (new Date(new Date(parseInt(createdYear), parseInt(createdMonth) - 1, parseInt(createdDate.substr(0, 2))).setHours(0)).setMinutes(0))) /
              //     (1000 * 60 * 60 * 24)
              // )) ? 0 : Math.ceil(
              //     (new Date(Date.now()).setHours(0) - (new Date(new Date(parseInt(createdYear), parseInt(createdMonth) - 1, parseInt(createdDate.substr(0, 2))).setHours(0)).setMinutes(0))) /
              //     (1000 * 60 * 60 * 24)
              // ),
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          // let createdDateString = (item.Created_Date.split('T')[0])
          // var [createdYear, createdMonth, createdDate] = createdDateString.split("-")
          let temp = {
            ...item,
            OppNo: index + 1,
            // age: Math.round(
            //     (new Date(Date.now()).setHours(0) - (new Date(new Date(parseInt(createdYear), parseInt(createdMonth) - 1, parseInt(createdDate.substr(0, 2))).setHours(0)).setMinutes(0))) /
            //     (1000 * 60 * 60 * 24)
            // ),
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getPbiSummaryDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    console.log(meta);
    appService.getWinACVGAAPAppsClusterDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.result.recordsets[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.result.recordsets[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getWinACVMixData: (key, filters = []) => {
    dispatch({ type: "get_win_acv_mix_analysis_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getWinACVMixGaapAnalysisData(key, filters).then(
      (json) => {
        dispatch({ type: "get_win_acv_mix_analysis_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_win_acv_mix_analysis_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_win_acv_mix_analysis_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedWinACVMixGaapAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WinACVMixGAAPAnalysis));
export default connectedWinACVMixGaapAnalysis;
