import React, { Fragment, Suspense } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Divider,
  Typography,
  Grid,
  Paper,
  AppBar,
  Box,
  Tabs,
  Tab,
  Button,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Tooltip as Tp,
} from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../App/app.service";
import { AgGridReact } from "ag-grid-react";
import "./Dashboard.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import D3OpenOpp from "./OpenOpportunities/D3OpenOppurtunities";
import { version } from "../../package.json";
import Alert from "@material-ui/lab/Alert";
import ExpectedAcvDrilldown from "../RepPerformance/ScoreCard/ExpectedAcv/ExpectedAcvDrilldown";
import ExpectedAcvPage from "../RepPerformance/ScoreCard/ExpectedAcv/ExpectedAcvPage";
import ExpectedAcvPageV2 from "../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import SingleLevelDrilldown from "../components/Drilldown/SingleLevelDrilldown";
import {
  countOrACVFormatter,
  countOrACVFormatterMillion,
  countOrACVFormatterThousand,
  countOrACVFormatterThousandNew,
} from "../util/customFunctions";
import Loading from "../App/Loading";
import { TableContainer } from "@mui/material";
import DrilldownSLD from "../RepPerformance/GenericScorecard/DrilldownSLD";
import DrilldownSLDv2 from "../RepPerformance/GenericScorecard/DrilddowSLDv2";
import Drilldown from "../components/Drilldown/DrilldownWithSLD";
import { replaceAll } from "../components/xlsx-export/LabelCange";
import DrilldownSLDNewv2 from "../RepPerformance/GenericScorecard/DrilldownSLDNewv2";
const OpenOpp = React.lazy(() => import("./OpenOpp"));
const C0003_OpenOpp = React.lazy(() => import("./C0003_OpenOpp"));
const C0002_OpenOpp = React.lazy(() => import("./C0002_OpenOpp"));
const C0004_OpenOpp = React.lazy(() => import("./C0004_OpenOpp"));

// const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
//   "&.Mui-selected, &.Mui-selected:hover": {
//     color: "white",
//     backgroundColor: selectedColor
//   }
// }));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
    maxWidth: 1500,
    [theme.breakpoints.up("sm")]: {
      //height: "100%",
    },
  },
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      // height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },

  tableHeader: {
    fontSize: "0.85rem",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    fontSize: "0.85rem",
    whiteSpace: "normal",
  },
  bookingCardContainer: {
    textAlign: "center",
    //border: '1px solid rgb(13, 98, 148)',
    width: "22%",
    position: "relative",
    paddingTop: `${20 * 0.5625}%`,
    border: "2px solid rgb(13, 98, 148)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingTop: `${100 * 0.5625}%`,
      marginBottom: 20,
    },
    background: "#4472C4",
    height: "180px",
  },
  bookingTableCardContainer: {
    textAlign: "center",
    //border: '1px solid rgb(13, 98, 148)',
    // width: "22%",
    // position: "relative",
    // paddingTop: `${20 * 0.5625}%`,
    border: "2px solid rgb(13, 98, 148)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      // paddingTop: `${100 * 0.5625}%`,
      // marginBottom: 20,
    },
    // background: "#4472C4",
    // height: "180px"
  },
  bookingTableCardContainerv2: {
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "#fafafa",
    //border: '1px solid rgb(13, 98, 148)',
    // width: "22%",
    // position: "relative",
    // paddingTop: `${20 * 0.5625}%`,
    // border: "2px solid rgb(13, 98, 148)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      // paddingTop: `${100 * 0.5625}%`,
      // marginBottom: 20,
    },
    // background: "#4472C4",
    // height: "180px"
  },
  bookingCardHeader: {
    height: "20%",
    background: "#4472C4",
    //border: '2px solid rgb(13, 98, 148)',
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  bookingCardBody: {
    height: "80%",
    background: "white",
    position: "absolute",
    // border: '2px solid rgb(13, 98, 148)',
    top: "20%",
    left: 0,
    bottom: 0,
    right: 0,
  },
  acvTotal: {
    fontWeight: 500,
    marginRight: 100,
    [theme.breakpoints.down("sm")]: {
      marginRight: 10,
    },
  },
  grid: {
    //height: "100%",
  },
  right: {
    marginLeft: "auto",
  },
  repHeader: {
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "inherit",
    padding: "5px",
  },
  datacell: {
    fontSize: "1rem",
    color: "inherit",
    padding: "5px",
    border: "none",
  },
  datacell2: {
    fontSize: "0.85rem",
    color: "inherit",
    padding: "5px",
    border: "none",
  },
});

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
var colorIndex = 0;
var colors = ["#fffff", "#4472c4"];
function progressColor() {
  colorIndex++;
  if (colorIndex === colors.length) {
    colorIndex = 0;
  }
}

const currencyFormatter = (params) => {
  if (_isNaN(params.value)) return 0;
  return `${params.colDef.Symbol} ${d3.format(",")(Math.round(params.value))}`;
};

const dateAndTimeFormatter = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(params.value));
};
const dateFormatter = (params) => {
  if ([null, undefined, ""].includes(params.value)) {
    return "";
  } else {
    return new Intl.DateTimeFormat("en-US", {
      timeZone: "UTC",
      month: "numeric",
      day: "numeric",
      year: "numeric",
    }).format(new Date(params.value));
  }
};

const percentageDFormatter = (params) => {
  if (!["", null, undefined].includes(params.value)) {
    return `${Math.round(params.value * 100)}%`;
  } else {
    return "";
  }
};

const newOppDisplayValue = {
  new: "New",
  new_resurrected: "Resurrected",
  new_resurrected_won_to_lost: "Resurrected Won to Lost",
  new_resurrected_won_to_won: "Resurrected Won to Won",
  new_resurrected_lost_to_lost: "Resurrected Lost to Lost",
  new_resurrected_lost_to_won: "Resurrected Lost to Won",
};
const newOwpFormatter = (params) => {
  return newOppDisplayValue[params.value];
};

class Dashboard extends React.Component {
  colorIndex = 0;
  state = {
    value: 0,
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
      autoHeight: true,
      location: "",
    },
    //rowStyle: { fontSize: 5 },
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    containerHeight:
      window.innerHeight > 800 || window.innerWidth < 400 ? "--" : "100%",
    gridHeight:
      window.innerHeight > 800 || window.innerWidth < 400
        ? "autoHeight"
        : "normal",
    colResizeDefault: "shift",
    lastRowData: "",
    sumOfAcv: "",
    getRowStyle: () =>
      this.colorIndex === 1
        ? { color: "white", backgroundColor: "#4472c4" }
        : { color: "black", backgroundColor: "#fffff" },
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
    secondLevelLocation: "",
    secondLevelLocationV2: "",
    toggleValue: "ACV",
  };

  filters = ["year", "month", "quarter", "cfq"];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  componentDidMount = () => {
    this.newMount = 1;
    if (this.props.homepageData === "") {
      this.props.getHomepageData();
      this.props.getAttainmentData([], "YTD");
      this.props.getAttainmentData([], "CFQ");
      this.props.getPipelineCoverageData([], "CFQ");
      this.props.getEnsembleData()
    }

    if (this.props.homepageData.length !== 0) {
    }
  };
  componentWillUnmount = () => {};
  redrawTopRows = () => {
    progressColor();
    var rows = [];
    for (var i = 0; i < 6; i++) {
      var row = this.gridApi.getDisplayedRowAtIndex(i);
      rows.push(row);
    }
    this.gridApi.redrawRows({ rowNodes: rows });
  };

  onCellClicked = (e) =>
    this.handleSecondLevelDrilldown("drilldownOppId", e.data.Opp_ID);

  componentDidUpdate = (prevProps, prevState) => {
    // this.props.getOpenOppData(this.props.scatterpoint.opp)
    if (this.gridApi !== undefined) {
      if (typeof this.props.scatterpoint === "object") {
        var highLightedObjectIndex;
        this.gridApi.forEachNode((item, i) => {
          if (item.data.OppName === this.props.scatterpoint.opp_name)
            highLightedObjectIndex = item.childIndex;
        });
        this.gridApi.ensureIndexVisible(highLightedObjectIndex);
        this.colorIndex = 1;
        // console.log("scatter point",this.state.scatterPoint,this.props.scatterpoint)

        // console.log(highLightedObjectIndex)
        var rows = [];
        var row = this.gridApi.getDisplayedRowAtIndex(highLightedObjectIndex);
        rows.push(row);
        // console.log(rows)
        this.gridApi.redrawRows({ rowNodes: rows });
      }
      if (typeof this.props.descatterpoint === "object") {
        // var highLightedObjectIndex = this.props.projectedPipelineData.recordset.findIndex(item=>item.OppName === this.props.descatterpoint.opp_name)
        // this.gridApi.ensureIndexVisible(highLightedObjectIndex)
        // this.progressColor()

        // var rows = []
        // var row  = this.gridApi.getDisplayedRowAtIndex(highLightedObjectIndex)
        // rows.push(row)
        this.colorIndex = 0;
        this.gridApi.redrawRows();
      }
      if (
        typeof this.props.deselected_scatter === "" &&
        this.props.selected_scatter === ""
      ) {
        this.colorIndex = 0;
        this.gridApi.redrawRows();
      }
    }
    if (this.props.homepageData !== "" && prevProps.homepageData === "") {
      let sumOfAcv = 0;
      // console.log(this.props.data.filter((i) => i.length))

      const lastRowData =
        this.props.data !== ""
          ? this.props.data
              .filter((i) => i.length)[0]
              .map((item) => {
                sumOfAcv += item.ACV;
              })
          : "";

      this.setState({
        ...this.state,
        lastRowData: lastRowData,
        sumOfAcv: sumOfAcv,
        toggleValue: "ACV",
      });
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    this.setState({
      ...this.state,
      location: location,
    });
    this.props.getOpenOppData(oppId);
  };

  handleSecondLevelDrilldownV2 = (location, oppId) => {
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getExpectedAcvData(oppId);
  };

  handleSecondLevelDrilldownV3 = (location, oppId) => {
    // console.log(oppId)
    this.setState({
      ...this.state,
      secondLevelLocationV2: location,
    });
    this.props.getOpenOppDataAttainment(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
      secondLevelLocationV2: "",
    });
    this.props.clearExpectedAcvData();
  };

  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: "",
      secondLevelLocationV2: "",
      drilldownTable: "",
    });
    this.props.setTable("");
  };

  handleChangeLocation = () => {
    var filters = [];
    if (this.state.toggleValue === "ACV-Threshold") {
      filters = [...filters, { name: "threshold", value: true }];
    }

    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
    });

    let tableType = undefined
    if(this.props.EnsembleData.expectedHighMidLow){
      this.props.getTimeSeriesDrilldown([]);
    }else{
      this.props.getPipelineCoverageDrilldown(filters, "open", "CFQ", undefined, tableType);
    }

   
  };

  handleChangeLocationAttainmnet = (timeFrame) => {
    var filters = [];

    this.setState({
      ...this.state,
      location: "drilldownOnGraphAttainment",
    });

    this.props.getAttainmentSectionDrilldown(filters, timeFrame);
  };

  handleChangeLocationPipeline = (timeFrame, stages) => {
    let filters = [
      {
        name: "projected_close_fiscal_quarter",
        value: typeof timeFrame === "string" ? [timeFrame] : timeFrame,
        string: true,
      },
      { name: "acv", value: 1, string: false },
      { name: "stage", value: stages },
    ];
    this.setState({
      ...this.state,
      location: "drilldownOnGraphPipeline",
    });

    this.props.getPipelineMixDrilldown(filters);
  };

  handleChangeToggle = (e, newAlignment) => {
    this.setState({
      ...this.state,
      toggleValue: newAlignment,
    });
  };

  handleChangeLocationClosedWon = (timeFrame, stage, chart, type) => {
    if (type === true) {
      var filters = [
        {
          name: "cfq",
          value: typeof timeFrame === "string" ? [timeFrame] : timeFrame,
        },
        { name: "sameTimePriorQtr", value: 1 },
      ];
    } else {
      var filters = [
        {
          name: "cfq",
          value: typeof timeFrame === "string" ? [timeFrame] : timeFrame,
        },
      ];
    }

    this.setState({
      ...this.state,
      location: "drilldownOnGraphAttainment",
    });
    if (chart === "home") {
      this.props.getHomeDrilldownOnGraph();
    } else {
      this.props.getClosedDrilldownOnGraph("cameTo", {
        stage: Array.isArray(stage) ? stage : [stage],
        filters,
      });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({
      ...this.state,
      value: newValue,
    });
  };
  sum = (input) => {
    if (toString.call(input) !== "[object Array]") return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  };

  gridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.params = params;
  };

  onFirstDataRendered = (params) => {
    /* var allColumnIds = [];
        params.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        params.columnApi.sizeColumnsToFit(allColumnIds.filter(c => c.colId !== 'OppName'), true); */
    this.onZoom();
  };

  onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  onGridSizeChanged = (params) => {
    var totalColsWidth = 2;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      var column = allColumns[i];
      totalColsWidth +=
        column.maxWidth !== null
          ? Math.min(column.maxWidth, column.getActualWidth())
          : column.getActualWidth();
    }
    this.setState({
      ...this.state,
      containerWidth: totalColsWidth,
    });
  };

  onFirstDataRendered_ = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      this.columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  onColumnResized_ = (params) => {
    params.api.resetRowHeights();
  };
  render() {
    const { classes } = this.props;
    console.log("this.state.location", this.state.location);

    // const HtmlTooltip = styled(({ className, ...props }) => (
    //   <Tooltip {...props} classes={{ popper: className }} />
    // ))({
    //   [`& .${tooltipClasses.tooltip}`]: {
    //     backgroundColor: "rgb(255,255,255,0.8)",
    //     color: "black",
    //     fontFamily: "roboto",
    //     fontSize: '1rem',
    //     borderRadius: "1rem",
    //     padding: "0px",
    //     boxShadow: "0 0.1rem 0.1rem 0",
    //     marginTop: "0.4rem",
    //   },
    // })
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "rgb(255,255,255,0.8)",
        color: "black",
        fontFamily: "roboto",
        fontSize: theme.typography.pxToRem(14),
        borderRadius: "1rem",
        padding: "0px",
        boxShadow: "0 0.1rem 0.1rem 0",
        marginTop: "0.4rem",
      },
    }))(Tp);

    const CustomWidthTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "15rem",
        fontSize: "1rem",
        color: "black",
        textAlign: "center",
        background: "white",
        border: "0.5px solid lightgrey",
      },
    });

    const STLYTooltip = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #4471c4",
                  borderRadius: "0.3rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.3rem",
                }}
              >
                <div className="container">
                  <Typography
                    style={{ padding: "0.1rem" }}
                    className="heading-style"
                  >
                    Same Time Same Quarter QTD Last Year
                  </Typography>
                </div>
              </div>
            </>
          ))}
      </>
    );

    const STLTooltip = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #5b9bd5",
                  borderRadius: "0.3rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.3rem",
                }}
              >
                <div className="container">
                  <Typography
                    style={{ padding: "0.1rem" }}
                    className="heading-style"
                  >
                    Same Time YTD Last Year
                  </Typography>
                </div>
              </div>
            </>
          ))}
      </>
    );

    const STLQTooltip = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #5b9bd5",
                  borderRadius: "0.3rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.3rem",
                }}
              >
                <div className="container">
                  <Typography
                    style={{ padding: "0.1rem" }}
                    className="heading-style"
                  >
                    Same Time QTD Last Quarter
                  </Typography>
                </div>
              </div>
            </>
          ))}
      </>
    );

    // console.log(this.props.data)
    // var openChart  =  document.getElementById("openOppChart")
    // if(openChart!==null){
    //   openChart.addEventListener('mousemove',this.onZoom)
    //   openChart.addEventListener('dblclick',this.onZoom)
    // }
    return (
      <Fragment>
        {(this.props.homepageData !== "" && this.props.EnsembleData !== "") ? (
          <div className={classes.dashOuter}>
            <Grid container className={classes.grid}>
              <Grid
                container
                justify="space-between"
                alignContent="center"
                xs={12}
                style={{ marginLeft: "6%", marginRight: "6%", width: "88%" }}
              >
                <Card
                  className={classes.bookingTableCardContainerv2}
                  xs={5}
                  style={{ width: "36%" }}
                >
                  <Typography
                    variant="h2"
                    align="left"
                    style={{ textTransform: "capitalize", fontWeight: 500 }}
                  >
                    {/* {"Closed Won ACV"} */}
                    {this.props.data[4][0].Role_Type === "admin" || this.props.data[4][0].Role_Type === "Admin" ||
                  this.props.data[4][0].Role_Type === "c-level" || this.props.data[4][0].Role_Type === "C-level" || this.props.data[4][0].Role_Type === "C-Level"
                      ? "  Companywide"
                      : ["manager", "Manger"].includes(this.props.data[4][0].Role_Type)
                      ? "" + this.props.data[4][0].Team
                      : ""}
                  </Typography>
                </Card>
                <Card
                  className={classes.bookingTableCardContainerv2}
                  xs={4}
                  style={
                    this.props.quartersToUse.showProjectedAttainment === "Y"
                      ? { width: "33%" }
                      : { width: "16.5%" }
                  }
                >
                  {(this.props.quartersToUse.showProjectedAttainment === "Y" && !this.props.EnsembleData.expectedHighMidLow) ? (
                    <Grid item container xs={12}>
                      <Grid item xs={5}></Grid>
                      <Grid item xs={7} style={{ padding: "5px 0px" }}>
                        <Grid item container xs={12}>
                          <Grid item xs={7} align="right">
                            {this.state.toggleValue === "ACV-Threshold" && (
                              <Typography
                                align="center"
                                style={{
                                  fontSize: "0.85rem",
                                  background: "#fee599ff",
                                  padding: "0rem 0.2rem",
                                  border: "1px solid lightgrey",
                                  color: "black",
                                }}
                              >
                                <span>{`Excl. outliers ${
                                  ![null, undefined, ""].includes(
                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                      (i) => ["company"].includes(i.name)
                                    )[0].outLayerValue
                                  )
                                    ? `>${countOrACVFormatterThousand(
                                        this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                          (i) => ["company"].includes(i.name)
                                        )[0].outLayerValue,
                                        true
                                      )}`
                                    : null
                                }`}</span>
                                <br />
                                <span>{`${
                                  [null, undefined, ""].includes(
                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                      (i) => ["company"].includes(i.name)
                                    )[0].outlierCount
                                  )
                                    ? 0
                                    : countOrACVFormatter(
                                        this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                          (i) => ["company"].includes(i.name)
                                        )[0].outlierCount
                                      )
                                } / ${
                                  [null, undefined, ""].includes(
                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                      (i) => ["company"].includes(i.name)
                                    )[0].outlierAcv
                                  )
                                    ? 0
                                    : countOrACVFormatterMillion(
                                        this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                          (i) => ["company"].includes(i.name)
                                        )[0].outlierAcv,
                                        true
                                      )
                                }`}</span>
                                {/* {`Excl. outlier opps ${[null, undefined, ''].includes(this.props.PipelineCoverageCFQ.dataWithThreshold.filter((i) => ["company"].includes(i.name) )[0].outlierCount) ? 0 : countOrACVFormatter(this.props.PipelineCoverageCFQ.dataWithThreshold.filter((i) => ["company"].includes(i.name) )[0].outlierCount)} / ${[null, undefined, ''].includes(this.props.PipelineCoverageCFQ.dataWithThreshold.filter((i) => ["company"].includes(i.name) )[0].outlierAcv) ? 0 : countOrACVFormatterMillion(this.props.PipelineCoverageCFQ.dataWithThreshold.filter((i) => ["company"].includes(i.name) )[0].outlierAcv, true)}`} */}
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            align="right"
                            style={{
                              paddingRight: "0px",
                              paddingLeft: "0px",
                              marginBottom: "0.3rem",
                              marginTop: "0.3rem",
                            }}
                          >
                            <ToggleButtonGroup
                              color="primary"
                              indicatorColor="primary"
                              value={this.state.toggleValue}
                              exclusive
                              // size='small'
                              onChange={this.handleChangeToggle}
                            >
                              {/* {children} */}

                              <ToggleButton
                                style={{
                                  background:
                                    this.state.toggleValue === "ACV"
                                      ? "#4472C4"
                                      : "",
                                  color:
                                    this.state.toggleValue === "ACV"
                                      ? "white"
                                      : "",
                                  fontSize: "1rem",
                                  fontWeight: 600,
                                  width: "3rem",
                                  height: "2rem",
                                }}
                                data-test={`acv-button-outlers-included`}
                                value="ACV"
                              >
                                <CustomWidthTooltip
                                  title={`Outliers Included`}
                                  placement="bottom"
                                >
                                  <RadioButtonCheckedIcon />
                                </CustomWidthTooltip>
                              </ToggleButton>
                              <ToggleButton
                                style={{
                                  background:
                                    this.state.toggleValue === "ACV-Threshold"
                                      ? "#4472C4"
                                      : "",
                                  color:
                                    this.state.toggleValue === "ACV-Threshold"
                                      ? "white"
                                      : "",
                                  fontSize: "1rem",
                                  fontWeight: 600,
                                  width: "3rem",
                                  height: "2rem",
                                }}
                                data-test={`currentAcv-button-outliers-excluded`}
                                value="ACV-Threshold"
                              >
                                <CustomWidthTooltip
                                  title="Outliers Excluded"
                                  placement="bottom"
                                >
                                  <FiberManualRecordIcon />
                                </CustomWidthTooltip>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Card>
                <Card
                  className={classes.bookingTableCardContainerv2}
                  xs={3}
                  style={{ width: "25%" }}
                ></Card>
              </Grid>

              <Grid
                container
                justify="space-between"
                alignContent="center"
                xs={12}
                style={{
                  marginBottom: 10,
                  marginLeft: "6%",
                  marginRight: "6%",
                  width: "88%",
                }}
              >
                <Card
                  className={classes.bookingTableCardContainer}
                  raised={true}
                  xs={5}
                  style={{ width: "36%" }}
                >
                  {this.props.AttainmentDataYTD !== "" &&
                  this.props.AttainmentDataCFQ !== "" &&
                  this.props.PipelineCoverageCFQ !== "" &&
                  this.props.EnsembleData !== "" &&
                  ![null, "", undefined].includes(
                    this.props.PipelineCoverageCFQ
                  ) ? (
                    <>
                      <TableContainer
                        className={classes.mainTable}
                        style={{ width: "100%" }}
                      >
                        <Table
                          size="small"
                          aria-label="html table"
                          style={{ tableLayout: "auto", width: "100%" }}
                          data-test="won-attainment-detail-table"
                        >
                          <TableBody>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="center"
                                className={classes.repHeader}
                                style={{
                                  background: "#4471c4",
                                  color: "white",
                                  width: "36%",
                                  minWidth: "36%",
                                  fontSize: "1rem",
                                }}
                                colSpan={3}
                              >{`${this.props.quartersToUse.currentFiscalQuarter} QTD`}</TableCell>
                              <HtmlTooltip title={STLQTooltip}>
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  style={{
                                    background: "#5b9bd5",
                                    color: "white",
                                    width: "32%",
                                    maxWidth: "32%",
                                    fontSize: "1rem",
                                  }}
                                  colSpan={3}
                                >{`${this.props.quartersToUse.currentPriorQuarter} (STLQ)`}</TableCell>
                              </HtmlTooltip>
                              <HtmlTooltip title={STLYTooltip}>
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  style={{
                                    background: "#4471c4",
                                    color: "white",
                                    width: "32%",
                                    maxWidth: "32%",
                                    fontSize: "1rem",
                                  }}
                                  colSpan={3}
                                >{`${this.props.quartersToUse.curentQtrPriorYear} (STLY)`}</TableCell>
                              </HtmlTooltip>
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={1}
                                className={classes.datacell2}
                              >
                                Won:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600, cursor: "pointer" }}
                                className={classes.datacell}
                                onClick={() =>
                                  this.handleChangeLocationClosedWon(
                                    this.props.quartersToUse
                                      .currentFiscalQuarter,
                                    this.props.quartersToUse.wonStage,
                                    undefined
                                  )
                                }
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.qtdACV
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData.qtdACV,
                                      true
                                    )}
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600, cursor: "pointer" }}
                                className={classes.datacell}
                                onClick={() =>
                                  this.handleChangeLocationClosedWon(
                                    this.props.quartersToUse
                                      .currentPriorQuarter,
                                    this.props.quartersToUse.wonStage,
                                    undefined,
                                    true
                                  )
                                }
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.priorQuarterACV
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData
                                        .priorQuarterACV,
                                      true
                                    )}
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600, cursor: "pointer" }}
                                className={classes.datacell}
                                onClick={() =>
                                  this.handleChangeLocationClosedWon(
                                    this.props.quartersToUse.curentQtrPriorYear,
                                    this.props.quartersToUse.wonStage,
                                    undefined,
                                    true
                                  )
                                }
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData
                                    .sameQtrPreviousYearACV
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData
                                        .sameQtrPreviousYearACV,
                                      true
                                    )}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={1}
                                className={classes.datacell2}
                              >
                                Quota:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.qtdQuota
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData.qtdQuota,
                                      true
                                    )}
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.priorQuarterQuota
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData
                                        .priorQuarterQuota,
                                      true
                                    )}
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData
                                    .sameQtrPreviousYearQuota
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData
                                        .sameQtrPreviousYearQuota,
                                      true
                                    )}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={1}
                                className={classes.datacell2}
                              >
                                Quota %:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.qtdPerc
                                )
                                  ? "-"
                                  : `${(
                                      this.props.differentQtrData.qtdPerc * 100
                                    ).toFixed(1)}%`}
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                <span>
                                  {["", undefined, null].includes(
                                    this.props.differentQtrData.priorQuarterPerc
                                  )
                                    ? "-"
                                    : `${(
                                        this.props.differentQtrData
                                          .priorQuarterPerc * 100
                                      ).toFixed(1)}%`}
                                </span>
                                &nbsp;
                                <span>(</span>
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.priorQuarterPerc
                                ) ? (
                                  "-"
                                ) : this.props.differentQtrData.qtdPerc -
                                    this.props.differentQtrData
                                      .priorQuarterPerc >=
                                  0 ? (
                                  <span style={{ color: "#517E32" }}>
                                    +
                                    {(
                                      (this.props.differentQtrData.qtdPerc -
                                        this.props.differentQtrData
                                          .priorQuarterPerc) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </span>
                                ) : (
                                  <span style={{ color: "#ED7C31" }}>
                                    {(
                                      (this.props.differentQtrData.qtdPerc -
                                        this.props.differentQtrData
                                          .priorQuarterPerc) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </span>
                                )}
                                <span>)</span>
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                <span>
                                  {["", undefined, null].includes(
                                    this.props.differentQtrData
                                      .sameQtrPreviousYearPerc
                                  )
                                    ? "-"
                                    : `${(
                                        this.props.differentQtrData
                                          .sameQtrPreviousYearPerc * 100
                                      ).toFixed(1)}%`}
                                </span>
                                &nbsp;
                                <span>(</span>
                                {["", undefined, null].includes(
                                  this.props.differentQtrData
                                    .sameQtrPreviousYearPerc
                                ) ? (
                                  "-"
                                ) : this.props.differentQtrData.qtdPerc -
                                    this.props.differentQtrData
                                      .sameQtrPreviousYearPerc >=
                                  0 ? (
                                  <span style={{ color: "#517E32" }}>
                                    +
                                    {(
                                      (this.props.differentQtrData.qtdPerc -
                                        this.props.differentQtrData
                                          .sameQtrPreviousYearPerc) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </span>
                                ) : (
                                  <span style={{ color: "#ED7C31" }}>
                                    {(
                                      (this.props.differentQtrData.qtdPerc -
                                        this.props.differentQtrData
                                          .sameQtrPreviousYearPerc) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </span>
                                )}
                                <span>)</span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Grid
                      component={Card}
                      item
                      container
                      justify="center"
                      style={{
                        height: "100%",
                        backgroundColor: "none",
                        position: "absolute",
                        // border: '2px solid rgb(13, 98, 148)',
                        top: "0%",
                      }}
                    >
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={150}
                        width="95%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
                <Card
                  className={classes.bookingTableCardContainer}
                  raised={true}
                  xs={4}
                  style={
                    this.props.quartersToUse.showProjectedAttainment === "Y"
                      ? { width: "33%" }
                      : { width: "16.5%" }
                  }
                >
                  {this.props.AttainmentDataYTD !== "" &&
                  this.props.AttainmentDataCFQ !== "" &&
                  this.props.PipelineCoverageCFQ !== "" &&
                  this.props.EnsembleData !== "" &&
                  ![null, "", undefined].includes(
                    this.props.PipelineCoverageCFQ
                  ) ? (
                    <>
                      <TableContainer
                        className={classes.mainTable}
                        style={{ width: "100%" }}
                      >
                        <Table
                          size="small"
                          aria-label="html table"
                          style={{ tableLayout: "auto", width: "100%" }}
                          data-test="quota-attainment-detail-table"
                          id="Home_Page_Current_Quarter_table"
                        >
                          <TableBody>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            ></TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              {this.props.quartersToUse
                                .showProjectedAttainment === "Y" ? (
                                <>
                                  <TableCell
                                    align="center"
                                    style={{
                                      background: "#4471c4",
                                      color: "white",
                                      width: "50%",
                                      minWidth: "50%",
                                      fontSize: "1rem",
                                    }}
                                    className={classes.repHeader}
                                    colSpan={4}
                                  >{`${this.props.quartersToUse.currentFiscalQuarter} Pipeline`}</TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      background: "#5b9bd5",
                                      color: "white",
                                      width: "50%",
                                      maxWidth: "50%",
                                      fontSize: "1rem",
                                    }}
                                    className={classes.repHeader}
                                    colSpan={4}
                                  >
                                    {`SkyGeni Projection`}
                                  </TableCell>
                                </>
                              ) : (
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  style={{
                                    background: "#4471c4",
                                    color: "white",
                                    width: "100%",
                                    fontSize: "1rem",
                                  }}
                                  colSpan={4}
                                >{`${this.props.quartersToUse.currentFiscalQuarter} Pipeline`}</TableCell>
                              )}
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={2}
                                className={classes.datacell2}
                              >
                                Quota Left:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.qtdQuota -
                                    this.props.differentQtrData.qtdACV
                                ) ||
                                this.props.differentQtrData.qtdQuota <
                                  this.props.differentQtrData.qtdACV
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData.qtdQuota -
                                        this.props.differentQtrData.qtdACV,
                                      true
                                    )}
                              </TableCell>
                              {this.props.quartersToUse
                                .showProjectedAttainment === "Y" && (
                                <>
                                  <TableCell
                                    align="right"
                                    colSpan={1}
                                    className={classes.datacell2}
                                  >
                                    Low:
                                  </TableCell>
                                  {this.state.toggleValue === "ACV-Threshold" &&
                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                    (i) => ["company"].includes(i.name)
                                  )[0].projectedAttainmentLow <
                                    this.props.PipelineCoverageCFQ.data.filter(
                                      (i) => ["company"].includes(i.name)
                                    )[0].projectedAttainmentLow ? (
                                    <HtmlTooltip
                                      title={
                                        <div
                                          style={{
                                            borderTop: "solid #FF0000",
                                            borderRadius: "1rem",
                                            padding: "0px",
                                            margin: 0,
                                            borderWidth: "0.6rem",
                                          }}
                                        >
                                          <div
                                            className="tooltip-box"
                                            style={{
                                              padding: "0px",
                                              fontSize: "0.9rem",
                                            }}
                                          >
                                            <h4
                                              className="heading-style"
                                              style={{ padding: "0.5rem" }}
                                            >
                                              {`Excluding Outliers ${
                                                ![null, undefined, ""].includes(
                                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].outLayerValue
                                                )
                                                  ? `>${countOrACVFormatterThousand(
                                                      this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                        (i) =>
                                                          ["company"].includes(
                                                            i.name
                                                          )
                                                      )[0].outLayerValue,
                                                      true
                                                    )}`
                                                  : null
                                              }`}
                                            </h4>
                                            <div
                                              style={{
                                                paddingLeft: "0.8rem",
                                                paddingRight: "0.8rem",
                                              }}
                                            >
                                              <hr />
                                            </div>
                                            <div
                                              style={{
                                                paddingRight: "0.5rem",
                                                paddingLeft: "0.5rem",
                                                paddingTop: "0.3rem",
                                                paddingBottom: "0.3rem",
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              <h4 className="result-style">
                                                Outlier Opps :{" "}
                                                <span
                                                  style={{
                                                    color: "rgb(31,119,180)",
                                                  }}
                                                >
                                                  {countOrACVFormatter(
                                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                      (i) =>
                                                        ["company"].includes(
                                                          i.name
                                                        )
                                                    )[0].outlierCount
                                                  )}
                                                </span>{" "}
                                              </h4>
                                            </div>
                                            <div
                                              style={{
                                                paddingright: "0.5rem",
                                                paddingLeft: "0.5rem",
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              <h4 className="result-style">
                                                Outlier ACV :{" "}
                                                <span
                                                  style={{
                                                    color: "rgb(31,119,180)",
                                                  }}
                                                >
                                                  {countOrACVFormatterMillion(
                                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                      (i) =>
                                                        ["company"].includes(
                                                          i.name
                                                        )
                                                    )[0].outlierAcv,
                                                    true
                                                  )}
                                                </span>{" "}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <TableCell
                                        align="right"
                                        colSpan={3}
                                        style={{
                                          fontWeight: 600,
                                          cursor: "pointer",
                                        }}
                                        className={classes.datacell}
                                        onClick={() =>
                                          this.handleChangeLocation()
                                        }
                                      >
                                        <>
                                          <span>
                                            {["", undefined, null].includes(
                                              this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentLow
                                            )
                                              ? "-"
                                              : countOrACVFormatterMillion(
                                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].projectedAttainmentLow,
                                                  true
                                                )}
                                          </span>
                                          &nbsp;
                                          <span>
                                            {[null, undefined, "", 0].includes(
                                              this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentLow
                                            ) ||
                                            [null, undefined, "", 0].includes(
                                              this.props.differentQtrData
                                                .qtdQuota
                                            )
                                              ? "(-)"
                                              : `(${(
                                                  (this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].projectedAttainmentLow /
                                                    this.props.differentQtrData
                                                      .qtdQuota) *
                                                  100
                                                ).toFixed(1)}%)`}
                                          </span>
                                          {this.state.toggleValue ===
                                            "ACV-Threshold" &&
                                            this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                              (i) =>
                                                ["company"].includes(i.name)
                                            )[0].projectedAttainmentLow <
                                              this.props.PipelineCoverageCFQ.data.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentLow && (
                                              <span
                                                style={{ color: "#FF0000" }}
                                              >
                                                *
                                              </span>
                                            )}
                                        </>
                                      </TableCell>
                                    </HtmlTooltip>
                                  ) : (
                                    <TableCell
                                      align="right"
                                      colSpan={3}
                                      style={{
                                        fontWeight: 600,
                                        cursor: "pointer",
                                      }}
                                      className={classes.datacell}
                                      onClick={() =>
                                        this.handleChangeLocation()
                                      }
                                    >
                                      <span>
                                        {(["", undefined, null].includes(
                                         this.props.PipelineCoverageCFQ.data.filter(
                                            (i) => ["company"].includes(i.name)
                                          )[0].projectedAttainmentLow 
                                        ) && ["", undefined, null].includes(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].low)
                                          ? "-"
                                          : 
                                          countOrACVFormatterMillion(Math.round((this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].low)), true)
                                         )}
                                      </span>
                                      &nbsp;
                                      <span>
                                        {([null, undefined, "", 0].includes(
                                          this.props.PipelineCoverageCFQ.data.filter(
                                            (i) => ["company"].includes(i.name)
                                          )[0].projectedAttainmentLow
                                        ) && ["", undefined, null].includes(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].low)) ||
                                        [null, undefined, "", 0].includes(
                                          this.props.differentQtrData.qtdQuota
                                        )
                                          ? "(-)"
                                          : `(${(
                                              (Math.round((this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].low)) /
                                                this.props.differentQtrData
                                                  .qtdQuota) *
                                              100
                                            ).toFixed(1)}%)`}
                                      </span>
                                      <span style={{ color: "#fff" }}>*</span>
                                    </TableCell>
                                  )}
                                </>
                              )}
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={2}
                                className={classes.datacell2}
                              >
                                Qualified Pipe:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600, cursor: "pointer" }}
                                className={classes.datacell}
                                onClick={() =>
                                  this.props.quartersToUse
                                    .showProjectedAttainment === "Y"
                                    ? this.handleChangeLocation()
                                    : this.handleChangeLocationPipeline(
                                        this.props.quartersToUse
                                          .currentFiscalQuarter,
                                        this.props.quartersToUse.openStages
                                      )
                                }
                              >
                                {["", undefined, null].includes(
                                  this.props.EnsembleData.qualified_pipeline
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                    this.props.EnsembleData.qualified_pipeline,
                                      true
                                    )}
                              </TableCell>
                              {this.props.quartersToUse
                                .showProjectedAttainment === "Y" && (
                                <>
                                  <TableCell
                                    align="right"
                                    colSpan={1}
                                    className={classes.datacell2}
                                  >
                                    Mid:
                                  </TableCell>
                                  {this.state.toggleValue === "ACV-Threshold" &&
                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                    (i) => ["company"].includes(i.name)
                                  )[0].projectedAttainmentMid <
                                    this.props.PipelineCoverageCFQ.data.filter(
                                      (i) => ["company"].includes(i.name)
                                    )[0].projectedAttainmentMid ? (
                                    <HtmlTooltip
                                      title={
                                        <div
                                          style={{
                                            borderTop: "solid #FF0000",
                                            borderRadius: "1rem",
                                            padding: "0px",
                                            margin: 0,
                                            borderWidth: "0.6rem",
                                          }}
                                        >
                                          <div
                                            className="tooltip-box"
                                            style={{
                                              padding: "0px",
                                              fontSize: "0.9rem",
                                            }}
                                          >
                                            <h4
                                              className="heading-style"
                                              style={{ padding: "0.5rem" }}
                                            >
                                              {`Excluding Outliers ${
                                                ![null, undefined, ""].includes(
                                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].outLayerValue
                                                )
                                                  ? `>${countOrACVFormatterThousand(
                                                      this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                        (i) =>
                                                          ["company"].includes(
                                                            i.name
                                                          )
                                                      )[0].outLayerValue,
                                                      true
                                                    )}`
                                                  : null
                                              }`}
                                            </h4>
                                            <div
                                              style={{
                                                paddingLeft: "0.8rem",
                                                paddingRight: "0.8rem",
                                              }}
                                            >
                                              <hr />
                                            </div>
                                            <div
                                              style={{
                                                paddingRight: "0.5rem",
                                                paddingLeft: "0.5rem",
                                                paddingTop: "0.3rem",
                                                paddingBottom: "0.3rem",
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              <h4 className="result-style">
                                                Outlier Opps :{" "}
                                                <span
                                                  style={{
                                                    color: "rgb(31,119,180)",
                                                  }}
                                                >
                                                  {countOrACVFormatter(
                                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                      (i) =>
                                                        ["company"].includes(
                                                          i.name
                                                        )
                                                    )[0].outlierCount
                                                  )}
                                                </span>{" "}
                                              </h4>
                                            </div>
                                            <div
                                              style={{
                                                paddingright: "0.5rem",
                                                paddingLeft: "0.5rem",
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              <h4 className="result-style">
                                                Outlier ACV :{" "}
                                                <span
                                                  style={{
                                                    color: "rgb(31,119,180)",
                                                  }}
                                                >
                                                  {countOrACVFormatterMillion(
                                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                      (i) =>
                                                        ["company"].includes(
                                                          i.name
                                                        )
                                                    )[0].outlierAcv,
                                                    true
                                                  )}
                                                </span>{" "}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <TableCell
                                        align="right"
                                        colSpan={3}
                                        style={{
                                          fontWeight: 600,
                                          cursor: "pointer",
                                        }}
                                        className={classes.datacell}
                                        onClick={() =>
                                          this.handleChangeLocation()
                                        }
                                      >
                                        <>
                                        <span>
                                            {["", undefined, null].includes(
                                              this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentMid
                                            )
                                              ? "-"
                                              : countOrACVFormatterMillion(
                                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].projectedAttainmentMid,
                                                  true
                                                )}
                                          </span>
                                          &nbsp;
                                          <span>
                                            {[null, undefined, "", 0].includes(
                                              this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentMid
                                            ) ||
                                            [null, undefined, "", 0].includes(
                                              this.props.differentQtrData
                                                .qtdQuota
                                            )
                                              ? "(-)"
                                              : `(${(
                                                  (this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].projectedAttainmentMid /
                                                    this.props.differentQtrData
                                                      .qtdQuota) *
                                                  100
                                                ).toFixed(1)}%)`}
                                          </span>
                                          {this.state.toggleValue ===
                                            "ACV-Threshold" &&
                                            this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                              (i) =>
                                                ["company"].includes(i.name)
                                            )[0].projectedAttainmentMid <
                                              this.props.PipelineCoverageCFQ.data.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentMid && (
                                              <span
                                                style={{ color: "#FF0000" }}
                                              >
                                                *
                                              </span>
                                            )}
                                        </>
                                      </TableCell>
                                    </HtmlTooltip>
                                  ) : (
                                    <TableCell
                                      align="right"
                                      colSpan={3}
                                      style={{
                                        fontWeight: 600,
                                        cursor: "pointer",
                                      }}
                                      className={classes.datacell}
                                      onClick={() =>
                                        this.handleChangeLocation()
                                      }
                                    >
                                       <span>
                                        {(["", undefined, null].includes(
                                         this.props.PipelineCoverageCFQ.data.filter(
                                            (i) => ["company"].includes(i.name)
                                          )[0].projectedAttainmentMid
                                        ) && ["", undefined, null].includes(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].mid)
                                          ? "-"
                                          : 
                                          countOrACVFormatterMillion(Math.round(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].mid), true)
                                         )}
                                      </span>
                                      &nbsp;
                                      <span>
                                        {([null, undefined, "", 0].includes(
                                          this.props.PipelineCoverageCFQ.data.filter(
                                            (i) => ["company"].includes(i.name)
                                          )[0].projectedAttainmentMid
                                        ) && ["", undefined, null].includes(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].mid)) ||
                                        [null, undefined, "", 0].includes(
                                          this.props.differentQtrData.qtdQuota
                                        )
                                          ? "(-)"
                                          : `(${(
                                              (Math.round(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].mid) /
                                                this.props.differentQtrData
                                                  .qtdQuota) *
                                              100
                                            ).toFixed(1)}%)`}
                                      </span>
                                      <span style={{ color: "#fff" }}>*</span>
                                    </TableCell>
                                  )}
                                </>
                              )}
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={2}
                                className={classes.datacell2}
                              >
                                Coverage Ratio:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.qtdQuota -
                                    this.props.differentQtrData.qtdACV
                                ) ||
                                this.props.differentQtrData.qtdQuota <
                                  this.props.differentQtrData.qtdACV ||
                                ["", undefined, null].includes(
                                  this.props.PipelineCoverageCFQ.data.filter(
                                    (i) => ["company"].includes(i.name)
                                  )[0].acv
                                )
                                  ? "-"
                                  : (
                                      this.props.PipelineCoverageCFQ.data.filter(
                                        (i) => ["company"].includes(i.name)
                                      )[0].acv /
                                      (this.props.differentQtrData.qtdQuota -
                                        this.props.differentQtrData.qtdACV)
                                    ).toFixed(1)}
                              </TableCell>
                              {this.props.quartersToUse
                                .showProjectedAttainment === "Y" && (
                                <>
                                  <TableCell
                                    align="right"
                                    colSpan={1}
                                    className={classes.datacell2}
                                  >
                                    High:
                                  </TableCell>
                                  {this.state.toggleValue === "ACV-Threshold" &&
                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                    (i) => ["company"].includes(i.name)
                                  )[0].projectedAttainmentHigh <
                                    this.props.PipelineCoverageCFQ.data.filter(
                                      (i) => ["company"].includes(i.name)
                                    )[0].projectedAttainmentHigh ? (
                                    <HtmlTooltip
                                      title={
                                        <div
                                          style={{
                                            borderTop: "solid #FF0000",
                                            borderRadius: "1rem",
                                            padding: "0px",
                                            margin: 0,
                                            borderWidth: "0.6rem",
                                          }}
                                        >
                                          <div
                                            className="tooltip-box"
                                            style={{
                                              padding: "0px",
                                              fontSize: "0.9rem",
                                            }}
                                          >
                                            <h4
                                              className="heading-style"
                                              style={{ padding: "0.5rem" }}
                                            >
                                              {`Excluding Outliers ${
                                                ![null, undefined, ""].includes(
                                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].outLayerValue
                                                )
                                                  ? `>${countOrACVFormatterThousand(
                                                      this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                        (i) =>
                                                          ["company"].includes(
                                                            i.name
                                                          )
                                                      )[0].outLayerValue,
                                                      true
                                                    )}`
                                                  : null
                                              }`}
                                            </h4>
                                            <div
                                              style={{
                                                paddingLeft: "0.8rem",
                                                paddingRight: "0.8rem",
                                              }}
                                            >
                                              <hr />
                                            </div>
                                            <div
                                              style={{
                                                paddingRight: "0.5rem",
                                                paddingLeft: "0.5rem",
                                                paddingTop: "0.3rem",
                                                paddingBottom: "0.3rem",
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              <h4 className="result-style">
                                                Outlier Opps :{" "}
                                                <span
                                                  style={{
                                                    color: "rgb(31,119,180)",
                                                  }}
                                                >
                                                  {countOrACVFormatter(
                                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                      (i) =>
                                                        ["company"].includes(
                                                          i.name
                                                        )
                                                    )[0].outlierCount
                                                  )}
                                                </span>{" "}
                                              </h4>
                                            </div>
                                            <div
                                              style={{
                                                paddingRight: "0.5rem",
                                                paddingLeft: "0.5rem",
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              <h4 className="result-style">
                                                Outlier ACV :{" "}
                                                <span
                                                  style={{
                                                    color: "rgb(31,119,180)",
                                                  }}
                                                >
                                                  {countOrACVFormatterMillion(
                                                    this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                      (i) =>
                                                        ["company"].includes(
                                                          i.name
                                                        )
                                                    )[0].outlierAcv,
                                                    true
                                                  )}
                                                </span>{" "}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <TableCell
                                        align="right"
                                        colSpan={3}
                                        style={{
                                          fontWeight: 600,
                                          cursor: "pointer",
                                        }}
                                        className={classes.datacell}
                                        onClick={() =>
                                          this.handleChangeLocation()
                                        }
                                      >
                                        <>
                                        <span>
                                            {["", undefined, null].includes(
                                              this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentHigh
                                            )
                                              ? "-"
                                              : countOrACVFormatterMillion(
                                                  this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].projectedAttainmentHigh,
                                                  true
                                                )}
                                          </span>
                                          &nbsp;
                                          <span>
                                            {[null, undefined, "", 0].includes(
                                              this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentHigh
                                            ) ||
                                            [null, undefined, "", 0].includes(
                                              this.props.differentQtrData
                                                .qtdQuota
                                            )
                                              ? "(-)"
                                              : `(${(
                                                  (this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                                    (i) =>
                                                      ["company"].includes(
                                                        i.name
                                                      )
                                                  )[0].projectedAttainmentHigh /
                                                    this.props.differentQtrData
                                                      .qtdQuota) *
                                                  100
                                                ).toFixed(1)}%)`}
                                          </span>
                                          {this.state.toggleValue ===
                                            "ACV-Threshold" &&
                                            this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                                              (i) =>
                                                ["company"].includes(i.name)
                                            )[0].projectedAttainmentHigh <
                                              this.props.PipelineCoverageCFQ.data.filter(
                                                (i) =>
                                                  ["company"].includes(i.name)
                                              )[0].projectedAttainmentHigh && (
                                              <span
                                                style={{ color: "#FF0000" }}
                                              >
                                                *
                                              </span>
                                            )}
                                        </>
                                      </TableCell>
                                    </HtmlTooltip>
                                  ) : (
                                    <TableCell
                                      align="right"
                                      colSpan={3}
                                      style={{
                                        fontWeight: 600,
                                        cursor: "pointer",
                                      }}
                                      className={classes.datacell}
                                      onClick={() =>
                                        this.handleChangeLocation()
                                      }
                                    >
                                       <span>
                                        {(["", undefined, null].includes(
                                         this.props.PipelineCoverageCFQ.data.filter(
                                            (i) => ["company"].includes(i.name)
                                          )[0].projectedAttainmentHigh
                                        ) && ["", undefined, null].includes(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].high)
                                          ? "-"
                                          : 
                                          countOrACVFormatterMillion(Math.round(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].high), true)
                                         )}
                                      </span>
                                      &nbsp;
                                      <span>
                                        {([null, undefined, "", 0].includes(
                                          this.props.PipelineCoverageCFQ.data.filter(
                                            (i) => ["company"].includes(i.name)
                                          )[0].projectedAttainmentHigh
                                        ) && ["", undefined, null].includes(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].high)) ||
                                        [null, undefined, "", 0].includes(
                                          this.props.differentQtrData.qtdQuota
                                        )
                                          ? "(-)"
                                          : `(${(
                                              (Math.round(this.props.EnsembleData.lowMidHigh.filter(f => f.category === 'Total')[0].high) /
                                                this.props.differentQtrData
                                                  .qtdQuota) *
                                              100
                                            ).toFixed(1)}%)`}
                                      </span>
                                      <span style={{ color: "#fff" }}>*</span>
                                    </TableCell>
                                  )}
                                </>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Grid
                      component={Card}
                      item
                      container
                      justify="center"
                      style={{
                        height: "100%",
                        backgroundColor: "none",
                        position: "absolute",
                        // border: '2px solid rgb(13, 98, 148)',
                        top: "0%",
                      }}
                    >
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={150}
                        width="95%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
                <Card
                  className={classes.bookingTableCardContainer}
                  raised={true}
                  xs={3}
                  style={{ width: "25%" }}
                >
                  {this.props.AttainmentDataYTD !== "" &&
                  this.props.AttainmentDataCFQ !== "" &&
                  this.props.PipelineCoverageCFQ !== "" &&
                  this.props.EnsembleData !== "" &&
                  ![null, "", undefined].includes(
                    this.props.PipelineCoverageCFQ
                  ) ? (
                    <>
                      <TableContainer
                        className={classes.mainTable}
                        style={{ width: "100%" }}
                      >
                        <Table
                          size="small"
                          aria-label="html table"
                          style={{ tableLayout: "auto", width: "100%" }}
                          data-test="won-attainment-year-detail-table"
                        >
                          <TableBody>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="center"
                                className={classes.repHeader}
                                style={{
                                  background: "#4471c4",
                                  color: "white",
                                  width: "50%",
                                  minWidth: "50%",
                                  fontSize: "1rem",
                                }}
                                colSpan={3}
                              >{`${this.props.quartersToUse.currentYear} YTD`}</TableCell>
                              <HtmlTooltip title={STLTooltip}>
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  style={{
                                    background: "#5b9bd5",
                                    color: "white",
                                    width: "50%",
                                    maxWidth: "50%",
                                    fontSize: "1rem",
                                  }}
                                  colSpan={3}
                                >{`${this.props.quartersToUse.priorYear} (STLY)`}</TableCell>
                              </HtmlTooltip>
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={1}
                                className={classes.datacell2}
                              >
                                Won:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600, cursor: "pointer" }}
                                className={classes.datacell}
                                onClick={() =>
                                  this.handleChangeLocationClosedWon(
                                    this.props.quartersToUse.YTDFiscalQuarters,
                                    this.props.quartersToUse.wonStage,
                                    undefined
                                  )
                                }
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.ytdACV
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData.ytdACV,
                                      true
                                    )}
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600, cursor: "pointer" }}
                                className={classes.datacell}
                                onClick={() =>
                                  this.handleChangeLocationClosedWon(
                                    this.props.quartersToUse
                                      .priorYTDFiscalQuarters,
                                    this.props.quartersToUse.wonStage,
                                    "home"
                                  )
                                }
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.priorYTDQuarterACV
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData
                                        .priorYTDQuarterACV,
                                      true
                                    )}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={1}
                                className={classes.datacell2}
                              >
                                Quota:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.ytdQuota
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData.ytdQuota,
                                      true
                                    )}
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData
                                    .priorYTDQuarterQuota
                                )
                                  ? "-"
                                  : countOrACVFormatterMillion(
                                      this.props.differentQtrData
                                        .priorYTDQuarterQuota,
                                      true
                                    )}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              style={{ fontWeight: 600, paddingLeft: 5 }}
                            >
                              <TableCell
                                align="right"
                                colSpan={1}
                                className={classes.datacell2}
                              >
                                Quota %:
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={2}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                {["", undefined, null].includes(
                                  this.props.differentQtrData.ytdPerc
                                )
                                  ? "-"
                                  : `${(
                                      this.props.differentQtrData.ytdPerc * 100
                                    ).toFixed(1)}%`}
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan={3}
                                style={{ fontWeight: 600 }}
                                className={classes.datacell}
                              >
                                <span>
                                  {["", undefined, null].includes(
                                    this.props.differentQtrData
                                      .priorYTDQuartersPerc
                                  )
                                    ? "-"
                                    : `${(
                                        this.props.differentQtrData
                                          .priorYTDQuartersPerc * 100
                                      ).toFixed(1)}%`}
                                </span>
                                &nbsp;
                                <span>(</span>
                                {["", undefined, null].includes(
                                  this.props.differentQtrData
                                    .priorYTDQuartersPerc
                                ) ? (
                                  "-"
                                ) : this.props.differentQtrData.ytdPerc -
                                    this.props.differentQtrData
                                      .priorYTDQuartersPerc >=
                                  0 ? (
                                  <span style={{ color: "#517E32" }}>
                                    +
                                    {(
                                      (this.props.differentQtrData.ytdPerc -
                                        this.props.differentQtrData
                                          .priorYTDQuartersPerc) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </span>
                                ) : (
                                  <span style={{ color: "#ED7C31" }}>
                                    {(
                                      (this.props.differentQtrData.ytdPerc -
                                        this.props.differentQtrData
                                          .priorYTDQuartersPerc) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </span>
                                )}
                                <span>)</span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Grid
                      component={Card}
                      item
                      container
                      justify="center"
                      style={{
                        height: "100%",
                        backgroundColor: "none",
                        position: "absolute",
                        // border: '2px solid rgb(13, 98, 148)',
                        top: "0%",
                      }}
                    >
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={150}
                        width="95%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{
                  marginLeft: "6%",
                  marginRight: "6%",
                  marginBottom: 0,
                  marginTop: 10,
                  marginBottom: 10,
                  width: "88%",
                }}
              >
                <Typography
                  variant="h2"
                  display="inline"
                  className={classes.acvTotal}
                  // style={{ fontWeight: 500 }}
                >
                  {" "}
                  Top 25 Qualified Opportunities Projected to Close in the next
                  six months
                  {this.props.data[4][0].Role_Type === "admin" || this.props.data[4][0].Role_Type === "Admin" ||
                  this.props.data[4][0].Role_Type === "c-level" || this.props.data[4][0].Role_Type === "C-level" || this.props.data[4][0].Role_Type === "C-Level"
                    ? " - Companywide"
                    : ["manager", "Manger"].includes(this.props.data[4][0].Role_Type)
                    ? " - " + this.props.data[4][0].Team
                    : ""}
                </Typography>
                <Typography
                  variant="h2"
                  display="inline"
                  style={{ fontWeight: 500 }}
                >
                  {" "}
                  {`Total: ${this.props.Currency_Symbol} ` +
                    d3.format(",")(
                      Math.round(this.sum(this.props.data[3].map((h) => h.ACV)))
                    )}
                </Typography>
              </Grid>
              <Grid
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
                item
                xs={12}
              >
                <Card style={{ margin: "auto", width: "88%" }} raised={true}>
                  <Suspense
                    fallback={
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="95%"
                        height={330}
                        style={{ margin: "2rem" }}
                      />
                    }
                  >
                    {this.props.company === "C0004" ? (
                      <C0004_OpenOpp
                        homepageData={this.props.data}
                        company={this.props.company}
                        handleChange={this.handleSecondLevelDrilldown}
                        Value_Label={this.props.Value_Label}
                      />
                    ) : this.props.company === "C0003" ? (
                      <C0003_OpenOpp
                        homepageData={this.props.data}
                        company={this.props.company}
                        handleChange={this.handleSecondLevelDrilldown}
                        Value_Label={this.props.Value_Label}
                      />
                    ) : this.props.company === "C0002" ? (
                      <C0002_OpenOpp
                        homepageData={this.props.data}
                        company={this.props.company}
                        handleChange={this.handleSecondLevelDrilldown}
                        Value_Label={this.props.Value_Label}
                      />
                    ) : (
                      <OpenOpp
                        homepageData={this.props.data}
                        company={this.props.company}
                        handleChange={this.handleSecondLevelDrilldown}
                        Value_Label={this.props.Value_Label}
                      />
                    )}
                  </Suspense>
                </Card>
              </Grid>

              {/* <Grid item xs={12}>
                <AppBar position="static">
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange.bind(this)}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Item One" {...a11yProps(0)} />
                    <Tab label="Item Two" {...a11yProps(1)} />
                    <Tab label="Item Three" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                  Item One
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                  Item Two
                </TabPanel>
                <TabPanel value={this.state.value} index={2}>
                  Item Three
                </TabPanel>
              </Grid> */}
              <Grid
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
                item
                xs={12}
                id="grid-container"
                className={classes.gridContainer}
              >
                <div
                  className="ag-theme-custom-react_DB"
                  style={{
                    width: "88%", //this.state.containerWidth,
                    height: 400,
                  }}
                >
                  <AgGridReact
                    columnDefs={[
                      {
                        headerName: "#",
                        field: "OppNo",
                        sortable: false,
                        filter: false,
                        cellClass: classes.tableCell,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          direction: "rtl",
                          "padding-right": "3px",
                          cursor: "pointer",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                        maxWidth: 45,
                      },
                      ...this.props.columns.map((c) => {
                        return {
                          headerName: replaceAll(
                            c.label,
                            "acv",
                            `${this.props.Value_Label}`
                          ),
                          field: c.field,
                          filter:
                            c.type === "currency"
                              ? "agNumberColumnFilter"
                              : true,
                          sortingOrder:
                            c.type === "currency"
                              ? ["desc", "asc"]
                              : ["asc", "desc"],
                          valueFormatter:
                            (c.type === "currency" && currencyFormatter) ||
                            (c.type === "datetime" && dateAndTimeFormatter) ||
                            (c.type === "date" && dateFormatter) ||
                            (c.field === "createEventType" &&
                              newOwpFormatter) ||
                            (c.type === "percent" && percentageDFormatter),
                            Symbol: this.props.Currency_Symbol,
                          type:
                            c.type === "currency" ||
                            c.type === "percent" ||
                            c.type === "integer" ||
                            c.type === "datetime" ||
                            c.type === "date"
                              ? "rightAligned"
                              : "leftAligned",
                          cellStyle:
                            c.type === "currency" || c.type === "integer"
                              ? { "padding-right": "5px", cursor: "pointer" }
                              : { "padding-left": "5px", cursor: "pointer" },
                          headerClass: classes.tableHeader,
                          filterParams:
                            c.type === "currency"
                              ? { defaultOption: "greaterThanOrEqual" }
                              : {},
                          flex: c.flex,
                        };
                      }),
                    ]}
                    rowData={this.props.data[3].map((item, index) => {
                      let temp = {
                        ...item,
                        OppNo: index + 1,
                        Age: Math.round(
                          (Date.now() - Date.parse(item.Created_Date)) /
                            (1000 * 60 * 60 * 24)
                        ),
                        rowHeight: 40,
                      };
                      return temp;
                    })}
                    onFirstDataRendered={this.onFirstDataRendered_}
                    defaultColDef={this.state.defaultColDef}
                    enableCellTextSelection={true}
                    // domLayout={this.state.gridHeight}
                    headerHeight={55}
                    onCellClicked={this.onCellClicked}
                    colResizeDefault={"shift"}
                    onColumnResized={
                      this.props.data[3].length <= 10
                        ? this.onColumnResized_
                        : null
                    }
                    //onGridSizeChanged={this.onGridSizeChanged}
                    onGridReady={this.gridReady}
                    getRowStyle={this.state.getRowStyle}
                  ></AgGridReact>
                </div>
              </Grid>
              {/* {
          this.state.location === "drilldownOppId" &&
          <ExpectedAcvDrilldown
            tableType={"Hello"}
            handleChangeLocationBack={this.handleChangeLocationBack}
            // filters={this.state.filtersForDrilldown}
            table={"Hi"}
            // col={this.state.col}
            // stepBack={this.stepBack}
            // selectedDrilldownStage={this.state.selectedDrilldownStage}
            openSecondLevelDrilldown={this.handleSecondLevelDrilldown}
            expectedAcvData={this.props.ExpectedAcvData}
            // metaArr={this.state.metaArr}
            // tableSection={this.state.tableSection}
            data={this.props.drilldownOnGraphData}

          />
        } */}

              {this.state.location === "drilldownOnGraph" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <SingleLevelDrilldown
                    header={`Qualified Pipeline Current Quarter Close`}
                    back={this.handleChangeLocationBack}
                    filters={[]}
                    showOutliers={
                      this.state.toggleValue === "ACV" ? false : true
                    }
                    outlierValue={
                      this.props.PipelineCoverageCFQ.dataWithThreshold.filter(
                        (i) => ["company"].includes(i.name)
                      )[0].outLayerValue
                    }
                    outliersData={this.props.drilldownOnGraphData}
                    Value_Label={this.props.Value_Label}
                  >
                    {
                      this.props.EnsembleData.expectedHighMidLow ?
                      <DrilldownSLDNewv2 
                      Value_Label={this.props.Value_Label}
                      header={"Qualified Pipeline Current Quarter Close"}
                      body={this.props.drilldownOnGraphData}
                      showLowMidHigh={true}
                      showSLD={true}
                      handleSecondLevelDrilldown={
                        this.handleSecondLevelDrilldownV2
                      }
                      pipeline={
                        this.props.PipelineCoverageCFQ[
                          this.state.toggleValue === "ACV"
                            ? "data"
                            : "dataWithThreshold"
                        ].filter((i) => ["company"].includes(i.name))[0]
                      }
                      toggleValue={
                        this.state.toggleValue === "ACV" ? false : true
                      }
                      expectedHighMidLow={this.props.EnsembleData.expectedHighMidLow}
                      qualifiedPipeline = {this.props.EnsembleData.qualifiedPipeline}
                      uniqueNames = {this.props.EnsembleData.uniqueNames}
                      quarterLastDate= {this.props.EnsembleData.quarterLastDate}
                      fiscalQuarter={this.props.EnsembleData.fiscalQuarter}
                      splitType={this.props.EnsembleData.splitType}
                      />
                      : 

                      <DrilldownSLDv2
                      Value_Label={this.props.Value_Label}
                      header={"Qualified Pipeline Current Quarter Close"}
                      body={this.props.drilldownOnGraphData}
                      showLowMidHigh={true}
                      showSLD={true}
                      handleSecondLevelDrilldown={
                        this.handleSecondLevelDrilldownV2
                      }
                      pipeline={
                        this.props.PipelineCoverageCFQ[
                          this.state.toggleValue === "ACV"
                            ? "data"
                            : "dataWithThreshold"
                        ].filter((i) => ["company"].includes(i.name))[0]
                      }
                      toggleValue={
                        this.state.toggleValue === "ACV" ? false : true
                      }
                    />
                    }
                    
                  </SingleLevelDrilldown>
                </Grid>
              )}

              {this.state.location === "drilldownOnGraphAttainment" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <SingleLevelDrilldown
                    header={`Won Opportunities`}
                    back={this.handleChangeLocationBack}
                    filters={[]}
                  >
                    <Drilldown
                      Value_Label={this.props.Value_Label}
                      header={"Won Opportunities"}
                      body={this.props.drilldownOnGraphData}
                      handleSecondLevelDrilldown={
                        this.handleSecondLevelDrilldownV3
                      }
                    />
                  </SingleLevelDrilldown>
                </Grid>
              )}

              {this.state.location === "drilldownOnGraphPipeline" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <SingleLevelDrilldown
                    header={`Opportunities in Pipeline`}
                    back={this.handleChangeLocationBack}
                    filters={[]}
                  >
                    <Drilldown
                      Value_Label={this.props.Value_Label}
                      header={"Opportunities in Pipeline"}
                      body={this.props.drilldownOnGraphData}
                      handleSecondLevelDrilldown={
                        this.handleSecondLevelDrilldownV3
                      }
                      Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                    />
                  </SingleLevelDrilldown>
                </Grid>
              )}

              {this.state.location === "drilldownOppId" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <SingleLevelDrilldown
                    header={``}
                    back={this.handleChangeLocationBack}
                    // stepBack={this.stepBack}
                    // filters={this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : null}
                    // filters={[{title: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ?  this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : this.state.selectedDrilldown : this.state.selectedDrilldown === "total" ? this.state.closed_fiscal_year : this.state.selectedDrilldown}]}
                    filters={[]}
                  >
                    <ExpectedAcvPage
                      Value_Label={this.props.Value_Label}
                      data={this.props.ExpectedAcvData}
                      opp={true}
                      company={this.props.company}
                      Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                    />
                  </SingleLevelDrilldown>
                </Grid>
              )}
              {this.state.secondLevelLocation === "drilldownOppId" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <SingleLevelDrilldown
                    header={``}
                    back={this.handleChangeLocationBack}
                    stepBack={this.stepBack}
                    // filters={this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : null}
                    // filters={[{title: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ?  this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : this.state.selectedDrilldown : this.state.selectedDrilldown === "total" ? this.state.closed_fiscal_year : this.state.selectedDrilldown}]}
                    filters={[]}
                  >
                    <ExpectedAcvPage
                      Value_Label={this.props.Value_Label}
                      data={this.props.ExpectedAcvData}
                      opp={true}
                      company={this.props.company}
                      Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                    />
                  </SingleLevelDrilldown>
                </Grid>
              )}

              {this.state.secondLevelLocationV2 === "drilldownOppId" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <SingleLevelDrilldown
                    header={``}
                    back={this.handleChangeLocationBack}
                    stepBack={this.stepBack}
                    // filters={this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : null}
                    // filters={[{title: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ?  this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : this.state.selectedDrilldown : this.state.selectedDrilldown === "total" ? this.state.closed_fiscal_year : this.state.selectedDrilldown}]}
                    filters={[]}
                  >
                    <ExpectedAcvPageV2
                      Value_Label={this.props.Value_Label}
                      body={this.props.ExpectedAcvData}
                    />
                  </SingleLevelDrilldown>
                </Grid>
              )}
            </Grid>
          </div>
        ) : (
          <Grid component={Card} item container justify="center">
            <Loading />
          </Grid>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    homepageData,
    scatterpoint,
    serviceWorkerUpdate,
    descatterpoint,
    ExpectedAcvData,
    drilldownOnGraphData,
    AttainmentHomeDataYTD,
    AttainmentHomeDataCFQ,
    PipelineCoverageHomeCFQ,
    EnsembleData
  } = state.app;

  const { data, columns, quartersToUse, differentQtrData } = homepageData;
  // const {data:PipelineCoverageCFQ,dataWithThreshold:PipelineCoverageCFQThreshold } = PipelineCoverageHomeCFQ
  //  console.log(quartersToUse)

  return {
    company: user.company,
    Value_Label: user.Value_Label,
    Currency_Symbol: user.Currency,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    data,
    columns,
    scatterpoint,
    descatterpoint,
    serviceWorkerUpdate,
    ExpectedAcvData,
    drilldownOnGraphData,
    homepageData,
    AttainmentDataYTD: AttainmentHomeDataYTD,
    AttainmentDataCFQ: AttainmentHomeDataCFQ,
    PipelineCoverageCFQ: PipelineCoverageHomeCFQ,
    quartersToUse,
    differentQtrData,
    EnsembleData
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTable: (drilldownTable) => {
      dispatch({ type: "update_table", table: drilldownTable });
    },

    getHomepageData: () => {
      dispatch({ type: "get_homepage_data_request" });
      appService.homepageData().then(
        (resp) => {
          dispatch({
            type: "get_homepage_data_success",
            homepageData: {
              data: resp.message.recordsets,
              columns: resp.message.columns,
              quartersToUse: resp.message.quartersToUse,
              differentQtrData: resp.message.differentQtrData,
            },
          });
        },
        (err) => {
          dispatch({ type: "get_homepage_data_failure", error: err });
        }
      );
    },
    getOpenOppDataAttainment: (oppId) => {
      // console.log(oppId)
      dispatch({ type: "get_expectedAcv_drilldown_data_request" });
      appService.getWaterfallOppData(oppId).then(
        (json) => {
          dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_expectedAcv_drilldown_data_failure",
              error: "Something went wrong",
            });
          else
            dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
        }
      );
    },
    clearOpenOppData: () => {
      dispatch({ type: "clear_expected_acv_data" });
    },
    getOpenOppData: (oppId) => {
      dispatch({ type: "get_expectedAcv_drilldown_data_request" });
      appService.getOpenOppData(oppId).then(
        (json) => {
          dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_expectedAcv_drilldown_data_failure",
              error: "Something went wrong",
            });
          else
            dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
        }
      );
    },
    getExpectedAcvData: (oppId) => {
      dispatch({ type: "get_expectedAcv_drilldown_data_request" });
      appService.getExpectedAcvData(oppId).then(
        (json) => {
          dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_expectedAcv_drilldown_data_failure",
              error: "Something went wrong",
            });
          else
            dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
        }
      );
    },
    getAttainmentData: (filters, type) => {
      dispatch({ type: `get_attainment_data_${type}_home_request` });
      appService.getAttainmentData(filters, type).then(
        (json) => {
          dispatch({ type: `get_attainment_data_${type}_home_success`, json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: `get_attainment_data_${type}_home_failure`,
              error: "Something went wrong",
            });
          else
            dispatch({
              type: `get_attainment_data_${type}_home_failure`,
              error,
            });
        }
      );
    },
    getPipelineCoverageData: (filters, type) => {
      dispatch({
        type: `get_generic_pipeline_coverage_data_${type}_home_request`,
      });
      appService.getGenericPipelineCoverageData(filters, type).then(
        (json) => {
          dispatch({
            type: `get_generic_pipeline_coverage_data_${type}_home_success`,
            json,
          });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: `get_generic_pipeline_coverage_data_${type}_home_failure`,
              error: "Something went wrong",
            });
          else dispatch({ type: "get_quota_attainment_data_failure", error });
        }
      );
    },
    getEnsembleData: () => {
      dispatch({
        type: `get_ensemble_home_request`,
      });
      appService.getEnsembleData().then(
        (json) => {
          dispatch({
            type: `get_ensemble_home_success`,
            json,
          });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: `get_ensemble_home_failure`,
              error: "Something went wrong",
            });
          else dispatch({ type: "get_quota_attainment_data_failure", error });
        }
      );
    },
    getPipelineCoverageDrilldown: (
      filters,
      key,
      timeFrame,
      deals20K,
      tableType
    ) => {
      dispatch({ type: "get_drilldown_on_graph_request" });
      appService
        .getPipelineCoverageGenericDrilldown(
          filters,
          key,
          timeFrame,
          deals20K,
          tableType
        )
        .then(
          (json) => {
            let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
              a.ACV > b.ACV ? -1 : 1
            );
            tempOpportunities = tempOpportunities.map((item, index) => {
              let temp =
                deals20K === "Deals20K"
                  ? {
                      ...item,
                      OppNo: index + 1,
                    }
                  : {
                      ...item,
                      OppNo: index + 1,
                    };
              return temp;
            });

            json.message = {
              ...json,
              columns: json.message.columns,
              count_acv: json.message.opportunities[0],
              opportunities: tempOpportunities,
            };
            dispatch({ type: "get_drilldown_on_graph_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_drilldown_on_graph_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_drilldown_on_graph_failure", error });
          }
        );
    },
    getAttainmentSectionDrilldown: (filters, key, coverage) => {
      dispatch({ type: "get_drilldown_on_graph_request" });
      appService.getAttainmentSectionDrilldown(filters, key, coverage).then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
            a.ACV > b.ACV ? -1 : 1
          );
          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json.message = {
            ...json,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
    },
    getClosedDrilldownOnGraph: (key, filters) => {
      // console.log(key,filters)
      dispatch({ type: "get_drilldown_on_graph_request" });
      appService.closedDrilldownOnGraph(key, filters).then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].map(
            (item, index) => {
              let x = {
                ...item,
                OppNo: index + 1,
              };
              return x;
            }
          );

          tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              count_acv: json.message.opportunities[0],
              opportunities: tempOpportunities,
              columns: json.message.columns,
            },
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
    },
    getHomeDrilldownOnGraph: () => {
      // console.log(key,filters)
      dispatch({ type: "get_drilldown_on_graph_request" });
      appService.homeDrilldownOnGraph().then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].map(
            (item, index) => {
              let x = {
                ...item,
                OppNo: index + 1,
              };
              return x;
            }
          );

          tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              count_acv: json.message.opportunities[0],
              opportunities: tempOpportunities,
              columns: json.message.columns,
            },
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
    },
    getPipelineMixDrilldown: (filters) => {
      dispatch({ type: "get_drilldown_on_graph_request" });
      appService.getPipelineMixAnalysisDrilldown(filters).then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].map(
            (item, index) => {
              let x = {
                ...item,
                //closed_date: item.closed_date !== undefined ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
                OppNo: index + 1,
              };
              return x;
            }
          );

          tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              ...json.message,
              count_acv: json.message.opportunities[0],
              opportunities: tempOpportunities,
              columns: json.message.columns,
            },
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
    },
    getTimeSeriesDrilldown: (filters) => {
      dispatch({ type: "get_drilldown_on_graph_request" });
      appService.getTimeSeriesHomeDrilldown(filters).then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].map(
            (item, index) => {
              let x = {
                ...item,
                //closed_date: item.closed_date !== undefined ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
                OppNo: index + 1,
              };
              return x;
            }
          );

          tempOpportunities.sort((a, b) => (a.Acv_At_Time_T > b.Acv_At_Time_T ? -1 : 1));

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              ...json.message,
              count_acv: json.message.opportunities[0],
              opportunities: tempOpportunities,
              columns: json.message.columns,
            },
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
    },
    clearDrilldownData: () => {
      dispatch({ type: "clear_drilldown_data" });
    },
    clearExpectedAcvData: () => {
      dispatch({ type: "clear_expected_acv_data" });
    },
  };
};

const connectedDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard));
export { connectedDashboard as Dashboard };