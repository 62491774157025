import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { sort } from "d3-array";
import React, { Component } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import Drilldown from "../../RepPerformance/GenericScorecard/DrilldownCalls";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import DataTable from "./DataTable";
import DatePicker from "../../components/DatePicker_v2";
import { customDateFormatterNew } from "../../util/customFunctions";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class CallsCompleted extends Component {
  state = {
    last: 60,
    activelast: 60,
    timeFrame: "window",
    from: "",
    to: "",
    fromDisplay: "",
    toDisplay: "",
    activeFrom: "",
    activeTo: "",
    minDateForTo: "",
    maxDateForTo: "",
    minDateForFrom: "",
    maxDateForFrom: "",
    errorTextFrom: "",
    errorTextTo: "",
    location: null,
    secondLevelLocation: null,
    toggleValue: "Acv",
    showWeeklyAvg: false,
  };
  componentDidMount = () => {
    this.props.getfunnelCompareFilters();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const fromDateComponents =
        this.props.funnelCompareFilters.dateFilters.prior2Month.split("-");
      const toDateComponents =
        this.props.funnelCompareFilters.dateFilters.currentDate.split("-");
      const fromDateObj = new Date(
        parseInt(fromDateComponents[0]),
        parseInt(fromDateComponents[1]) - 1,
        parseInt(fromDateComponents[2])
      );
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      const minDateSplit =
        this.props.funnelCompareFilters.minMax.minDate.split("-");
      const minDate = new Date(
        parseInt(minDateSplit[0]),
        parseInt(minDateSplit[1]) - 1,
        parseInt(minDateSplit[2])
      );
      const maxDateSplit =
        this.props.funnelCompareFilters.minMax.maxDate.split("-");
      const maxDate = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );

      this.setState({
        ...this.state,
        minDateForTo: minDate,
        maxDateForTo: maxDate,
        minDateForFrom: minDate,
        maxDateForFrom: maxDate,
        from: fromDate,
        to: toDate,
        fromDisplay: customDateFormatterNew(fromDate),
        toDisplay: customDateFormatterNew(toDate),
        activeFrom: fromDate,
        activeTo: toDate,
      });
    }
  }

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  handleChange = (e) => {
    if (e.target.value !== 1) {
      const projectedClosedDateMapping = {
        30: this.props.funnelCompareFilters.dateFilters.prior1Month.split("-"),
        60: this.props.funnelCompareFilters.dateFilters.prior2Month.split("-"),
      };
      const fromDateObj = new Date(
        parseInt(projectedClosedDateMapping[e.target.value][0]),
        parseInt(projectedClosedDateMapping[e.target.value][1]) - 1,
        parseInt(projectedClosedDateMapping[e.target.value][2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDateComponents =
        this.props.funnelCompareFilters.dateFilters.currentDate.split("-");
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.statem, [e.target.name]: e.target.value });
    }
  };

  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    var filters = [];

    if (this.state.from !== "" && this.state.to !== "") {
      const from = this.state.from;
      const fromDate = `${from.split("-")[2]}-${from.split("-")[0]}-${
        from.split("-")[1]
      }`;
      const to = this.state.to;
      const toDate = `${to.split("-")[2]}-${to.split("-")[0]}-${
        to.split("-")[1]
      }`;
      const timeFrames = `${fromDate}_to_${toDate}`;
      filters = [
        ...filters,
        {
          name: "timeFrames",
          value: timeFrames,
        },
      ];
    }

    this.setState({ ...this.state });
    this.props.getCallsCompletedData(filters);
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    //this.props.setTable('')
  };
  handleChangeLocation = (location, week, linkedOpp, rep, team) => {
    if (location !== "overview") {
      let filters = [];
      let drildownFilters = [];

      filters = this.props.currentFilters.filter(
        (f) => f.name === "timeFrames"
      );

      let showWeekAvg = false;
      if (typeof week !== "string" && week.length > 1) {
        showWeekAvg = true;
      }

      filters = [
        ...filters,
        { name: "Owner_Name", value: typeof rep === "string" ? [rep] : rep },
        {
          name: "Week_Ending",
          value: typeof week === "string" ? [week] : week,
        },
      ];

      drildownFilters = [
        ...drildownFilters,
        {
          title: "Rep",
          value:
            typeof rep === "string" ? [rep] : rep.length > 6 ? ["All"] : rep,
        },
        {
          title: "Week Ending",
          value:
            typeof week === "string"
              ? [week.split("T")[0]]
              : week.length > 1
              ? ["All"]
              : week.map((c) => c.split("T")[0]),
        },
      ];

      if (linkedOpp) {
        filters = [
          ...filters,
          {
            name: "Linked_to_Existing_Opp",
            value: typeof linkedOpp === "string" ? [linkedOpp] : linkedOpp,
          },
        ];
        drildownFilters = [
          ...drildownFilters,
          {
            title: "Linked to Existing Opp",
            value: typeof linkedOpp === "string" ? [linkedOpp] : linkedOpp,
          },
        ];
      }

      if (team) {
        filters = [
          ...filters,
          { name: "team", value: typeof team === "string" ? [team] : team },
        ];
        drildownFilters = [
          ...drildownFilters,
          {
            title: "Team",
            value:
              typeof team === "string"
                ? [team]
                : team.length > 6
                ? ["All"]
                : team,
          },
        ];
      }

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        // header:key1 === 'Won' ? 'Won' : key1 === 'Open' ? `Open Pipeline (${this.state.Stage[0]} or later)` : `Lost or Disqualified (${this.state.Stage[0]} or later)`,
        filtersForDrilldown: [...drildownFilters],
        showWeeklyAvg: showWeekAvg,
      });

      this.props.getCallsCompletedDrilldownData(filters);
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      // location: location,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant='h2' align='center'>Calls Completed</Typography>
        </Grid>
        <Typography variant='body1'>
        Analyze the cadence and consistency of customer calls completed
        </Typography> */}
        {addHeaderdescription(
          "Calls Completed",
          "Analyze the cadence and consistency of customer calls completed"
        )}

        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid container item style={{ padding: 10 }} xs={4} md={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={4} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4", whiteSpace: "nowrap" }}
                    >
                      Time Frame - Last:
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={this.state.last}
                      onChange={this.handleChange}
                      label="Last:"
                      name="last"
                      disabled={this.state.timeFrame === "custom"}
                    >
                      {[
                        { text: "Custom", value: 1 },
                        { text: "1 month", value: 30 },
                        { text: "2 months", value: 60 },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.value}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker
                    label="From start of:"
                    setDate={(date) => this.setFromDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForFrom}
                    max={this.state.maxDateForFrom}
                    current={this.state.from}
                    errorText={this.state.errorTextFrom}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker
                    label="To end of:"
                    setDate={(date) => this.setToDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForTo}
                    max={this.state.maxDateForTo}
                    current={this.state.to}
                    errorText={this.state.errorTextTo}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Card style={{ margin: "auto", width: "100%" }} raised={true}>
            <Grid item container justify="center">
              <Skeleton
                variant="react"
                animation="wave"
                height={20}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          </Card>
        )}

        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.CallsCompletedData !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >
                      Calls Completed (grouped by team)
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right"></Grid>
                </Grid>
                <DataTable
                  data={this.props.CallsCompletedData.dataLinkedtoExistingOpp}
                  dataNotLinnked={
                    this.props.CallsCompletedData.dataNotLinkedtoExistingOpp
                  }
                  dataTotal={this.props.CallsCompletedData.totalData}
                  teamRep={this.props.CallsCompletedData.repTeamObj}
                  timeFrames={this.props.CallsCompletedData.uniqueTimeFrames}
                  handleChangeLocation={this.handleChangeLocation}
                  dataTest="calls-complted-data-table"
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        {/* <Grid item xs={12} style={{ padding: '1rem', paddingLeft: 0 }}>
          {
            this.props.CallsCompletedData !== "" ?
              <Grid item container xs={12} justify="flex-start">
                <Card style={{ padding: '1rem 2rem' }}>
                <Grid item container xs={12}justify="center">
                      <Grid item xs={1}>
                      </Grid>
                      <Grid item xs={10}>
                      <Typography variant='h2' style={{ width: '100%', margin: 'auto', padding: '1rem' }} align="center">Calls Completed - Not Linked to Existing Opp (grouped by team)</Typography>
                   </Grid>
                   <Grid item xs={1} align='right'>

                      
                      </Grid>
                   </Grid>
                  
                   <DataTable data={this.props.CallsCompletedData.dataNotLinkedtoExistingOpp} teamRep={this.props.CallsCompletedData.repTeamObj} timeFrames={this.props.CallsCompletedData.uniqueTimeFrames}/>

                </Card>
              </Grid>
              :
              <Grid item container component={Card} justify="center"><Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} /></Grid>
          }
        </Grid> */}

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Calls Completed"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={"Calls Completed"}
                body={this.props.CallsCompletedDrilldownData}
                showWeeklyAvg={this.state.showWeeklyAvg}
              />
              {/* <Drilldown body={this.props.CallsCompletedDrilldownData} isMetaData={this.state.isMetaData} metaData={this.state.metaData} drilldownLabelString={this.state.drilldownLabelString} 
               company={this.props.company} handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
               />   */}
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    user,
    CallsCompletedData,
    drilldownOnGraphData,
    ExpectedAcvData,
    CallsCompletedFilters,
    currentFilters,
  } = state.app;

  return {
    company: user.company,
    CallsCompletedData,
    CallsCompletedDrilldownData: drilldownOnGraphData,
    funnelCompareFilters: CallsCompletedFilters,
    ExpectedAcvData,
    currentFilters,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearDrilldownData: () => {
    dispatch({ type: "clear_CallsCompleted_drilldown_data" });
  },
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_callsCompleted_filters_request" });
    appService.getCallsCompletedFilters().then(
      (json) => {
        dispatch({ type: "get_callsCompleted_filters_success", json });
        dispatch({ type: "get_callsCompleted_data_request" });
        let filters = [];
        if (json.message.dateFilters) {
          const from = json.message.dateFilters.prior2Month;
          // const fromDate = `${from.split('-')[2]}-${from.split('-')[0]}-${from.split('-')[1]}`
          const to = json.message.dateFilters.currentDate;
          // const toDate = `${to.split('-')[2]}-${to.split('-')[0]}-${to.split('-')[1]}`

          const timeFrames = `${from}_to_${to}`;
          filters = [
            ...filters,
            {
              name: "timeFrames",
              value: timeFrames,
            },
          ];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService.getCallsCompletedData(filters).then(
          (json) => {
            dispatch({ type: "get_callsCompleted_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_callsCompleted_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_callsCompleted_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_callsCompleted_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_callsCompleted_filters_failure", error });
      }
    );
  },
  getCallsCompletedDrilldownData: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getCallsCompletedDrilldownData(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acvAtStage > b.acvAtStage ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          },
        };

        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getCallsCompletedData: (filters) => {
    dispatch({ type: "get_callsCompleted_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getCallsCompletedData(filters).then(
      (json) => {
        dispatch({ type: "get_callsCompleted_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_callsCompleted_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_callsCompleted_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedCallsCompleted = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CallsCompleted));
export default connectedCallsCompleted;
