import Skeleton from "@material-ui/lab/Skeleton";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import { NumberFormatCustom } from "../../components/CustomNumberFormat";
import {
  customDateFormatterNew,
  dateFormatterMomentV2,
} from "../../util/customFunctions";
import DatePicker from "../../components/DatePicker_v2";
// import RepCertificationTable from './RepCertificationTable'
// import RepCertificationTable2 from './RepCertificationTable2'

import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import DrilldownBody from "../../components/Drilldown/Drilldown";
import RampAnalysisTable from "./RampAnalysisTable";
import addHeaderdescription from "../../components/HeaderDescription";

// // import QualifiedPipelineTable from './QualifiedPipelineTable';
// import { weekEndingMapping } from './utils';
const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  formControl: {
    maxWidth: 250,
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: "32%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  repTeamFilterContainer: {
    padding: 10,
    margin: 5,
    width: "29%",
    [theme.breakpoints.down("md")]: {
      width: "37%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  acvFilterContainer: {
    padding: 10,
    margin: 5,
    paddingBottom: 2,
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "35%",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  stageFilterContainer: {
    padding: 5,
    paddingTop: 10,
    margin: 5,
    paddingBottom: 10,
    width: "10%",
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  buttonContainer: {
    padding: 5,
    width: "5%",
    alignSelf: "center",
  },
});

class RampAnalysis extends Component {
  filters = ["team"];

  state = {
    location: null,
    disabled: ["year", "month", "quarter"],
    filtersForDrilldown: [],
    activeFilters: "",
    activelast: "",
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    Cust_Type: ["All"],
    acv: "20,000",
    from: "",
    to: "",
    minDateForTo: "",
    maxDateForTo: "",
    minDateForFrom: "",
    maxDateForFrom: "",
    errorTextFrom: "",
    errorTextTo: "",
    last: "",
    fromDisplay: "",
    toDisplay: "",
    activeFrom: "",
    activeTo: "",
    DrildownFilters: [],
    drilldownTitle: "",
    key: "",
    series: ["All"],
    seriesFilter: ["All"],
    groupby: "None",
    secondLevelLocation: null,
  };
  componentDidMount = () => {
    this.props.getRampAnalysisFilters();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.RampAnalysisFilters !== "" &&
      prevProps.RampAnalysisFilters === ""
    ) {
      // console.log(month)
      // console.log(dateFormatterMoment({year,month:month,date:dt}),year,month,dt)
      const fromDateComponents =
        this.props.RampAnalysisFilters.filters.dateFilters
          .filter((s) => s.selectedByDefault === "Y")[0]
          .value.split("-");
      const toDateComponents =
        this.props.RampAnalysisFilters.currentDate.split("-");
      const fromDateObj = new Date(
        parseInt(fromDateComponents[0]),
        parseInt(fromDateComponents[1]) - 1,
        parseInt(fromDateComponents[2])
      );
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      const minDateSplit =
        this.props.RampAnalysisFilters.minMax.minDate.split("-");
      const minDate = new Date(
        parseInt(minDateSplit[0]),
        parseInt(minDateSplit[1]) - 1,
        parseInt(minDateSplit[2])
      );
      const maxDateSplit =
        this.props.RampAnalysisFilters.minMax.maxDate.split("-");
      const maxDate = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );
      this.setState({
        ...this.state,
        minDateForTo: minDate,
        maxDateForTo: maxDate,
        minDateForFrom: minDate,
        maxDateForFrom: maxDate,
        from: fromDate,
        to: toDate,
        last: this.props.RampAnalysisFilters.filters.dateFilters.filter(
          (s) => s.selectedByDefault === "Y"
        )[0].value,
        fromDisplay: customDateFormatterNew(fromDate),
        toDisplay: customDateFormatterNew(toDate),
        activeFrom: fromDate,
        activeTo: toDate,
        filtersForDrilldown: this.passFiltersForDrilldown(),
      });
    }
  }
  handleChange = (e) => {
    if (e.target.value !== 1) {
      const fromDateComponents = e.target.value.split("-");
      const toDateComponents =
        this.props.RampAnalysisFilters.currentDate.split("-");
      const fromDateObj = new Date(
        parseInt(fromDateComponents[0]),
        parseInt(fromDateComponents[1]) - 1,
        parseInt(fromDateComponents[2])
      );
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };

  handleChangeFilter = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };

  orderRepsByLastName = () => {
    let temp = this.props.RampAnalysisFilters.filters.reps;
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  getDrilldownTitle = (key) => {
    if (key === "SAL") {
      return "SALs Assigned on or After Hire Date";
    } else if (key === "Quota") {
      return "Cumulative Quota since Hire Date";
    } else if (key === "Won") {
      return "Won from opps. assigned after Hire Date";
    } else if (key === "lost") {
      return "Lost from opps. assigned after Hire Date";
    } else if (key === "Win") {
      return "Win Rate from SQL";
    } else if (key === "Certification") {
      return "Certification Completion";
    } else {
      return "Rep Certification";
    }
  };

  handleChangeLocation = (location, rep, quarter, team) => {
    if (location !== "overview") {
      var filters = [];
      filters = [
        ...filters,
        {
          name: "closed_fiscal_quarter",
          value: typeof quarter === "string" ? [quarter] : quarter,
          string: true,
        },
        { name: "Owner_Name", value: [rep], string: true },
      ];

      filters =
        team !== undefined
          ? [
              ...filters,
              { name: "team", value: typeof team === "string" ? [team] : team },
            ]
          : filters;
      filters =
        this.state.team[0] !== "All"
          ? [
              ...filters,
              {
                name: "team",
                value:
                  typeof this.state.team === "string"
                    ? [this.state.team]
                    : this.state.team,
              },
            ]
          : filters;
      // if(!this.state.seriesFilter.includes('All')) {
      //   filters=[...filters,{name:'series',value:this.state.seriesFilter}]
      // }
      var drilldownfilters = [
        { title: "Rep", value: rep },
        { title: "Closed Fiscal Quarter", value: quarter },
      ];
      const fromDate = `${this.state.activeFrom.split("-")[2]}-${
        this.state.activeFrom.split("-")[0]
      }-${this.state.activeFrom.split("-")[1]}`;
      const toDate = `${this.state.activeTo.split("-")[2]}-${
        this.state.activeTo.split("-")[0]
      }-${this.state.activeTo.split("-")[1]}`;
      this.props.getRampAnalysisDrilldown(filters);
      this.setState({
        location,
        drilldownTitle: "Ramp Analysis",
        DrildownFilters: [
          ...drilldownfilters,
          ...this.state.filtersForDrilldown,
        ],
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  plot = () => {
    const fromSplit = this.state.from.split("-");
    const toSplit = this.state.to.split("-");

    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.RampAnalysisFilters.filters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.RampAnalysisFilters.filters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
          string: "true",
        };
      })
      .filter((f) => !f.value.includes("All"))
      .filter((f) => !this.state.disabled.includes(f.name));
    /* if (this.state.acv !== null && this.state.acv !== 0) {
        filters = [
          ...filters,
          {
            name: 'acv',
            value: this.state.acv && typeof (this.state.acv) === 'string' ?
  
              this.state.acv.match(/,/g) ?
                this.state.acv.match(/,/g).length === 1 ?
                  parseInt(this.state.acv) * 1000
                  :
                  this.state.acv.match(/,/g).length === 2 ?
                    parseInt(this.state.acv) * 1000000
                    :
                    parseInt(this.state.acv)
                :
                parseInt(this.state.acv)
  
  
              : this.state.acv,
            string: false
          }
        ]
      }
   */
    //this.props.getStageProgressionData(this.state.from, this.state.to, filters)
    const fromDate = `${this.state.from.split("-")[2]}-${
      this.state.from.split("-")[0]
    }-${this.state.from.split("-")[1]}`;
    const toDate = `${this.state.to.split("-")[2]}-${
      this.state.to.split("-")[0]
    }-${this.state.to.split("-")[1]}`;
    let groupbyFilters = { groupby: this.state.groupby };
    //  console.log(groupbyFilters)
    this.props.getRampAnalysisData(fromDate, toDate, filters, groupbyFilters);
    this.setState({
      ...this.state,
      fromDisplay: customDateFormatterNew(this.state.from),
      toDisplay: customDateFormatterNew(this.state.to),
      activeFrom: this.state.from,
      activeTo: this.state.to,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      //activeFilters: filters,
      activelast: this.state.last,
      seriesFilter: this.state.series,
    });
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (!this.state.series.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Series", value: this.state.series },
      ];
    }
    if (!this.state.team.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];
    }
    // filtersArray=[...filtersArray,{title:'ACV >=',value:this.state.acv}]
    if (!this.state.Cust_Type.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer Type", value: this.state.Cust_Type },
      ];
    }

    return filtersArray;
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };
  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    // console.log(this.state.secondLevelLocation)
    return (
      <Grid container className={classes.gridContainer}>
        <Grid
          className={classes.grid}
          item
          style={{ marginBottom: 10, marginLeft: 25 }}
          xs={12}
        >
          {/* <Grid item xs={12} justify="center">
            <Typography variant="h2" align="center">
              Ramp Analysis
            </Typography>
          </Grid>
          <Grid item xs={12} justify="left" style={{ margintop: 10 }}>
            <Typography variant="body1">
              Analyze ramp velocity by rep and team.
            </Typography>
          </Grid> */}
          {addHeaderdescription(
            "Ramp Analysis",
            "Analyze ramp velocity by rep and team."
          )}
          {this.props.RampAnalysisFilters !== "" ? (
            <>
              <Grid
                container
                xs={12}
                alignItems="flex-start"
                style={{ marginBottom: 5 }}
              >
                <Grid
                  xs={5}
                  item
                  container
                  className={classes.datesFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-last-label"
                        style={{ color: "#4472c4" }}
                      >
                        Hiring Time Frame - Last:
                      </InputLabel>
                      <Select
                        labelId="select-last-label"
                        id="select-last"
                        value={this.state.last}
                        onChange={this.handleChange}
                        label="Last:"
                        name="last"
                        disabled={this.state.timeFrame === "custom"}
                      >
                        {[
                          { text: "Custom", value: 1 },
                          ...this.props.RampAnalysisFilters.filters.dateFilters,
                        ].map((y) => (
                          <MenuItem key={y.text} value={y.value}>
                            {y.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 10 }}>
                    <DatePicker
                      label="From start of:"
                      setDate={(date) => this.setFromDate(date)}
                      disabled={this.state.last !== 1}
                      min={this.state.minDateForFrom}
                      max={this.state.maxDateForFrom}
                      current={this.state.from}
                      errorText={this.state.errorTextFrom}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 10 }}>
                    <DatePicker
                      label="To end of:"
                      setDate={(date) => this.setToDate(date)}
                      disabled={this.state.last !== 1}
                      min={this.state.minDateForTo}
                      max={this.state.maxDateForTo}
                      current={this.state.to}
                      errorText={this.state.errorTextTo}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth>
                      <InputLabel id="groupby-label">Group By:</InputLabel>
                      <Select
                        labelId="groupby-label"
                        id="groupby"
                        value={this.state.groupby}
                        onChange={this.handleChangeFilter}
                        label="groupby"
                        name="groupby"
                        data-test="group-by"
                      >
                        <MenuItem value={"None"}>None</MenuItem>
                        <MenuItem value={"Team"}>Team</MenuItem>
                        {/* <MenuItem value={'Team, Ramp Status'} >Team, Ramp Status</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={2}
                  container
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChangeFilter}
                        label="Team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.RampAnalysisFilters !== "" &&
                          this.props.RampAnalysisFilters.filters.teams
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  justify="space-between"
                  xs={4}
                  lg={2}
                >
                  {/* <Grid item spacing={2} xs={8} xl={9} container component={Paper} >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0, height: '0px' }}>

                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={this.props.RampAnalysisFilters !== '' && this.props.RampAnalysisFilters.filters.acv}
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField {...params} label="ACV >= :" margin="normal" variant="standard" style={{ marginTop: 4, }}
                        InputLabelProps={{ shrink: true, style: { color: this.state.acv !== null && this.state.acv !== 0 ? '#4472c4' : '#000' } }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />

                </Grid>
              </Grid> */}
                  <Grid item xs={4} xl={3} container justify="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.plot}
                      disabled={
                        this.state.errorTextFrom !== "" ||
                        this.state.errorTextTo !== ""
                      }
                    >
                      GO
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
          >
            <Grid
              item
              xs={12}
              style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.RampAnalysisData !== "" ? (
                  <Grid item container xs={12} justify="center">
                    <Typography
                      variant="h2"
                      style={{
                        margin: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Ramp Analysis ($K)
                    </Typography>

                    <RampAnalysisTable
                      data={this.props.RampAnalysisData.data}
                      timeFrame={this.props.RampAnalysisData.timeFrame}
                      groupby={this.props.RampAnalysisData.groupby}
                      groupbyColumn={this.props.RampAnalysisData.groupbyColumn}
                      uniqueTeams={this.props.RampAnalysisData.uniqueTeams}
                      handleChangeLocation={this.handleChangeLocation}
                      dataTest={"Ramp Analysis"}
                    />
                    {this.props.RampAnalysisData.data
                      .map((c) => c.Term_Date)
                      .filter(Boolean).length > 0 && (
                      <Grid
                        item
                        container
                        xs={10}
                        justify="flex-start"
                        alignItems="flex-end"
                        style={{ marginBottom: "1rem", marginLeft: "-205px" }}
                      >
                        <Typography variant="body2" style={{}}>
                          <span style={{ color: "#ED7D31" }}>*</span>&nbsp;
                          Excluded from analysis for time periods where no quota
                          data is available.
                        </Typography>
                      </Grid>
                    )}
                    {/* {
                      this.props.RampAnalysisData.data.map(s=>s.showAsterisk).includes(true) && 
                      <Grid item container xs={12} justify="flex-start" >
                        <Typography variant="body2" style={{paddingBottom:'1rem',paddingLeft:'2rem' }}>
                            <span style={{ color: "#ED7D31" }}>*</span>&nbsp; Reps who are no longer active.
                        </Typography>
                    </Grid>  
                    } */}
                  </Grid>
                ) : (
                  <Grid item container justify="center">
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Ramp Analysis"}
              back={this.handleChangeLocationBack}
              filters={this.state.DrildownFilters}
            >
              <DrilldownBody
                body={this.props.drilldownOnGraphData}
                showQuota={true}
                company={this.props.company}
                header={"Ramp Analysis"}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    RampAnalysisData,
    RampAnalysisFilters,
    drilldownOnGraphData,
    currentFilters,
    user,
    ExpectedAcvData,
  } = state.app;

  return {
    RampAnalysisFilters,
    RampAnalysisData,
    drilldownOnGraphData,
    currentFilters,
    company: user.company,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getRampAnalysisFilters: () => {
    dispatch({ type: "get_ramp_analysis_filters_request" });
    appService.getRampAnalysisFilters().then(
      (json) => {
        dispatch({ type: "get_ramp_analysis_filters_success", json });
        dispatch({ type: "get_ramp_analysis_data_request" });
        //const filters=[{name:'acv',value:20000,string:false}]
        //dispatch({ type: 'current_selected_filters', filters })
        const groupbyFilters = { groupby: "None" };
        appService
          .getRampAnalysisData(
            json.message.filters.dateFilters.filter(
              (s) => s.selectedByDefault === "Y"
            )[0].value,
            json.message.currentDate,
            [],
            groupbyFilters
          )
          .then(
            (json) => {
              dispatch({ type: "get_ramp_analysis_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_ramp_analysis_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_ramp_analysis_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_ramp_analysis_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_ramp_analysis_filters_failure", error });
      }
    );
  },
  getRampAnalysisDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getRampAnalysisDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getRampAnalysisData: (from, to, filters, groupbyFilters) => {
    dispatch({ type: "get_ramp_analysis_data_request" });
    //dispatch({ type: 'current_selected_filters', filters })
    appService.getRampAnalysisData(from, to, filters, groupbyFilters).then(
      (json) => {
        dispatch({ type: "get_ramp_analysis_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_ramp_analysis_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_ramp_analysis_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedRampAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RampAnalysis));
export default connectedRampAnalysis;
